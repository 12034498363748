import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diagnostics-list',
  templateUrl: './diagnostics-list.component.html',
  styleUrls: ['./diagnostics-list.component.css']
})
export class DiagnosticsListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
