import { Component, ViewChild, OnInit ,NgModule ,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule,DomSanitizer, SafeHtml  } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../../_services/api.service';
import { appConfig } from '../../../../app.config';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

declare var $ :any;

@Component({
  selector: 'app-consultant-form',
  templateUrl: './consultant-form.component.html',
  styleUrls: ['./consultant-form.component.css']
})
export class ConsultantFormComponent implements OnInit {

	createConsultant: FormGroup;

	public specializations:any;
    public specialistName : any;
    public specilization : any;
	public personalNo : any;
	public personalEmail : any;

	public addressName : any;
	public clinicAddress : any;
	public streetColony:any;
	public plotFlatNo:any;
	public area:any;
	public areaId:any;
	public paCity : any;
	public paPin : any;
	public paState : any;
	public paCountry : any;

	public areas:any;

	public Message : any;

	validemail : string ='Please enter the valid email.';
  	mobileno : string ='Please enter valid Phone No';
  	titleAlert : string = 'This field is required';

 	constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private _sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private titleService: Title,
    public toastr: ToastrService){

 		this.titleService.setTitle( 'Create Allied Health Associates' );
    	 

		this.createConsultant = fb.group({
		 specialistName: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
		 specilization: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
		 personalNo: [null, [Validators.required,
		  					Validators.minLength(9),
     						Validators.maxLength(10),
      						Validators.pattern('[0-9]+')
    				   ]],
		 personalEmail: [null, Validators.required],
		 addressName : new FormControl('', [Validators.required, Validators.maxLength(25)]),
		 streetColony : new FormControl('', [Validators.required, Validators.maxLength(25)]),
		 plotFlatNo : new FormControl('', [Validators.required, Validators.maxLength(25)]),
		 area : new FormControl('', Validators.required),
		 areaId : [''],
		 paCity: ['', Validators.required],
		 paPin :['', [Validators.required,
		  					Validators.minLength(6),
     						Validators.maxLength(6),
      						Validators.pattern('[0-9]+')
    				   ]],
		  paState : ['', Validators.required],
		  paCountry : ['India', Validators.required],

     
    	});
	 	
	}

	areaSelected(val){
		this.createConsultant.controls['area'].setValue(val.area);
		this.createConsultant.controls['areaId'].setValue(val.id);
		this.createConsultant.controls['paCity'].setValue(val.cityDistrict);
		this.createConsultant.controls['paPin'].setValue(val.pin);
		this.createConsultant.controls['paState'].setValue(val.state);
	}


	submitForm(post) {

		post = {
					specialistName : post.specialistName,
					specialityId : post.specilization,
					personalEmail : post.personalEmail,
					personalPhone : post.personalNo,
					category : "AHSPARTNER",
					workingLocations:
						[
							{
								address : 
								{
									addressName : post.addressName,
									plotFlatNo : post.plotFlatNo,
									streetColony : post.streetColony,
									areaId : post.areaId,
									addrType : "CLINIC",
									currentAddress: true,
								}
								
							}
						]
		}

		this.apiService.postAhspartner(post)
	            .subscribe(result => {
	                if (result != null) {
	                     console.log(result);
	                     this.Message = "AHS Partner Created Successfully";
	                     this.createConsultant.reset();
	                this.toastr.success('AHS Partner Created Successfully', 'Successful!');
		          } else {
		             this.toastr.error('Some thing went wrong', 'Sorry!');
		          }
		    }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            }; 

		console.log('post Data::'+JSON.stringify(post));
	}

	loadSpecializations() {
	        this.apiService.getSpecializations('AHSPARTNER').subscribe(specializations => { 
	          this.specializations = specializations;
	        }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            };
	}

	loadAreas() {	
        this.apiService.getAreas().subscribe(areas => { 
          this.areas = areas;
          this.areas = this.areas.areas;
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  	}

  	autocompleListFormatter = (data: any) : SafeHtml => {
	    let html = `<span>${data.area}</span>`;
	    return this._sanitizer.bypassSecurityTrustHtml(html);
	}


  ngOnInit() {
  	this.loadAreas();
  	this.loadSpecializations();
  }

}
