import { Component,ChangeDetectorRef, ViewChild, OnInit ,EventEmitter,Input,Output,NgModule ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';    
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable, combineLatest, Subscription } from 'rxjs';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';

import { ToastrService } from 'ngx-toastr';
declare var $ :any;

import { ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-madication',
  templateUrl: './madication.component.html',
  styleUrls: ['./madication.component.css']
})
export class MadicationComponent implements OnInit,OnChanges  {

  @ViewChild(ModalDirective) modal: ModalDirective;
@Output() childEvent = new EventEmitter();
  @Input() public patientId:any;
  @Input() public visitId: any;
  @Input() public pills: any; 
  @Input() public addnew: any;
  @Input() public consultationSession: any;


  

  public patientID:any;
  public visitID : any;
  public Pills : any = [];
  public ConsultationSession: any;

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  modalRef4: BsModalRef;
  modalRef5: BsModalRef;
  modalRef6: BsModalRef;

  drugSelected = false;
  durationSelected = false;

  datepickerModel1: Date;
  datepickerModel2: Date;
  datepickerModel3: Date;
  datepickerModel4: Date;

  datepickerModel5:any  = new Date();
  public maxDate:any  = new Date();

  addDrug:FormGroup;
  addMadication:FormGroup;
  oldMadication1:FormGroup;
  pauseMadication:FormGroup;
  resumeMadication:FormGroup;
  stopMedication:FormGroup;

  subscriptions: Subscription[] = [];

  public brandName : any;
  public route : any;
  public unit : any;
  public form : any;
  chemicals: any[] = [];
  public name : any;
  public strength : any;

  public administeredDate : any;
  public commenceDate : any;

  public remarks: any;
  public resumeOn: any;

  public mRoute : any;
  public mDrugId : any;
  public mChemical : any;
  public mDosage:any;
  public mDosage1 : any;
  public mDosage2 : any;
  public mDosage3 : any;
  public mTimeodDay : any;
  public mFood : any;
  public mGAP : any;
  public mRemarks : any;
  public selectedChemicals : any;
  public selectedunit : any;
  public indexvalue:any;

  public time_of_day:boolean = false;
  public time_intervel:boolean = false;
  public poststatus:boolean = true;
  public selectedMedication;

  public morningId:any;
  public afternoonId:any;
  public eveningId:any;
  public nightId:any;
  public scheduledID:any;
  public selected:any;
  public selectedPill:any;
  public hideenddate:Boolean = true;

  validemail : string ='Please enter the valid email.';
  mobileno : string ='Please enter valid Phone No';
  titleAlert : string = 'This field is required';

  public count : any;


  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    public toastr: ToastrService,
    private changeDetection: ChangeDetectorRef) {

     

    this.addDrug = new FormGroup({
       brandName: new FormControl(null, Validators.required),
       route: new FormControl(null, Validators.required),
       unit: new FormControl(null, Validators.required),
       form: new FormControl(null, Validators.required),
       chemicals: this.fb.array([this.createChemicals()]), 
    });

    this.addMadication = new FormGroup({
       brandName: new FormControl(null, Validators.required),
       mRoute : new FormControl(null, Validators.required),
       mDrugId : new FormControl(null, Validators.required),
       usageType: new FormControl(null, Validators.required),
       mDosage1 : new FormControl(null, [Validators.pattern('^([0-9]{0,3}(,[0-9]{3})*(\.[0-9]+)?|\.[0-9]+)$')]),
       mDosage2 : new FormControl(null, [ Validators.pattern('^([0-9]{0,3}(,[0-9]{3})*(\.[0-9]+)?|\.[0-9]+)$')]),
       mDosage3 : new FormControl(null, [Validators.pattern('^([0-9]{0,3}(,[0-9]{3})*(\.[0-9]+)?|\.[0-9]+)$')]),
       mDosage4 : new FormControl(null, [ Validators.pattern('^([0-9]{0,3}(,[0-9]{3})*(\.[0-9]+)?|\.[0-9]+)$')]),
       mDosage : new FormControl(null, [ Validators.pattern('^([0-9]{0,3}(,[0-9]{3})*(\.[0-9]+)?|\.[0-9]+)$')]),

       mTimeodDay : new FormControl(null),
       mTimeodDay1 : new FormControl(null),
       mTimeodDay2 : new FormControl(null),
       mTimeodDay3 : new FormControl(null),
       mTimeodDay4 : new FormControl(null),

       mFood : new FormControl(null),
       mFood1 : new FormControl(null),
       mFood2 : new FormControl(null),
       mFood3 : new FormControl(null),
       mFood4 : new FormControl(null),

       mGAP : new FormControl(null),
       mGAP1 : new FormControl(null),
       mGAP2 : new FormControl(null),
       mGAP3 : new FormControl(null),
       mGAP4 : new FormControl(null),

       administeredDate : new FormControl(null, Validators.required),
       commenceDate : new FormControl(null, Validators.min(1)),
       mRemarks : new FormControl(null),
       tillNextReview : new FormControl(null)
    });



    this.pauseMadication = new FormGroup({
       remarks: new FormControl(null, Validators.required),
       id: new FormControl(null, Validators.required),
       resumeOn: new FormControl(null, Validators.required) 
    });

    this.resumeMadication = new FormGroup({
       remarks: new FormControl(null, Validators.required),
       id: new FormControl(null, Validators.required)
    });

    this.stopMedication  = new FormGroup({
       remarks: new FormControl(null, Validators.required),
       id: new FormControl(null, Validators.required)
    });

    this.oldMadication1 = new FormGroup({
      remarks: new FormControl(null, Validators.required)
    })


  }

  SetTimeType(value) {
    if(value == 1) {
        this.time_of_day = true;
        this.time_intervel = false;
        this.addMadication.controls['mTimeodDay'].setValue(null);
    } else {
      this.time_of_day = false;
      this.time_intervel = true;
    }
  }

  tilldateselected(){
    let val = this.addMadication.get('tillNextReview').value;
    if(val == null || val == false) {
      this.hideenddate = false;
    } else {
      this.hideenddate = true;
    }
    
  }

  openModal(template: TemplateRef<any>) {
    this.time_intervel = false;
    this.time_of_day = false;
    this.hideenddate = true;
    this.maxDate = new Date();

    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addMadication.reset();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addMadication.reset();
        this.unsubscribe();
      })
    );
    this.modalRef = this.modalService.show(template);
    this.selectedChemicals = [];
    this.poststatus = true;
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  openModal2(template: TemplateRef<any>) {
    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addDrug.reset();
        const arr = <FormArray>this.addDrug.get(`chemicals`);
        arr.controls = [];
        this.addChemicals();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addDrug.reset();
        this.unsubscribe();
      })
    );

    this.modalRef2 = this.modalService.show(template, { class: 'second' });
  }
  openModal3(template: TemplateRef<any>) {
    this.modalRef3 = this.modalService.show(template);
  }
  openModal4(template: TemplateRef<any>,val,index) {
    this.modalRef4 = this.modalService.show(template);
    this.indexvalue = index;
    this.resumeMadication.controls['id'].setValue(val.treatSchId); 
  }
  openModal5(template: TemplateRef<any>,val,index) {
    this.modalRef5 = this.modalService.show(template);
    this.indexvalue = index;
    console.log(val.val)
    this.pauseMadication.controls['id'].setValue(val.treatSchId); 
  }
  openModal6(template: TemplateRef<any>,val,index) {
    this.modalRef6 = this.modalService.show(template);
    this.indexvalue = index;
    console.log(val.val)
    this.stopMedication.controls['id'].setValue(val.treatSchId); 
  }

  openModal7(pill,template: TemplateRef<any>) {
    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addMadication.reset();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addMadication.reset();
        this.unsubscribe();
      })
    );

    this.morningId = undefined;
    this.afternoonId = undefined;
    this.eveningId = undefined;
    this.nightId = undefined;
    this.scheduledID = undefined;
    this.selectedPill = pill;

  console.log(pill);

    this.modalRef = this.modalService.show(template);
    this.selectedChemicals = [];
    this.poststatus = false;
    this.selectedMedication = pill;
    this.addMadication.controls['brandName'].setValue(pill.drugDetails.brandName);
    this.addMadication.controls['mRoute'].setValue(pill.drugDetails.brandName);
    
    
    this.selectedChemicals = pill.drugDetails.chemicals;
    this.drugsSelected(pill.drugDetails);

    let dosages = pill.dosage;
    if(dosages[0].timeOfDay == null) {
      this.scheduledID = dosages[0].id;
      this.addMadication.controls['usageType'].setValue('2');
      this.time_of_day = false;
      this.time_intervel = true;
      this.addMadication.controls['mTimeodDay'].setValue(24/dosages[0].noOfDosagesPerDay);
      this.addMadication.controls['mDosage'].setValue(dosages[0].dosageQty);
      if(dosages[0].beforeMeal == true) {
        this.addMadication.controls['mFood'].setValue('2');
      } 
      if(dosages[0].beforeMeal == false) {
        this.addMadication.controls['mFood'].setValue('3');
      }
      this.addMadication.controls['mGAP'].setValue(Math.abs(dosages[0].foodGapInMinutes));

    } else {
      this.addMadication.controls['usageType'].setValue('1');
      this.time_of_day = true;
      this.time_intervel = false;
      dosages.forEach(element => {
        if(element.timeOfDay == 'MORNING') {
          this.morningId = element.id;
          this.addMadication.controls['mTimeodDay1'].setValue('MORNING');
          this.addMadication.controls['mDosage1'].setValue(element.dosageQty);
          if(element.beforeMeal == true) {
            this.addMadication.controls['mFood1'].setValue('2');
          }
          if(element.beforeMeal == false) {
            this.addMadication.controls['mFood1'].setValue('3');
          }
          if( element.emptyStomach == true) {
            this.addMadication.controls['mFood1'].setValue('1');
          } 
          this.addMadication.controls['mGAP1'].setValue(Math.abs(element.foodGapInMinutes));


        } else if(element.timeOfDay == 'NOON') {
          this.afternoonId = element.id;
          this.addMadication.controls['mTimeodDay2'].setValue('NOON');
          this.addMadication.controls['mDosage2'].setValue(element.dosageQty);
          if(element.beforeMeal == true) {
            this.addMadication.controls['mFood2'].setValue('2');
          } 
          if(element.beforeMeal == false) {
            this.addMadication.controls['mFood2'].setValue('3');
          }
          if( element.emptyStomach == true) {
            this.addMadication.controls['mFood2'].setValue('1');
          } 
          this.addMadication.controls['mGAP2'].setValue(Math.abs(element.foodGapInMinutes));


        } else if(element.timeOfDay == 'EVENING') {
          this.eveningId = element.id;
          this.addMadication.controls['mTimeodDay3'].setValue('EVENING');
          this.addMadication.controls['mDosage3'].setValue(element.dosageQty);
          if(element.beforeMeal == true) {
            this.addMadication.controls['mFood3'].setValue('2');
          } 
          if(element.beforeMeal == false) {
            this.addMadication.controls['mFood3'].setValue('3');
          }
          if( element.emptyStomach == true) {
            this.addMadication.controls['mFood3'].setValue('1');
          }
          this.addMadication.controls['mGAP3'].setValue(Math.abs(element.foodGapInMinutes));


        } else if(element.timeOfDay == 'NIGHT') {
         
          this.nightId = element.id;
          this.addMadication.controls['mTimeodDay4'].setValue('NIGHT');
          this.addMadication.controls['mDosage4'].setValue(element.dosageQty);
          if(element.beforeMeal == true) {
            this.addMadication.controls['mFood4'].setValue('2');
          } 
          if(element.beforeMeal == false) {
            this.addMadication.controls['mFood4'].setValue('3');
          }
          if( element.emptyStomach == true) {
            this.addMadication.controls['mFood4'].setValue('1');
          }
          this.addMadication.controls['mGAP4'].setValue(Math.abs(element.foodGapInMinutes));
        }
      });
      
    }
    let startdate =  pill.commenceDateTime.split(" ");
    startdate = startdate[0].split("-");
    this.datepickerModel1 = new Date(Number(startdate[2]) ,Number(startdate[1])-1, Number(startdate[0]));
    this.maxDate = new Date(Number(startdate[2]) ,Number(startdate[1])-1, Number(startdate[0]));
    this.addMadication.controls['mRemarks'].setValue(pill.remarks);
    if( pill.tillNextReview == null || pill.tillNextReview == false) {
      
        this.addMadication.controls['commenceDate'].setValue(pill.consumptionDuration);
        this.hideenddate = true;
    } else {
      this.addMadication.controls['tillNextReview'].setValue(pill.tillNextReview);
      this.hideenddate = false;
    }
    
    
  }

  updateData(){
    this.childEvent.emit();
  }

  statDateSelected(value) {
    if(value != null) {
      this.datepickerModel5 = value;
    }
  }

  observableSource = (keyword: any): Observable<any[]> => {
    let url: string = appConfig.apiUrl + '/consultationSummary/searchDrug?pattern='+keyword;
    let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
    let options = new RequestOptions({ headers: headers });
    if (keyword) {     
      return this.http.get(url,options)
        .map((res: Response) => {
          console.log(res.json());
          return res.json()
        });
    } else {
      return Observable.of([]);
    }
  }

  drugsSelected(val){
    this.addMadication.controls['brandName'].setValue(val.brandName);
    this.addMadication.controls['mRoute'].setValue(val.route);
    this.addMadication.controls['mDrugId'].setValue(val.id);
    this.selectedChemicals = val.chemicals; 
    this.selectedunit = val.unit; 
    this.form = val.from;
    console.log(val);
  }








  createChemicals(): FormGroup  {
    return this.fb.group({
      name : new FormControl(null, Validators.required),
      strength : new FormControl(null, Validators.required),
    })
  }

  addChemicals() {  
      this.count = this.count + 1; 
      const arr = <FormArray>this.addDrug.get(`chemicals`);
      arr.push(this.createChemicals());    
  }

  removeChemicals(index){
      this.count = this.count - 1; 
      const arr = <FormArray>this.addDrug.get(`chemicals`);
      arr.removeAt(index);
  }

  submitForm(post) {
    
    console.log('post Data::'+JSON.stringify(post));
    this.apiService.postDrug(post)
        .subscribe(result => {
            if (result != null) {
              this.modalRef2.hide();
              this.addDrug.reset();
              this.toastr.success('Drug created successfully', 'Successfull');
            } else {
              this.toastr.error('Some thing went wrong', 'Sorry!');
            }
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };  
  }

  submitForm1(post) {

    if(this.poststatus == true) {
      this.morningId = undefined;
      this.afternoonId = undefined;
      this.eveningId = undefined;
      this.nightId = undefined;
      this.scheduledID = undefined;
    }
    console.log(post);
    let val1;
    if(post.administeredDate == null || post.administeredDate == undefined) {
      let datedate = new Date();
      val1 = new Date(datedate);
    } else {
      let datedate = new Date(post.administeredDate);
      val1 = new Date(datedate);
    }

    let val2 = new Date(post.commenceDate);
    let val3 = new Date();
    // alert(val2);
    // let duration = val2.getDate()  - val1.getDate();
    let diff = Math.abs(val2.getTime() - val1.getTime());
    let duration = post.commenceDate;
    let date = this.datePipe.transform(val3, 'dd-MM-yyyy')
    let administeredDate = this.datePipe.transform(val1, 'dd-MM-yyyy');
    post.administeredDate = this.datePipe.transform(post.administeredDate, 'dd-MM-yyyy HH:mm');
    let dosages = [];

    if(post.mTimeodDay1 != null && post.mTimeodDay1 != false && post.mTimeodDay == null){
      let emptyStomach = "false";
      let beforemeal = "false";
      let foodgap = post.mGAP1; 

      if( post.mFood1 == 1){
        emptyStomach = "true";
      }
      if( post.mFood1 == 2){
        beforemeal = "true";
        foodgap = -Math.abs(post.mGAP1);
        foodgap =  foodgap.toString();
      }
     
      let date1 = {
          timeOfDay : "MORNING",
          emptyStomach : emptyStomach,
          beforeMeal : beforemeal,
          foodGapInMinutes : foodgap,
          dosageQty : post.mDosage1,
          id : this.morningId
      }
      dosages.push(date1);
    }
    if(post.mTimeodDay2 != null && post.mTimeodDay2 != false && post.mTimeodDay == null){
      let emptyStomach = "false";
      let beforemeal = "false";
      let foodgap = post.mGAP2; 

      if( post.mFood2 == 1){
        emptyStomach = "true";
      }
      if( post.mFood2 == 2){
        beforemeal = "true";
        foodgap = -Math.abs(post.mGAP2);
        foodgap = foodgap.toString();
      }
      
      let date1 = {
          timeOfDay : "NOON",
          emptyStomach : emptyStomach,
          beforeMeal : beforemeal,
          foodGapInMinutes : foodgap,
          dosageQty : post.mDosage2,
          id : this.afternoonId
      }
      dosages.push(date1);
    }
    if(post.mTimeodDay3 != null && post.mTimeodDay3 != false && post.mTimeodDay == null){
      let emptyStomach = "false";
      let beforemeal = "false";
      let foodgap = post.mGAP3; 

      if( post.mFood3 == 1){
        emptyStomach = "true";
      }
      if( post.mFood3 == 2){
        beforemeal = "true";
        foodgap = -Math.abs(post.mGAP3);
        foodgap =  foodgap.toString();
      }
      
      let date1 = {
          timeOfDay : "EVENING",
          emptyStomach : emptyStomach,
          beforeMeal : beforemeal,
          foodGapInMinutes : foodgap,
          dosageQty : post.mDosage3,
          id : this.eveningId
      }
      dosages.push(date1);
    }

    if(post.mTimeodDay4 != null && post.mTimeodDay4 != false && post.mTimeodDay == null){
      let emptyStomach = "false";
      let beforemeal = "false";
      let foodgap = post.mGAP4; 

      if( post.mFood4 == 1){
        emptyStomach = "true";
      }
      if( post.mFood4 == 2){
        beforemeal = "true";
        foodgap = -Math.abs(post.mGAP4);
        foodgap = foodgap.toString();
      }
      
      let date1 = {
          timeOfDay : "NIGHT",
          emptyStomach : emptyStomach,
          beforeMeal : beforemeal,
          foodGapInMinutes : foodgap,
          dosageQty : post.mDosage4,
          id : this.nightId
      }
      dosages.push(date1);
    }

    if(post.mTimeodDay != null && post.mTimeodDay != false){
      let emptyStomach = "false";
      let beforemeal = "false";
      let foodgap = post.mGAP; 

      if( post.mFood == 1){
        emptyStomach = "true";
      }
      if( post.mFood == 2){
        beforemeal = "true";
        foodgap = -Math.abs(post.mGAP);
        foodgap = foodgap.toString();
      }
     
      let noofdosages = 24/Number(post.mTimeodDay);
      let date1 = {
          beforeMeal : beforemeal,
          foodGapInMinutes : foodgap,
          dosageQty : post.mDosage,
          noOfDosagesPerDay: noofdosages,
          id : this.scheduledID
      }
      dosages.push(date1);
    }
    if(post.tillNextReview) {
      duration = null;
    } else {
      duration = duration;
    }



    

    if(this.poststatus == true ) {

      post = {
        itemMasterId : post.mDrugId,
        administeredDate : date,
        status : "ACTIVE",
        commenceDateTime : post.administeredDate,
        consultationId: this.visitID,
        consumptionDuration : duration,
        tillNextReview : post.tillNextReview,
        treatmentType: "DRUG",
        remarks: post.mRemarks,
        dosage: dosages,
      }

    this.apiService.addMadication(post,this.visitId)
            .subscribe(result => {
                
                  this.addMadication.reset();
                  this.updateData();
                  this.modalRef.hide();
                  this.toastr.success('Medication added successfully', 'Successfull');

    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      }; 
    } else {

      post = {
        treatSchId : this.selectedMedication.treatSchId,
        itemMasterId : this.selectedMedication.itemMasterId,
        administeredDate : administeredDate,
        status : "ACTIVE",
        commenceDateTime : post.administeredDate,
        consultationId: this.visitID,
        consumptionDuration : duration,
        tillNextReview : post.tillNextReview,
        treatmentType: "DRUG",
        remarks: post.mRemarks,
        dosage: dosages,
      }
      this.apiService.putMadication(post,this.visitId,this.selectedMedication.treatSchId)
            .subscribe(result => {
                
                    this.addMadication.reset();
                    this.updateData();
                    this.modalRef.hide();
                    this.toastr.success('Medication updated successfully', 'Successfull');
      }, error => {
              let edata;
              if(error.apierror){ edata = error.apierror
              } else { edata = error }
              this.toastr.error(edata.message, edata.status);
        }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        }; 
    }
    console.log(post);
  }



  submitForm2(post){
   

    let todaysDate = new Date();
    let date = this.datePipe.transform(todaysDate, 'dd-MM-yyyy');
    post.resumeOn = this.datePipe.transform(post.resumeOn, 'dd-MM-yyyy');

    post = {
      drugSchId: post.id,
      interferenceDate: date,
      interferenceStatus: "PAUSED",
      treatmentType: "DRUG",
      remarks: post.remarks,
      resumeOn: post.resumeOn
    }

    this.apiService.pauseNDT(post,Number(this.visitID),Number(post.drugSchId))
            .subscribe(result => {
                this.updateData();
                this.modalRef5.hide();
                this.pauseMadication.reset();
                this.toastr.success('Medication paused successfully', 'Successfull');
                
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      }; 

     console.log('post Data::'+JSON.stringify(post));
  }

  submitForm3(post){
   

    let todaysDate = new Date();
    let date = this.datePipe.transform(todaysDate, 'dd-MM-yyyy');
    post.resumeOn = this.datePipe.transform(post.resumeOn, 'dd-MM-yyyy');

    post = {
      drugSchId: post.id,
      interferenceStatus: "RESUMED",
      treatmentType: "DRUG",
      remarks: post.remarks
    }

    this.apiService.pauseNDT(post,Number(this.visitID),Number(post.drugSchId))
            .subscribe(result => {
                this.modalRef4.hide();
                this.updateData();
                this.resumeMadication.reset();
                this.toastr.success('Medication resumed successfully', 'Successfull');
                
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };  

     console.log('post Data::'+JSON.stringify(post));
  }

  submitForm4(post) {
    
    post = {
      drugSchId: post.id,
      interferenceStatus: "STOPPED",
      remarks: post.remarks,
      treatmentType: "DRUG"
    }

    this.apiService.pauseNDT(post,Number(this.visitID),Number(post.drugSchId))
        .subscribe(result => {
            
                 console.log(result);
                 this.toastr.success('Medication stopped successfully', 'Successfull');
                 //this.NonDrugTherapies.push(post);
                 //this.NonDrugTherapies[this.indexvalue] = post;
                 this.stopMedication.reset();
                 this.modalRef6.hide();
                 this.updateData();

    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };

    console.log('post Data::'+JSON.stringify(post));

  }

  submitForm5(post){
      post = {
        remarks: post.remarks
      }

      this.apiService.continueMadication(post,Number(this.visitID),Number(this.patientID),post.remarks)
        .subscribe(result => {
            
                 console.log(result);
                 this.toastr.success('Continue old medication successfully', 'Successfull');
                 this.modalRef3.hide();
                 this.updateData();

    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
      
  }

 onChange(newValue) {
  	if(newValue = 0){
  		this.drugSelected= false;
  	}else{
  		this.drugSelected= true;
  	}

  }

  duration(newValue) {
  	if(newValue = 0){
  		this.durationSelected= false;
  	}else{
  		this.durationSelected= true;
  	}
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) { 

    this.patientID = this.patientId;
    this.visitID = this.visitId;
    this.Pills = this.pills;
    this.Pills.forEach(element => {
      let a = element.commenceDateTime.split(' ');
      element.commenceDateTime = a[0];
    });
    this.ConsultationSession = this.consultationSession;

  }

}
