import { Component, ViewChild, OnInit ,NgModule ,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule,DomSanitizer, SafeHtml  } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../../_services/api.service';
import { appConfig } from '../../../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var $ :any;

@Component({
  selector: 'app-patient-consultants',
  templateUrl: './patient-consultants.component.html',
  styleUrls: ['./patient-consultants.component.css']
})
export class PatientConsultantsComponent implements OnInit {
	 associateSP  : FormGroup;
  public user :any;

  public specializations:any[];
  public specialists:any;
  public associatedSpecialists:any;

  public specialization:any;
  public specialist:any
  public frequency:any

  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private _sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private titleService: Title,
    public toastr: ToastrService) {
     
    this.titleService.setTitle( 'AHS Specialists List' );

    this.associateSP = fb.group({
     specialization : [null,Validators.required],
     specialist: [null, Validators.required],
     frequency: [null, Validators.required]
    });


  }


  submitForm(post) {
    
      var patient = this.route.snapshot.params['id'];
      post = {
        clientId : patient,
        specialistId : post.specialist,  
      }
    console.log('post Data::'+JSON.stringify(post));

    this.apiService.postAssociateSpecialist(post,Number(patient),Number(post.specialistId))
              .subscribe(result => {
                  if (result != null) {
                       console.log(result);
                      this.loadAssociateSpecializations(Number(this.user.client_id));
                      // this.Message = "Specialist Created Successfully"
                  } else {
                    
                  }
      }); 
    
  }

  getCurrentPatients(id) {
        this.apiService.getCurrentPatients(id).subscribe(user => { 
          this.user = user;
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }

  loadSpecializations() {
        this.apiService.getSpecializations('AHSPARTNER').subscribe(specializations => { 
          this.specializations = specializations;
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }

  public filteredSpeclist:any;
  specializationSelected(val){
    this.filteredSpeclist = [];
    console.log(val);
    console.log(this.specialists);
    let data = this.specialists;
    for(let i=0; i < data.length; i++){
          if(data[i].specialityId == val){
            this.filteredSpeclist.push(data[i]);
          }
      }
  }

  loadSpecialists() {
        this.apiService.getAhspartner().subscribe(specialists => { 
          this.specialists = specialists;
          console.log(this.specialists.length);
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }

  loadAssociateSpecializations(id) {
        this.apiService.getAssociateAhspartner(id).subscribe(associatedSpecialists => { 
          this.associatedSpecialists = associatedSpecialists;
          this.associatedSpecialists = this.associatedSpecialists.assocSpecialists
          console.log('associated');
          console.log(this.associatedSpecialists);
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }


  disassociate(id){
      this.apiService.deleteAHSAssociation(Number(this.user.client_id),Number(id))
              .subscribe(result => {
                this.associatedSpecialists= [];
               this.toastr.success("Deassociated Successfully","Success");
               this.loadAssociateSpecializations(Number(this.user.client_id));
              
      }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }


  ngOnInit() {

  	this.user = this.route.snapshot.params['id'];
    console.log(this.user);
    this.getCurrentPatients(this.user);
  	this.loadSpecializations();
  	this.loadSpecialists();
    this.loadAssociateSpecializations(this.user);

  }

}
