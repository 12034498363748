import { Component, ViewChild, OnInit ,Input,NgModule ,OnChanges,SimpleChanges ,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule,DomSanitizer, SafeHtml  } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../../_services/api.service';
import { appConfig } from '../../../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

declare var $ :any;

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.css']
})
export class PersonalDetailsComponent implements OnInit {

	
	@ViewChild(ModalDirective) modal: ModalDirective;

  	public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  	public areas:any;

	updatePersonalDetails: FormGroup;

	public user: any;
	public userAdress:any;
	public userContacts:any;
	public intialFindings:any;

	public patientFirstName : any;
	public patientLastName : any;
	public patientGender : any;
	public patientDOB : any;
	public patientImage : any;

	public paHouseNo : any;
	public plotcColony : any;
	public area : any;
	public areaId : any;
	public paCity : any;
	public paPin : any;
	public paState : any;
	public paCountry : any;

	public paPhoneType : any;
	public paPhoneNo : any;

	public paEmail : any;

	public paInFindings : any;
	public paSince : any;
	public icdCode : any;
	public diagnosis_master_id : any;
	public diagnosisDesc:any;

	public paRemarks : any;
	public clientStatus: any;

	paAddresses: any[] = [];
	paPhonenos: any[] = [];
	paEmails: any[] = [];
	paFindings: any[] = [];

	datepickerModel: Date;

	currentfile:any;
	fileName:any;
	filetype:any;
	fileValue:any;

	createdUser: any;

	validemail : string ='Please enter the valid email.';
  	mobileno : string ='Please enter valid Phone No';
  	titleAlert : string = 'This field is required';

  	 public count  : any;
  	 public count1 : any;
  	 public count2 : any;
  	 public count3 : any;


	constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private titleService: Title,
    public toastr: ToastrService){

    	this.titleService.setTitle( 'Client Details' );
    	 

		this.updatePersonalDetails = fb.group({

		 patientFirstName: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
		 patientLastName: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
		 patientGender: new FormControl(null, Validators.required),
		 patientDOB: new FormControl(null, Validators.required),
		 patientImage:new FormControl(null),
		 paRemarks:new FormControl(null),

		 paAddresses: this.fb.array([]),
		 paPhonenos: this.fb.array([]),
		 paEmails: this.fb.array([]),
		 paFindings: this.fb.array([]),
     
    	});

    	


	 	
	}

	createAddress() {
		return this.fb.group({
		  id:  new FormControl(''),
		  paHouseNo : new FormControl('', [Validators.required, Validators.maxLength(50)]),
		  plotcColony : new FormControl('', [Validators.required, Validators.maxLength(100)]),
		  area : new FormControl('', Validators.required),
		  areaId : new FormControl(''),
		  paCity : new FormControl('', Validators.required),
		  paPin :new FormControl('',[Validators.required,
		  					Validators.minLength(6),
     						Validators.maxLength(6),
      						Validators.pattern('[0-9]+')]),
		  paState : new FormControl('', Validators.required),
		  paCountry : new FormControl('India', Validators.required)
		})
	}

	addNewAddress() {  
	    this.count = this.count + 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paAddresses`);
	    arr.push(this.createAddress());    
	}

	removeNewAddress(index){
	    this.count = this.count - 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paAddresses`);
	    arr.removeAt(index);
	}



	createPhoneno() {
		return this.fb.group({
		  id:  new FormControl(''),
		  paPhoneType : new FormControl('', Validators.required),
		  paPhoneNo : new FormControl('',  [Validators.required,
		  					Validators.minLength(10),
     						Validators.maxLength(10),
      						Validators.pattern('[0-9]+')
    				   ]),
		})
	}

	addPhoneno() {  
	    this.count = this.count + 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paPhonenos`);
	    arr.push(this.createPhoneno());    
	}

	removePhoneno(index){
	    this.count = this.count - 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paPhonenos`);
	    arr.removeAt(index);
	}


	createEmail() {
		return this.fb.group({
		  id:  new FormControl('',[Validators.minLength(6),
			Validators.maxLength(40),Validators.required] ),
		  paEmail :  new FormControl('', Validators.required),
		})
	}

	addEmail() {  
	    this.count = this.count + 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paEmails`);
	    arr.push(this.createEmail());    
	}

	removeEmail(index){
	    this.count = this.count - 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paEmails`);
	    arr.removeAt(index);
	}






	createFindings() {
		return this.fb.group({
		  diagnosisDesc : new FormControl(''),
		  icdCode : new FormControl(''),
		  diagnosis_master_id : new FormControl(''),
		  diagnosis_id : new FormControl(''),
		  paSince : new FormControl(''),
		})
	}

	addFinding() {  
	    this.count = this.count + 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paFindings`);
	    arr.push(this.createFindings());    
	}

	removeFinding(index){
	    this.count = this.count - 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paFindings`);
	    arr.removeAt(index);
	}



	areaSelected(val,i){

		console.log(val);

		const control = this.updatePersonalDetails.get(`paAddresses.${i}`)['controls'];

		control.area.setValue(val.area);
		control.areaId.setValue(val.id);
		control.paCity.setValue(val.cityDistrict);
		control.paPin.setValue(val.pin);
		control.paState.setValue(val.state);

		// control.patchValue({
		// 	area :val.area,
		// 	areaId : val.id,
		// 	paCity: val.cityDistrict,
		// 	paPin: val.pin,
		// 	paState:val.state
		// });
	}




	submitdata() {
		
		let post = JSON.parse(JSON.stringify(this.updatePersonalDetails.value));
		//console.log(post);
		if(post.patientDOB == this.user.personalDetails.dob){
			post.patientDOB = this.user.personalDetails.dob;
		} else {
			post.patientDOB = this.datePipe.transform(post.patientDOB, 'dd-MM-yyyy');
		}

		let contacts:any;
		let j = post.paAddresses.length;
		for (let i = 0; i < j; i++) {
			let cuurent_address = undefined;
			if( i == 0){
				cuurent_address = true;
			} else {
				cuurent_address = undefined;
			}
		  let firstArray = {
		  	id: post.paAddresses[i].id,
		  	plotFlatNo : post.paAddresses[i].paHouseNo, 
		  	streetColony : post.paAddresses[i].plotcColony,
		  	areaId : post.paAddresses[i].areaId,
			addrType : "RESIDENCE",
			currentAddress: cuurent_address};
			if(firstArray.plotFlatNo != undefined && firstArray.plotFlatNo != "") {
			  	post.paAddresses[i] = firstArray;
			}
		}
		let l = post.paPhonenos.length;
		for (let i = 0; i < l; i++) {
			let primary_contact = undefined;
			let id;
			if( i == 0){
				primary_contact = true;
			} else {
				primary_contact = false;
			}
			if(post.paPhonenos[i].id != '') {
				id = post.paPhonenos[i].id;
			}
		  	let firstArray = {
		  	id: id,
		  	type : post.paPhonenos[i].paPhoneType, 
			  value : post.paPhonenos[i].paPhoneNo,
			  primary_contact : primary_contact};
			  if(firstArray.value != undefined && firstArray.value != "") {
		  		post.paPhonenos[i] = firstArray;
		  	  }
		}

		let k = post.paEmails.length;
		for (let i = 0; i < k; i++) {
		  	let firstArray = {
		  	id: post.paEmails[i].id,
			  type : "EMAIL", 
			  primary_contact : false,
		  	value : post.paEmails[i].paEmail};
		  	if(firstArray.value != undefined && firstArray.value != "") {
			  	post.paPhonenos.push(firstArray);
			}
		}

		let findings = [];
		let p = post.paFindings.length;
		for(let i = 0; i < p; i++){
			//console.log(post.paFindings[i].paSince);

			if(post.paFindings[i].paSince != '') {
			if(this.intialFindings[i].paSince == post.paFindings[i].disease_since) {
				post.paFindings[i].paSince = this.intialFindings[i].disease_since;
			} else {
				console.log(this.intialFindings[i].paSince);
				post.paFindings[i].paSince = this.datePipe.transform(post.paFindings[i].paSince, 'dd-MM-yyyy');
			}
			
			if(post.paFindings[i].diagnosis_id == ""){
			   		post.paFindings[i].diagnosis_id = undefined;
			}
			let data = {
				predated : true,
		  		disease : post.paFindings[i].diagnosisDesc, 
		  		icd_code : post.paFindings[i].icdCode,
		  		diagnosis_master_id : post.paFindings[i].diagnosis_master_id,
		  		disease_since : post.paFindings[i].paSince,
		  		diagnosis_id:  post.paFindings[i].diagnosis_id
		  	};
		  	if(data.disease != undefined && data.disease != "" ) {
			  	findings.push(data);
			}
		}
		}

		post = { clientStatus :  this.clientStatus,
				 diseaseProfile : findings,
				 client_id : this.user.client_id,
				 drInitialComments:post.paRemarks,
				 // personId : this.user.personId,
			     personalDetails :
			     {
			     	 id:this.user.personId,
			         firstName :  post.patientFirstName,
			         lastName :  post.patientLastName,
			         gender :  post.patientGender,
			         dob :  post.patientDOB,
			         addresses :post.paAddresses,
			         contacts : post.paPhonenos
			    }
			}

		this.apiService.putPatients(post,this.user.personId)
	 	           .subscribe(result => {
	 	              
		                   this.toastr.success('User updated Successfully', 'Congrats!');
		                    // this.Message = "Specialist Created Successfully"
		                    this.createdUser = result;
		                    // this.updatePersonalDetails.reset();
		                    this.uploadImage(this.createdUser);

		                    this.getCurrentPatients(this.user);
	 	             
			    }, error => {
	                let edata;
	                if(error.apierror){ edata = error.apierror
	                } else { edata = error }
	                this.toastr.error(edata.message, edata.status);
	              }),
	              err   => {
	                this.toastr.error('500 Internal Server Error!');           
	            }; 

	  console.log(post);
	}

	onFileChange(event) {
	    this.currentfile =  event.target.files;
	    console.log(this.currentfile); 
	    let reader = new FileReader();
	    if(event.target.files && event.target.files.length > 0) {
	      let file = event.target.files[0];
	      reader.readAsDataURL(file);
	      
	      reader.onload = () => {
	        this.fileName = file.name;
	        this.filetype = file.type;
	        this.fileValue = reader.result.split(',')[1];
	        
	      };
	    }
	    let image = this.currentfile.item(0);
	    this.apiService.postPatientMedia(image,Number(this.user.personId))
	           .subscribe(result => {
	               
	                    console.log(result);
	                    this.toastr.success('upload profile image Successfully', 'Congrats!');
	              
		    }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            }; 
	    
	}

	uploadImage(userid){
		let image = this.currentfile.item(0);
	    this.apiService.postPatientMedia(image,Number(userid.personalDetails.id))
           .subscribe(result => {
              
                    this.toastr.success('upload profile image Successfully', 'Congrats!');
             
	    }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            };  
	}

	loadAreas() {	
        this.apiService.getAreas().subscribe(areas => { 
          this.areas = areas;
          this.areas = this.areas.areas;
          console.log(this.areas);
        }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            };
  	}

 

  	autocompleListFormatter = (data: any) : SafeHtml => {
	    let html = `<span>${data.area}</span>`;
	    return this._sanitizer.bypassSecurityTrustHtml(html);
	}

	observableSource = (keyword: any): Observable<any[]> => {
	    let url: string = appConfig.apiUrl + '/consultationSummary/diagnosis/search?pattern='+keyword;
	    let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
	    let options = new RequestOptions({ headers: headers });
	    if (keyword) {     
	      return this.http.get(url,options)
	        .map((res: Response) => {
	        	console.log(res.json())
	        	return res.json() 
	        })
	        
	    } else {
	      return Observable.of([]);
	    }
	}


	diagnosisSelected(val,i){  
	    console.log(val);
	    const control = this.updatePersonalDetails.get(`paFindings.${i}`)['controls'];
		control.diagnosisDesc.setValue(val.diagnosisDesc);
		control.icdCode.setValue(val.icdCode);
		control.diagnosis_master_id.setValue(val.id);
	}

	 getCurrentPatients(id) {
	        this.apiService.getCurrentPatients(id).subscribe(user => { 
	          this.user = user;

	          this.userAdress = this.user.personalDetails.addresses;
			  this.userContacts = this.user.personalDetails.contacts;
			  this.intialFindings = this.user.diseaseProfile;
			  this.clientStatus = this.user.clientStatus


			   let control1 = <FormArray>this.updatePersonalDetails.controls.paPhonenos;
			   let control2 = <FormArray>this.updatePersonalDetails.controls.paEmails;
			   let control3 = <FormArray>this.updatePersonalDetails.controls.paFindings;
			   let emial = 0;
			   let phones = 0;

			   let find = 0;

			   this.intialFindings.forEach(x => {
			   	find = find + 1;

				control3.push(this.fb.group({
				  diagnosisDesc : x.disease,
				  paSince : x.disease_since,
				  icdCode: x.icd_code,
				  diagnosis_master_id: x.diagnosis_master_id,
				  diagnosis_id: x.diagnosis_id
				 }))
			   })

			   this.userContacts.forEach(x => {
			   		
			   		if(x.type == 'EMAIL'){
			   			emial = emial + 1;
						control2.push(this.fb.group({
						  id: x.id,
						  paEmail :  new FormControl(x.value, [Validators.minLength(6),
							Validators.maxLength(40),Validators.required]),
						}))
					}else{
						phones = phones + 1;
						control1.push(this.fb.group({
						  id: x.id,
						  paPhoneType : new FormControl(x.type, Validators.required),
						  paPhoneNo :  new FormControl(x.value,  [Validators.required,
							Validators.minLength(10),
						   Validators.maxLength(10),
							Validators.pattern('[0-9]+')
					 ]),
						}))

					}
			   })

			   if(emial === 0) {
					control2.push(this.fb.group({
						  paEmail : new FormControl('', [Validators.minLength(9),
							Validators.maxLength(40),Validators.required]),
					}))
				}
				if(phones === 0) {
					control1.push(this.fb.group({
						paPhoneType : new FormControl('', Validators.required),
						paPhoneNo :  new FormControl('',  [Validators.required,
						  Validators.minLength(10),
						 Validators.maxLength(10),
						  Validators.pattern('[0-9]+')
				   ]),
					}))
				}

			   

			   if(find === 0) {
					this.addFinding();
				}

				this.updatePersonalDetails.controls['patientFirstName']
			  .setValue(this.user.personalDetails.firstName);
			  
			  this.updatePersonalDetails.controls['patientLastName']
	          .setValue(this.user.personalDetails.lastName);

	          let control = <FormArray>this.updatePersonalDetails.controls.paAddresses;
			   this.userAdress.forEach((x,index) => {

				control.push(this.fb.group({
				  id: x.id,
				 

				  paHouseNo : new FormControl(x.plotFlatNo, [Validators.required, Validators.maxLength(50)]),
		  plotcColony : new FormControl(x.streetColony, [Validators.required, Validators.maxLength(100)]),
		  area : new FormControl(x.area, Validators.required),
		  areaId : new FormControl(x.areaId),
		  paCity : new FormControl(x.cityDistrict, Validators.required),
		  paPin :new FormControl(x.pin,[Validators.required,
		  					Validators.minLength(6),
     						Validators.maxLength(6),
      						Validators.pattern('[0-9]+')]),
		  paState : new FormControl(x.state, Validators.required),
		  paCountry : new FormControl('India', Validators.required)
				}))
			   });

			    this.updatePersonalDetails.controls['patientGender']
	          .setValue(this.user.personalDetails.gender);

	          this.updatePersonalDetails.controls['patientDOB']
	          .setValue(this.user.personalDetails.dob);
	          this.updatePersonalDetails.controls['paRemarks']
	          .setValue(this.user.drInitialComments);
	          

	          setTimeout(()=>{    //<<<---    using ()=> syntax
			      $('.firstname').focus();
			 }, 500);


	         

	        }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            };
	 }


	
	
	ngOnInit() {
		this.loadAreas();
		this.user = this.route.snapshot.params['id'];
    	console.log(this.user);
    	this.getCurrentPatients(this.user);
	}
}
