import { Component, ViewChild, OnInit ,NgModule ,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule,DomSanitizer, SafeHtml  } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../../_services/api.service';
import { appConfig } from '../../../../app.config';
import { DatePipe } from '@angular/common';
declare var $ :any;

import { ToastrService } from 'ngx-toastr'; 

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.css']
})
export class SponsorComponent implements OnInit {

	updateSponsorDetails: FormGroup;
	
	@ViewChild(ModalDirective) modal: ModalDirective;

  	public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

	public patientFirstName : any;
	public patientLastName : any;
	public patientGender : any;
	public patientDOB : any;

	public paHouseNo : any;
	public plotcColony : any;
	public area : any;
	public areaId : any;
	public paCity : any;
	public paPin : any;
	public paState : any;
	public paCountry : any;

	public paPhoneNo : any;
	public paTimeofCall : any;
	public paTimeZone : any;

	public paEmail : any;

	public areas : any;
	public user: any;



	paAddresses: any[] = [];
	paPhonenos: any[] = [];
	paEmails: any[] = [];

	datepickerModel: Date;
	maxDate: Date;

	validemail : string ='Please enter the valid email.';
  	mobileno : string ='Please enter valid Phone No';
  	titleAlert : string = 'This field is required';

  	 public count : any;
  	 public count1 : any;
  	 public count2 : any;

  	constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private _sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public toastr: ToastrService){

    	 
		this.maxDate = new Date();

		this.updateSponsorDetails = fb.group({

		 patientFirstName: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
		 patientLastName: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
		 patientGender: new FormControl(null, Validators.required),
		 patientDOB: new FormControl(null, Validators.required),

		 paAddresses: this.fb.array([this.createAddress()]),
		 paPhonenos: this.fb.array([this.createPhoneno()]),
		 paEmails: this.fb.array([this.createEmail()]),
     
    	});
	 	
	}

	createAddress() {
		return this.fb.group({
		  paHouseNo : new FormControl(null, [Validators.required, Validators.maxLength(25)]),
		  plotcColony : new FormControl(null, [Validators.required, Validators.maxLength(25)]),
		  area : new FormControl('', Validators.required),
		  areaId : new FormControl(''),
		  paCity : new FormControl('', Validators.required),
		  paPin :new FormControl('',[Validators.required,
		  					Validators.minLength(6),
     						Validators.maxLength(6),
      						Validators.pattern('[0-9]+')]),
		  paState : new FormControl('', Validators.required),
		  paCountry : new FormControl('India', Validators.required)
		})
	}

	addNewAddress() {  
	    this.count = this.count + 1; 
	    const arr = <FormArray>this.updateSponsorDetails.get(`paAddresses`);
	    arr.push(this.createAddress());    
	}

	removeNewAddress(index){
	    this.count = this.count - 1; 
	    const arr = <FormArray>this.updateSponsorDetails.get(`paAddresses`);
	    arr.removeAt(index);
	}


	createPhoneno() {
		return this.fb.group({
		  paPhoneNo : ['', [Validators.required,
		  					Validators.minLength(9),
     						Validators.maxLength(10),
      						Validators.pattern('[0-9]+')
    				   ]],

    	 paTimeofCall : '',
		 paTimeZone : '',
		})
	}

	addPhoneno() {  
	    this.count = this.count + 1; 
	    const arr = <FormArray>this.updateSponsorDetails.get(`paPhonenos`);
	    arr.push(this.createPhoneno());    
	}

	removePhoneno(index){
	    this.count = this.count - 1; 
	    const arr = <FormArray>this.updateSponsorDetails.get(`paPhonenos`);
	    arr.removeAt(index);
	}


	createEmail() {
		return this.fb.group({
		  Email : new FormControl('', Validators.required),
		})
	}

	addEmail() {  
	    this.count = this.count + 1; 
	    const arr = <FormArray>this.updateSponsorDetails.get(`paEmails`);
	    arr.push(this.createEmail());    
	}

	removeEmail(index){
	    this.count = this.count - 1; 
	    const arr = <FormArray>this.updateSponsorDetails.get(`paEmails`);
	    arr.removeAt(index);
	}


	loadAreas() {	
        this.apiService.getAreas().subscribe(areas => { 
          this.areas = areas;
          this.areas = this.areas.areas;
          console.log(this.areas);
        }, error => {
	        let edata;
	        if(error.apierror){ edata = error.apierror
	        } else { edata = error }
	        this.toastr.error(edata.message, edata.status);
	      }),
	      err   => {
	        this.toastr.error('500 Internal Server Error!');           
	    };
  	}


	autocompleListFormatter = (data: any) : SafeHtml => {
	    let html = `<span>${data.area}</span>`;
	    return this._sanitizer.bypassSecurityTrustHtml(html);
	}

	areaSelected(val,i){
		console.log(val);
		const control = this.updateSponsorDetails.get(`paAddresses.${i}`)['controls'];
		control.area.setValue(val.area);
		control.areaId.setValue(val.id);
		control.paCity.setValue(val.cityDistrict);
		control.paPin.setValue(val.pin);
		control.paState.setValue(val.state);
	}


	submitForm(post) {
		console.log(post);
		post.patientDOB = this.datePipe.transform(post.patientDOB, 'dd-MM-yyyy');
		let contacts:any;
		let j = post.paAddresses.length;
		for (let i = 0; i < j; i++) {
		  let firstArray = {
		  		plotFlatNo : post.paAddresses[i].paHouseNo, 
				streetColony : post.paAddresses[i].plotcColony,
				areaId : post.paAddresses[i].areaId,
				addrType : "RESIDENCE",
				currentAddress: true,};
		  	post.paAddresses[i] = firstArray;
		}
		let l = post.paPhonenos.length;
		for (let i = 0; i < l; i++) {
			let primary_contact;
			if( i == 0){
				primary_contact = true;
			} else {
				primary_contact = false;
			}
		  	let firstArray = {
		  	type : "PHONE", 
			value : post.paPhonenos[i].paPhoneNo,
			primary_contact : primary_contact,
		  	availability : [{
				startTime: "06:00",
				endTime : "07:00"
			}] };
		  	post.paPhonenos[i] = firstArray;
		}

		let k = post.paEmails.length;
		for (let i = 0; i < k; i++) {
			let primary_contact;
			if( i == 0){
				primary_contact = true;
			} else {
				primary_contact = false;
			}
		  	let firstArray = {
			type : "EMAIL", 
			primary_contact : primary_contact,
		  	value : post.paEmails[i].Email};
		  	post.paEmails[i] = firstArray;
		}


		post = {  firstName :  post.patientFirstName,
				  lastName :  post.patientLastName,
				  gender : post.patientGender,
				  dob :  post.patientDOB,
				  addresses : post.paAddresses,
				  phoneContacts : post.paPhonenos,
				  emailContacts : post.paEmails
		}
		this.user = this.route.snapshot.params['id'];

		this.apiService.postSponsor(post,this.user)
	            .subscribe(result => {

		          if (result != null) {
		            console.log(result);
		            this.updateSponsorDetails.reset();
		            this.toastr.success('Sponsor created successfully', 'Successful!');
		          } else {
		             this.toastr.error('Some thing went wrong', 'Sorry!');
		          }
		    }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            }; 
    console.log('post Data::'+JSON.stringify(post));
  }






  ngOnInit() {
		this.loadAreas();

  }

}
