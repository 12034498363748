import { Component, OnInit, Input, OnChanges,SimpleChanges } from '@angular/core';

import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { AbstractControl,FormArray,ReactiveFormsModule, FormBuilder, FormGroup, FormControl, Validators, FormsModule ,NgForm  } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import {  ViewChild ,NgModule,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
declare var $ :any; 

import { User } from '../../_models/index';
import { UserService } from '../../_services/index';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  currentUser: User;
  title :string;

    constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private userService: UserService,
    private titleService: Title ) {

        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.title = this.titleService.getTitle();
        router.events.subscribe((val) => {
          this.title = this.titleService.getTitle();
        });

        
    }

    ngOnInit() {
    	this.title = this.titleService.getTitle();
    }

    updateTitle(){
        //alert('hai');
        this.title = this.titleService.getTitle();
    }

    ngOnChanges(changes: SimpleChanges) { 
      this.title = this.titleService.getTitle();
    }


}
