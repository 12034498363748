import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-monitor-treatment',
  templateUrl: './doctor-monitor-treatment.component.html',
  styleUrls: ['./doctor-monitor-treatment.component.css']
})
export class DoctorMonitorTreatmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
