import { Component,ChangeDetectorRef, ViewChild, OnInit ,EventEmitter,Input,Output,NgModule ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef  }  from '@angular/core';

import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { AbstractControl,FormArray,ReactiveFormsModule, FormBuilder, FormGroup, FormControl, Validators, FormsModule ,NgForm  } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';


import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable, combineLatest, Subscription } from 'rxjs';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../../_services/api.service';
import { appConfig } from '../../../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { ToastrService } from 'ngx-toastr';

declare var $ :any; 

@Component({
  selector: 'app-renew-package',
  templateUrl: './renew-package.component.html',
  styleUrls: ['./renew-package.component.css']
})
export class RenewPackageComponent implements OnInit {

	@ViewChild(ModalDirective) modal: ModalDirective;

	renewPackage: FormGroup;

	modalRef: BsModalRef;

	public user :any;

	public renewDate : any;
	public renewPeriod : any;
	public renewAmount : any;
	public renewMode : any;
	public packageId : any;
	subscriptions: Subscription[] = [];

	datepickerModel: Date;

	validemail : string ='Please enter the valid email.';
  	mobileno : string ='Please enter valid Phone No';
  	titleAlert : string = 'This field is required';

  	constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private titleService: Title,
	public toastr: ToastrService,
	private changeDetection: ChangeDetectorRef){

    	 
    	this.titleService.setTitle( 'Renew Package' );

		this.renewPackage = fb.group({
		 packageId : [null],
		 renewDate: [null, Validators.required],
		 renewAmount: [null, Validators.required],
		 renewMode: [null, Validators.required],
    	});
	 	
	}

	openModal(template: TemplateRef<any>,id) {
		const _combine = combineLatest(
			this.modalService.onHide,
			this.modalService.onHidden
		  ).subscribe(() => this.changeDetection.markForCheck());
	   
		  
		  this.subscriptions.push(
			this.modalService.onHide.subscribe((reason: string) => {
			  const _reason = reason ? `, dismissed by ${reason}` : '';
			  this.renewPackage.reset();
			})
		  );
		  this.subscriptions.push(
			this.modalService.onHidden.subscribe((reason: string) => {
			  const _reason = reason ? `, dismissed by ${reason}` : '';
			  this.renewPackage.reset();
			  this.unsubscribe();
			})
		  );
	   
		  this.subscriptions.push(_combine);

	    this.modalRef = this.modalService.show(template);
	    this.packageId = id;
	}

	unsubscribe() {
		this.subscriptions.forEach((subscription: Subscription) => {
		  subscription.unsubscribe();
		});
		this.subscriptions = [];
	}

	submitForm(post) {
		let val1 = null;
		let val2 = null;
		let renewDate = new Date(post.renewDate);
		val1 = this.datePipe.transform(renewDate,'dd-MM-yyyy');
		let expireDate = new Date(post.renewDate);
    	val2 = expireDate.setDate(expireDate.getDate() + Number(post.renewPeriod));
    	//expireDate = expireDate.toString();
    	
		
    	val2 = this.datePipe.transform(val2, 'dd-MM-yyyy');

    	post = {
			renewStatus : "ACTIVE",
			enrollDate : val1,
			amountPaid : post.renewAmount,
			paymentMode : post.renewMode,
			packageId : post.packageId,
			clientId : this.user.client_id
		}
		console.log('post Data::'+JSON.stringify(post));

		this.apiService.postPackage(post,Number(this.user.client_id))
	            .subscribe(result => {
	                if (result != null) {
	                    console.log(result);
	                    this.modalRef.hide();
	                    this.renewPackage.reset();
            			this.toastr.success('Package updated successfully', 'Successful!');
	                } else {
	                  this.toastr.error('Some thing went wrong', 'Sorry!');
	                }
	    }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            };  
		
	}

  ngOnInit() {

  		this.user = this.route.snapshot.params['id'];
		console.log(this.user);
		this.getCurrentPatients(this.user);
  }

  getCurrentPatients(id) {
        this.apiService.getCurrentPatients(id).subscribe(user => { 
          this.user = user;
        }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            };
  }

}
