import { Component,ChangeDetectorRef, ViewChild, OnInit ,EventEmitter,Input,Output,NgModule ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable, combineLatest, Subscription } from 'rxjs';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $ :any;

@Component({
  selector: 'app-examination-widget',
  templateUrl: './examination-widget.component.html',
  styleUrls: ['./examination-widget.component.css']
})
export class ExaminationWidgetComponent implements OnInit,OnChanges {
  @ViewChild(ModalDirective) modal: ModalDirective;
  @Output() childEvent = new EventEmitter();
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  @Input() public patientId:any;
  @Input() public visitId: any;
  @Input() public examinations: any; 
  @Input() public addnew: any;

  public patientID:any;
  public visitID : any;
  public Examinations : any;

  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  subscriptions: Subscription[] = [];

  Erecords = [
  {examMasterId:'1',exam_type: 'Exam Type 01', summary:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita, eius, magnam.'},
  {examMasterId:'1',exam_type: 'Exam Type 01', summary:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita, eius, magnam.'},
  {examMasterId:'1',exam_type: 'Exam Type 01', summary:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita, eius, magnam.'},
  ];


  public examination_descriptions = [
    {id:1, exam:'General', value:"Healthy appearing, alert, no acute distress, normal habitus, overweight, obese, underweight, thin, frail, mild, moderate and severe distress , uncooperative, agitated, pale, flushed, crying , cyanotic, dyspneic and ***"},
    {id:2, exam:'Vitals', value:""},
    {id:3, exam:'Skin', value:"negative, Normal color without pallor, cyanosis, masses, nodules, ulcerations, lesions, rash or discolorations. , Evidence of pallor, cyanosis, masses, nodules, ulcerations, lesions, rash or discolorations. and ***"},
    {id:4, exam:'Head-Eye-Ear-Nose-Throat', value:"negative, Conjunctiva clear, jaundice, erythematous ***, discharge ***, pink, pale, moist, dry and ***, Pupils: Pupils Equal, Regular, Reacting to Light and Accommodation, dilated, pinpoint, cataract ***, asymmetric *** and ***, Extraocular movements intact, abnormal and ***, Fundoscopic exam normal ***, retinal lesions present on the right, limited exam on the right and *** and ***.    "},
    {id:5, exam:'Cardio Vascular System', value:"Regular rate and rhythm, S1/S2, no gallop, murmur, or rub, no bruits, carotid artery/radial artery/dorsalis pedis 2+ symmetric, no edema or varicosities., Abnormal: radial R 4+, 3+, 2+, 1+, present , diminished, absent, bruit, bounding and *** L {:10837} and carotid R {:10837}, L {:10837}, dorsalis pedis non-palpable, femoral bruit, edema upper, lower, full, partial, *** extremity, left upper, lower, full, partial, *** extremity, generalized and ***, varicosities {location}, tachycardia, bradycardia, irregular rhythm, rub, S3, S4, murmurs: 6/6 early systolic, mid systolic, late systolic, holosystolic, diastolic, crescendo-decrescendo, ejection, harsh, blowing, rumbling, musical, vibratory, coarse, *** murmur aortic area, pulmonic area, apex, base, left lower sternal border and upper left sternal border, 6/6 early systolic, mid systolic, late systolic, holosystolic, diastolic, crescendo-decrescendo, ejection high pitched harsh, blowing, rumbling, musical, vibratory, coarse, *** murmur aortic area, pulmonic area, apex, base, left lower sternal border and upper left sternal border and *** and ***, *** 1+ edema to back and 4+ edema to back. Fistula/AV Graft not present."},
    {id:6, exam:'Respitory Systems', value:"negative, clear to auscultation, wheezes Right upper anterior, Right upper posterior, Right mid anterior, Right mid posterior, Right lower anterior, Right lower posterior, Left upper anterior, Left upper posterior, Left mid anterior, Left mid posterior, Left lower anterior, Left lower posterior, bibasilar and ***, rhonchi Right upper anterior, Right upper posterior, Right mid anterior, Right mid posterior, Right lower anterior, Right lower posterior, Left upper anterior, Left upper posterior, Left mid anterior, Left mid posterior, Left lower anterior, Left lower posterior and bibasilar, crackles Right upper anterior, Right upper posterior, Right mid anterior, Right mid posterior, Right lower anterior, Right lower posterior, Left upper anterior, Left upper posterior, Left mid anterior, Left mid posterior, Left lower anterior, Left lower posterior and bibasilar, decreased breath sounds Right upper anterior, Right upper posterior, Right mid anterior, Right mid posterior, Right lower anterior, Right lower posterior, Left upper anterior, Left upper posterior, Left mid anterior, Left mid posterior, Left lower anterior, Left lower posterior and bibasilar, egophony Right upper anterior, Right upper posterior, Right mid anterior, Right mid posterior, Right lower anterior, Right lower posterior, Left upper anterior, Left upper posterior, Left mid anterior, Left mid posterior, Left lower anterior, Left lower posterior, bibasilar and ***, dull to percussion Right upper anterior, Right upper posterior, Right mid anterior, Right mid posterior, Right lower anterior, Right lower posterior, Left upper anterior, Left upper posterior, Left mid anterior, Left mid posterior, Left lower anterior, Left lower posterior and bibasilar, chest wall ***, cyanosis none, toes, fingers and ***, clubbing none, toes, fingers and ***, cough was normal and ***."},
    {id:7, exam:'Abdomen', value:"soft non-tender bowel sounds normal, no masses or hepatosplenomegaly, hyperactive bowel sounds, no bruits heard, tenderness, mass, transplant kidney palpable non tender, hepatomegaly, bruit heard and ***."},
    {id:8, exam:'Central Neurological System', value:"negative, Alert and oriented x 3. Cranial nerves grossly intact, Abnormal: cranial nerve deficit {location}, reflex nerve deficit {location}, hyper reflexive, receptive aphasia, expressive aphasia, dysarthria, nystagmus {LEFT/RIGHT:10940}, tremor {location}, fasiculation ***, rest tremor, intention tremor, tic {location}, sensory deficit {location} and memory loss and no focal deficits    "},
    {id:9, exam:'Musculoskeletal', value:"negative, Normal gait, normal strength and range of motion of all extremities and Abnormal: walks with assistive device, abnormal gait, lack of coordination, decreased strength {location}, limited range of motion {location}, wheelchair bound."},
  ]


  addExamination: FormGroup;
  editExamination: FormGroup;

  public exam_type: any;
  public summary:any;
  public ex_form_type : any;
  public index_value : any;
  public selectedexamination :any;
  public examp_master_id : any;



  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    public toastr: ToastrService,
    private changeDetection: ChangeDetectorRef) {

     
    // Add Examination From Group
  	this.addExamination = fb.group({
           exam_type : [null, Validators.required],
           summary: [null, Validators.required],
           ex_form_type: [null],
           index_value: [null],
    });

    // Edit Examination From Group
    this.editExamination = fb.group({
           exam_type : [null, Validators.required],
           summary: [null, Validators.required],
           ex_form_type: [null],
           index_value: [null],
    });

  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  // Open Add examination modal
  openModal(template: TemplateRef<any>) {

    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addExamination.reset();
        this.editExamination.reset();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addExamination.reset();
        this.editExamination.reset();
        this.unsubscribe();
      })
    );

    this.modalRef = this.modalService.show(template);
    this.ex_form_type = "new";
  }

  updateData(){
    this.childEvent.emit();
  }

 
  // pushing examination values to  table 
  examinationSelected(val){

    this.examination_descriptions.forEach(item => {
        if(item.id == val){
          this.addExamination.controls['summary'].setValue(item.value);
        }
    });
    let data = this.exam_type;
    for(let i = 0; i < data.length; i++){
      if(data[i].id == val){
        this.selectedexamination = data[i];
      }
    }
    console.log(val);
  }
  public selected_exam_value:any;
  public selected_exam_id:any;

  // Updating examination record function
  updateExaminationRow(Erecord,index,template: TemplateRef<any>){
      console.log(Erecord);
      const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addExamination.reset();
        this.editExamination.reset();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addExamination.reset();
        this.editExamination.reset();
        this.unsubscribe();
      })
    );
      this.modalRef1 = this.modalService.show(template);
      this.selected_exam_value = Erecord.systemName;
      this.selected_exam_id = Erecord.examId;
      this.summary = Erecord.summary;
      this.ex_form_type = "update";
      this.index_value = index; 
      this.examp_master_id = Erecord.examMasterId;
  }

  //Delete Examination Record Function
  deleteExaminationRow(index,Erecord) {
        
        this.apiService.deleteExaminations(this.visitID,Erecord.examId)
              .subscribe(result => {
                this.updateData();
                this.Examinations.splice(index, 1);
                this.toastr.success("Examination deleted successfully");
      }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  //Creating and updating examination record
  submitForm(post) {
    
    
    console.log('post Data::'+JSON.stringify(post));
    if(this.ex_form_type == "new"){
      post = {
          consultation_id: this.visitID,
          examMasterId : post.exam_type,
          summary : post.summary
      }
      
      this.apiService.postExaminations(post,Number(this.visitID))
              .subscribe(result => {
                
                     this.Erecords.push(post); 
                     this.modalRef.hide(); 
                     this.updateData();
                     this.toastr.success("Examination added successfully");
      }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
    }else{
      this.Erecords[post.index_value] = {examMasterId:post.exam_type,exam_type: post.exam_type, summary:post.summary};
      post = {
          consultation_id : this.visitID,
          examMasterId : post.exam_type,
          summary : post.summary,
          examId : this.selected_exam_id
      }
      this.apiService.putExaminations(post,Number(this.visitID),'1')
              .subscribe(result => {
                this.updateData();
                this.toastr.success("Examination updated successfully");
      }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
    }
  }


  submitForm1(post) {
    
    console.log('post Data::'+JSON.stringify(post));
    
      this.Erecords[post.index_value] = {examMasterId:post.exam_type,exam_type: post.exam_type, summary:post.summary};
      post = {
          examId: this.selected_exam_id,
          consultation_id : this.visitID,
          examMasterId : this.examp_master_id,
          summary : post.summary
      }
      this.apiService.putExaminations(post,Number(this.visitID),this.selected_exam_id)
              .subscribe(result => {
                this.updateData();
                this.modalRef1.hide();
                this.toastr.success("Examination updated successfully");
      }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
    
  }



  ngOnInit() {
  	this.apiService.getExaminations().subscribe(result => {            
        this.exam_type = result;
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  ngOnChanges(changes: SimpleChanges) { 

    this.patientID = this.patientId;
    this.visitID = this.visitId;
    this.Examinations = [];
    let examinationwithoutsort = this.examinations;
    if( this.Examinations !== null && this.Examinations !== undefined ) {
      this.Examinations = examinationwithoutsort.sort(function IHaveAName(a, b) { // non-anonymous as you ordered...
        return b.examMasterId < a.examMasterId ?  1 // if b should come earlier, push a to end
            : b.examMasterId > a.examMasterId ? -1 // if b should come later, push a to begin
            : 0;                   // a and b are equal
    });
    }
   

    
    if(this.Examinations == null){
      console.log('test 1');
      console.log(this.Examinations);
    }else{
      console.log('test 2');
      console.log('VisitId' + this.visitID +'Patient ID'+ this.patientID);
      console.log(this.Examinations);
    }

  }

}
