import { Component, ViewChild, OnInit ,NgModule ,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule,DomSanitizer, SafeHtml  } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../_services/api.service';
import { appConfig } from '../../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-initiate-adhoc-visit',
  templateUrl: './initiate-adhoc-visit.component.html',
  styleUrls: ['./initiate-adhoc-visit.component.css']
})
export class InitiateAdhocVisitComponent implements OnInit {

  	createAdhocVisit: FormGroup;

	public patientFirstName : any;
	public patientLastName : any;
	public patientGender : any;
	public patientDOB : any;
	public patientImage : any;

	public paHouseNo : any;
	public paStreetName : any;
	public areas : any;
	public area:any;
	public areaId:any;
	public paCity : any;
	public paPin : any;
	public paState : any;
	public paCountry : any;

	public paPhoneType : any;
	public paPhoneNo : any;

	public paEmail : any;


	public paInFindings : any;
	public diagnosisDesc: any;
	public diagnosisId :any;
	public paSince : any;

	public paRemarks : any;

	public visitDate : any;
	public visitTime : any;
	public serviceCharges : any;
	public includeVisit : any;
	public comments : any;
	public createdUser :any;
	public currentfile:any;

	public hasData:boolean = false;


	paFindings: any[] = [];

	datepickerModel: Date;
	datepickerModel1: Date;

	mytime: Date = new Date();

	validemail : string ='Please enter the valid email.';
  	mobileno : string ='Please enter valid Phone No';
  	titleAlert : string = 'This field is required';

  	 public count : any;
  	 public count1 : any;
  	 public count2 : any;
  	 public count3 : any;


	constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private _sanitizer: DomSanitizer,
    private titleService: Title,
    public toastr: ToastrService){

    	 
    	this.titleService.setTitle( 'Create ADHOC Visit' );

		this.createAdhocVisit = fb.group({

		 patientFirstName: [null, Validators.required],
		 patientLastName: [null, Validators.required],
		 patientGender: [null, Validators.required],
		 patientDOB: [null, Validators.required],
		 patientImage:null,
		 paHouseNo : ['', Validators.required],
		 paStreetName : ['', Validators.required],
		 area : ['', Validators.required],
		 areaId : ['', Validators.required],
		 paCity : ['', Validators.required],
		 paPin :['', [Validators.required,
		  					Validators.minLength(6),
     						Validators.maxLength(6),
      						Validators.pattern('[0-9]+')
    				   ]],
		 paState : ['', Validators.required],
		 paCountry : ['India', Validators.required],
		 paPhoneType : ['', Validators.required],
		 paPhoneNo : ['', [Validators.required,
		  					Validators.minLength(10),
     						Validators.maxLength(10),
      						Validators.pattern('[0-9]+')
    				   ]],
		 paEmail : ['', [Validators.email,Validators.required]],

		 paFindings: this.fb.array([this.createFindings()]),

		 visitDate : ['', Validators.required],
		 visitTime : ['', Validators.required],
		 serviceCharges : [''],
		 includeVisit : [''],
		 comments : ['', Validators.required],
     
    	});
	 	
	}


	createFindings() {
		return this.fb.group({
		  diagnosisDesc : [''],
		  diagnosisId : [''],
		  paSince : [''],
		  paRemarks:[''],
		})
	}

	addFinding() {  
	    this.count = this.count + 1; 
	    const arr = <FormArray>this.createAdhocVisit.get(`paFindings`);
	    arr.push(this.createFindings());    
	}

	removeFinding(index){
	    this.count = this.count - 1; 
	    const arr = <FormArray>this.createAdhocVisit.get(`paFindings`);
	    arr.removeAt(index);
	}

	observableSource = (keyword: any): Observable<any[]> => {
	    let url: string = appConfig.apiUrl + '/consultationSummary/diagnosis/search?pattern='+keyword;
	    let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
	    let options = new RequestOptions({ headers: headers });
	    if (keyword) {     
	      return this.http.get(url,options)
	        .map((res: Response) => res.json())
	    } else {
	      return Observable.of([]);
	    }
	  }

	 diagnosisSelected(val,i){
	    console.log(val);
		const control = this.createAdhocVisit.get(`paFindings.${i}`)['controls'];
	  	control.diagnosisDesc.setValue(val.diagnosisDesc);
	  	control.diagnosisId.setValue(val.id);
	  }


	submitForm(value) {
		let post = JSON.parse(JSON.stringify(value));
		
		post.patientDOB = this.datePipe.transform(post.patientDOB, 'dd-MM-yyyy');
		post.visitDate = this.datePipe.transform(post.visitDate, 'dd-MM-yyyy');
		post.visitTime = this.datePipe.transform(post.visitTime, 'HH:mm');
		let j = post.paFindings.length;
		for (let i = 0; i < j; i++) {
			debugger;
			if( post.paFindings[i].paSince != "") {
				post.paFindings[i].paSince = this.datePipe.transform(post.paFindings[i].paSince, 'dd-MM-yyyy');
			} else {
				let day = new Date();
				post.paFindings[i].paSince = this.datePipe.transform(day, 'dd-MM-yyyy');
			}
		  let firstArray = { 
				 diagnosis_master_id : post.paFindings[i].diagnosisId,
				 disease_since : post.paFindings[i].paSince,
				 predated : "true",
				 disease : post.paFindings[i].diagnosisDesc,
				 remarks : post.paFindings[i].paRemarks
		    };
		  	post.paFindings[i] = firstArray;
		}

		let payment = "PAID_IN_CASH";
		if( post.includeVisit == true ) {
			payment = "INCLUDE_IN_PKG";
		}
		
		post = {
					
					patient :
					{
						clientStatus :  "ADHOC",
						personalDetails :
						{
							 firstName :  post.patientFirstName,
							 lastName :  post.patientLastName,
							 gender :  post.patientGender,
							 dob :  post.patientDOB,
							 addresses : [
								{
									plotFlatNo : post.paHouseNo,
									streetColony : post.paStreetName,
									areaId : post.areaId,
									currentAddress : true,
									addrType : "RESIDENCE"
								}
							],
							contacts : [
								{
									type : "PHONE",
									value : post.paPhoneNo,
									primary_contact : true
								},
								{
									 type : "EMAIL",
									 value : post.paEmail,
									 primary_contact : false
								}
							]
						},
						diseaseProfile : post.paFindings,
						drInitialComments:post.comments,
					},
					schedule:
					{
						date : post.visitDate,
						time : post.visitTime
					},
					service_charges: post.serviceCharges,
					paymentStatus: payment,
		    }
		    console.log('post Data::'+JSON.stringify(post));

		    this.apiService.initiateAdhocVisit(post)
            .subscribe(result => {
                
                     console.log(result);
                     this.toastr.success('Adhoc Visit Created Successfully', 'Congrats!');
					 this.createdUser = result;
					 if(this.currentfile != '') {
						this.uploadImage(this.createdUser);
					}
		             this.createAdhocVisit.reset();
               
			    }, error => {
	                let edata;
	                if(error.apierror){ edata = error.apierror
	                } else { edata = error }
	                this.toastr.error(edata.message, edata.status);
	              }),
	              err   => {
	                this.toastr.error('500 Internal Server Error!');           
	            };

		    
	}

    
	uploadImage(userid){
		let image = this.currentfile.item(0);
	    this.apiService.postPatientMedia(image,Number(userid.patient.personalDetails.id))
           .subscribe(result => {
               
                    console.log(result);
                    this.toastr.success('upload profile image Successfully', 'Congrats!');
               // this.Message = "Specialist Created Successfully"
              
	    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        }; 
	}

	
	onFileChange(event) {
	    this.currentfile =  event.target.files;
	    console.log(this.currentfile);
	    console.log(this.currentfile.item(0));
	    
	}

    loadAreas() {	
        this.apiService.getAreas().subscribe(areas => { 
          this.areas = areas;
          this.areas = this.areas.areas;
          console.log(this.areas);
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  	}
  	areaSelected(val,i){

		console.log(val);
		this.createAdhocVisit.controls['area'].setValue(val.area);
		this.createAdhocVisit.controls['paCity'].setValue(val.cityDistrict);
		this.createAdhocVisit.controls['paPin'].setValue(val.pin);
		this.createAdhocVisit.controls['paState'].setValue(val.state);
		this.createAdhocVisit.controls['areaId'].setValue(val.id);
		
	}

 

  	autocompleListFormatter = (data: any) : SafeHtml => {
	    let html = `<span>${data.area}</span>`;
	    return this._sanitizer.bypassSecurityTrustHtml(html);
	}


	
	
	ngOnInit() {
		this.loadAreas();
	}

}
