import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User,logins } from '../_models/index';
import { UserService } from '../_services/index';
import { AuthenticationService } from '../_services/index';

@Component({
  selector: 'app-main',
  moduleId: module.id,
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

   currentUser: logins;
   bodyClasses = 'skin-blue sidebar-mini';
   body: HTMLBodyElement = document.getElementsByTagName('body')[0];

    constructor(private userService: UserService,
        private router: Router,
        private authenticationService: AuthenticationService) {
        this.router = router;
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if(this.currentUser){
          if(this.router.url == "/"){
            if(this.currentUser.userrole == "ROLE_CONSULTATION_DOCTOR"){
                this.router.navigate(['/doctor-dashboard']);
            }else if(this.currentUser.userrole == "ROLE_OPS_ADMIN"){
                this.router.navigate(['/operator-dashboard']);
            }
          }
        }else{
          this.router.navigate(['/login']);
        }
        
    }

   ngOnInit() {
    // add the the body classes
    this.body.classList.add('skin-green');
    this.body.classList.add('sidebar-mini');
    this.body.classList.add('sidebar-collapse');

    
  }

   ngOnDestroy() {
    // remove the the body classes
    this.body.classList.remove('skin-green');
    this.body.classList.remove('sidebar-mini');
    this.body.classList.add('sidebar-collapse');
  }

}
