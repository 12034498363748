import { Component, ViewChild, OnInit ,NgModule ,  ViewContainerRef,TemplateRef ,Renderer, ElementRef }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule,DomSanitizer, SafeHtml  } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var $ :any;

@Component({
  selector: 'app-partner-from',
  templateUrl: './partner-from.component.html',
  styleUrls: ['./partner-from.component.css']
})
export class PartnerFromComponent implements OnInit {

  @ViewChild(ModalDirective) modal: ModalDirective;

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  @ViewChild('successbtn') successbtn:ElementRef;



  modalRef: BsModalRef;

  createPatner: FormGroup;

  public patnerName : any;
	public phoneNo : any;
	public email : any;
	public addressName : any;
	public houseFlat : any;
  public streetColony : any;
  public area : any;
  public areaId : any;
  public pin : any;
  public areas : any;

  CPcontacts: any[] = [];

  public CPName : any;
  public CPPhoneNo : any;
  public CPEmail : any;

  validemail : string ='Please enter the valid email.';
  mobileno : string ='Please enter valid Phone No';
  titleAlert : string = 'This field is required';

  public count  : any;



  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private _sanitizer: DomSanitizer,
    private titleService: Title,
    public toastr: ToastrService) {

     

    this.titleService.setTitle( 'Create Partner' );

    this.createPatner = fb.group({

     patnerName: new FormControl(null, Validators.required),
     phoneNo: new FormControl('',[Validators.required,
                Validators.minLength(9),
                 Validators.maxLength(10),
                  Validators.pattern('[0-9]+')]),
     email: new FormControl(null, Validators.email),
     addressName: new FormControl(null, Validators.required),
     houseFlat:new FormControl(null, Validators.required),
     streetColony:new FormControl(null, Validators.required),
     area:new FormControl(null, Validators.required),
     areaId : new FormControl(null),
     pin:new FormControl(null, Validators.required),
       CPcontacts: this.fb.array([this.createContact()]),
     });

  }

  openModal(template: TemplateRef<any>) {
      this.modalRef = this.modalService.show(template);
  }

  createContact() {
    return this.fb.group({
      CPName : new FormControl('', Validators.required),
      CPPhoneNo :new FormControl('',[Validators.required,
                Validators.minLength(9),
                 Validators.maxLength(10),
                  Validators.pattern('[0-9]+')]),
      CPEmail : new FormControl('', Validators.email),
      
    })
  }

  addNewContact() {  
      this.count = this.count + 1; 
      const arr = <FormArray>this.createPatner.get(`CPcontacts`);
      arr.push(this.createContact());    
  }

  removeNewContact(index){
      this.count = this.count - 1; 
      const arr = <FormArray>this.createPatner.get(`CPcontacts`);
      arr.removeAt(index);
  }

  loadAreas() {  
        this.apiService.getAreas().subscribe(areas => { 
          this.areas = areas;
          this.areas = this.areas.areas;
          console.log(this.areas);
        });
  }

 

  autocompleListFormatter = (data: any) : SafeHtml => {
      let html = `<span>${data.area}</span>`;
      return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  areaSelected(val,i){

    console.log(val);
   this.createPatner.controls['area'].setValue(val.area);
   this.createPatner.controls['areaId'].setValue(val.id);
   this.createPatner.controls['pin'].setValue(val.pin);
  }

  ngOnInit() {
    this.loadAreas();
  }

  submitForm(post) {
  	

    let j = post.CPcontacts.length;
  
    for (let i = 0; i < j; i++) {

        let firstArray = {
          contactName : post.CPcontacts[i].CPName, 
          contactNo : post.CPcontacts[i].CPPhoneNo,
          contactEmail : post.CPcontacts[i].CPEmail,
        };
        post.CPcontacts[i] = firstArray;

     }

     post =  {
              partnerName : post.patnerName,
              partnerType : "DIAGNOSTICS",
              serviceAtHome : "false",
              partnerStatus : "ACTIVE",
              partnerAddress :
                    {
                      addressName : post.addressName,
                      plotFlatNo : post.houseFlat,
                      streetColony : post.streetColony,
                      areaId : post.areaId,
                      addrType : "CLINIC"
                    },
                    phoneNumber : post.phoneNo,
                    email : post.email,
                    contacts :post.CPcontacts
        }

        this.apiService.postPatners(post)
                .subscribe(result => {
                    if (result != null) {
                         console.log(result);
                         this.createPatner.reset();
                         this.successbtn.nativeElement.click()
                       // this.Message = "Specialist Created Successfully"
                    } else {
                    
                    }
       }, error => {

            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };

        
        console.log('post Data::'+JSON.stringify(post));
    }

}
