import { Component,ChangeDetectorRef, ViewChild, OnInit ,EventEmitter,Input,Output,NgModule ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';   
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable, combineLatest, Subscription } from 'rxjs';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

declare var $ :any; 


@Component({
  selector: 'app-action-item-widget',
  templateUrl: './action-item-widget.component.html',
  styleUrls: ['./action-item-widget.component.css']
})
export class ActionItemWidgetComponent implements OnInit,OnChanges {

  @ViewChild(ModalDirective) modal: ModalDirective;
  @Output() childEvent = new EventEmitter();

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  @Input() public patientId:any;
  @Input() public visitId: any;
  @Input() public notes: any; 
  @Input() public addnew: any;
  @Input() public doctorId: any;
  @Input() public opsID: any;
  
  public patientID:any;
  public visitID : any;
  public Notes : any;
  public DoctorId: any;
  public OpsId: any;
  subscriptions: Subscription[] = [];
  public maxDate:any  = new Date();

  modalRef: BsModalRef;

  addTask:FormGroup;

  public task: any;
  public asignTo : any;
  public endDate: any;

  public formtype : any;
  public currentItem : any;
  public indexValue : any;


  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,public toastr: ToastrService,
    private changeDetection: ChangeDetectorRef) {
     
    // Task Form controller
  	this.addTask = fb.group({
           task : new FormControl(null, Validators.required),
           asignTo : new FormControl(null, Validators.required),
           endDate : new FormControl(null, Validators.required)
    });

    

  }

  updateData(){
    this.childEvent.emit();
  }

  openModal(template: TemplateRef<any>) {

    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addTask.reset();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addTask.reset();
        this.unsubscribe();
      })
    );
 
    this.subscriptions.push(_combine);

    this.modalRef = this.modalService.show(template);

    this.formtype = "New";
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  //Edit Task
  editTask(template: TemplateRef<any>,index,val){

    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addTask.reset();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addTask.reset();
        this.unsubscribe();
      })
    );
    
  	this.modalRef = this.modalService.show(template);
  	this.formtype = "Edit";
  	this.currentItem = val.id;
  	this.indexValue = index;

  	let enddate =  val.endDate.split("-");
    enddate = new Date(Number(enddate[2]) ,Number(enddate[1])-1, Number(enddate[0]));

  	this.addTask.controls['task'].setValue(val.notes);
  	this.addTask.controls['asignTo'].setValue(val.assignedTo);
  	this.addTask.controls['endDate'].setValue(enddate);
  }


  //Update Task
  submitForm(post){
    this.modalRef.hide();
    let data = new Date();

    let todaysDate = this.datePipe.transform(data, 'dd-MM-yyyy');
    post.endDate = this.datePipe.transform(post.endDate, 'dd-MM-yyyy');
    if(this.formtype == "New"){
      let UserId;
      if( post.asignTo == "DOCTOR" ) {
        UserId = this.DoctorId;
      } else if( post.asignTo == "PATIENT" ) {
        UserId = this.patientID;
      } else if( post.asignTo == "OPS" ) {
        UserId = this.OpsId;
      }
	    post = {
			type : "ACTION_ITEM",
			assignedTo : post.asignTo,
			startDate : todaysDate,
			endDate : post.endDate,
			notes : post.task,
			consultation_id : this.visitID,
      status: "OPEN",
      assigneeId: UserId
		}
	    this.apiService.postTasks(post,Number(this.visitID))
	            .subscribe(result => {
	                
	                     console.log(result);
	                     this.Notes.push(result);
                       this.addTask.reset();
	                     this.toastr.success('Action Item added successfully'); 
	    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
    } else{

    	post = {
    		id : this.currentItem,
			type : "ACTION_ITEM",
			assignedTo : post.asignTo,
			startDate : todaysDate,
			endDate : post.endDate,
			notes : post.task,
			consultation_id : this.visitID,
      status : "OPEN",
		}
	    this.apiService.putTasks(post,Number(this.visitID),Number(this.currentItem))
	            .subscribe(result => {
	               
	                     console.log(result);
	                     this.Notes[this.indexValue] = post;
                       this.addTask.reset();
                       this.toastr.success('Action Item updated successfully');
	               
	    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };

    }


     console.log('post Data::'+JSON.stringify(post)); 
  }


  // Delete Task function
  deleteTask(index,id){
  	this.apiService.deleteTasks(Number(this.visitID),Number(id)).subscribe(result => {            
               
                     this.Notes.splice(index, 1);
                     this.toastr.success('Action Item Deleted successfully');
                
    }, error => {
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
    }),
      err   => {
        this.toastr.error('500 Internal Server Error!');           
    };
  }

  completeTask(data) {
    
    this.apiService.completeTasks(data.id).subscribe(result => {            
          this.toastr.success('Action Item completed successfully');
          this.updateData();
    }, error => {
    let edata;
    if(error.apierror){ edata = error.apierror
    } else { edata = error }
    this.toastr.error(edata.message, edata.status);
    }),
    err   => {
    this.toastr.error('500 Internal Server Error!');           
    };
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) { 

    this.patientID = this.patientId;
    this.visitID = this.visitId;
    this.Notes = this.notes;
    this.DoctorId = this.doctorId;
    this.OpsId = this.opsID;

  }

}
