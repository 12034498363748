import { Component, ViewChild, OnInit ,NgModule ,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule,DomSanitizer, SafeHtml  } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../../_services/api.service';
import { appConfig } from '../../../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var $ :any;

@Component({
  selector: 'app-patient-specialists',
  templateUrl: './patient-specialists.component.html',
  styleUrls: ['./patient-specialists.component.css']
})
export class PatientSpecialistsComponent implements OnInit {
  
  associateSP  : FormGroup;
  public user :any;

  public specializations:any[];
   public unspecializations = [];
  public specialists:any;
  public associatedSpecialists:any;

  public specialization:any;
  public specialist:any
  public frequency:any



  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private _sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private titleService: Title,
    public toastr: ToastrService) {

    this.titleService.setTitle( 'Specialist List' );
     

  	this.associateSP = fb.group({
		 specialization : [null,Validators.required],
		 specialist: [null, Validators.required],
		 frequency: [null, Validators.required]
    });
  }


  submitForm(post) {
		

    	post = {
    		clientId : this.user.client_id,
			  specialistId : post.specialist,
        visitFreq : post.frequency
		  }
		console.log('post Data::'+JSON.stringify(post));

		this.apiService.postAssociateSpecialist(post,Number(this.user.client_id),Number(post.specialistId))
	            .subscribe(result => {
	                
	                     console.log(result);
                       this.loadAssociateSpecializations(Number(this.user.client_id));
	                     this.associateSP.reset();
                       this.toastr.success('Specialist Associated successfully', 'Successful!');
	               
	    }, error => {
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };  
		
	}

  loadSpecializations() {
        this.apiService.getSpecializations('SPECIALIST').subscribe(specializations => { 
          this.specializations = specializations;
          console.log(this.specializations[0]);
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }
  loadSpecialists() {
        let specilzations = [];
        this.apiService.getSpecialists().subscribe(specialists => { 
          this.specialists = specialists;
          
          for(let i = 0; i<this.specialists.length; i++){
            specilzations.push(this.specialists[i].specialization);
          }


    // Unique currencies
    this.unspecializations = specilzations.filter((x, i, a) => x && a.indexOf(x) === i);

          console.log(this.unspecializations);

         // let currencies = this.specialists.filter((x, i, a) => x && a.indexOf(x) === i);
          console.log(this.specialists.length);
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            if ( edata.status != 'NOT_FOUND') {
              this.toastr.error(edata.message, edata.status);
            }
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }
  public filteredSpeclist:any;
  specializationSelected(val){
    this.filteredSpeclist = [];
    console.log(val);
    let data = this.specialists;
    for(let i=0; i < data.length; i++){
          if(data[i].specialization == val){
            this.filteredSpeclist.push(data[i]);
          }
      }
  }
  loadAssociateSpecializations(id) {
        this.apiService.getAssociateSpecialist(id).subscribe(associatedSpecialists => { 
          this.associatedSpecialists = associatedSpecialists;
          this.associatedSpecialists = this.associatedSpecialists.assocSpecialists
          console.log('associated');
          console.log(this.associatedSpecialists);
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }
  getCurrentPatients(id) {
        this.apiService.getCurrentPatients(id).subscribe(user => { 
          this.user = user;
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }


  disassociate(id){
      this.apiService.deleteAssociation(Number(this.user.client_id),Number(id))
              .subscribe(result => {
          
               console.log(result);
               this.associatedSpecialists = [];
               this.loadAssociateSpecializations(Number(this.user.client_id));
               this.toastr.success('Specialist Deassociated successfully', 'Successful!');
           
      }, error => {
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      }; 
  }





  ngOnInit() {
  	this.user = this.route.snapshot.params['id'];
    console.log(this.user);
    this.getCurrentPatients(this.user);
  	this.loadSpecializations();
  	this.loadSpecialists();
    this.loadAssociateSpecializations(this.user);
  
  }

}
