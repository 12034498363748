﻿export const appConfig = {
   //apiUrl: 'http://183.82.97.88:7777'
   //apiUrl: 'http://183.82.97.88:9999'
   //apiUrl: 'http://192.168.0.17:9999'
    //apiUrl: 'http://192.168.0.17:8888'
  //apiUrl: 'http://18.197.65.151:8080'
   //apiUrl: 'http://18.197.65.151:8080'
    //apiUrl: 'http://192.168.0.17:9999'
    //apiUrl: 'http://192.168.0.25:8080'

    //testin URL
    //apiUrl: 'http://183.82.121.77:9999' 
    
    //live URL
    //apiUrl: 'http://18.197.65.151:8080'
    //apiUrl: 'https://phcspl.com:8443'

    //live,piersoft
    apiUrl: 'https://phcspl.com:8443/peritus_home_care_service-1.0'
    //apiUrl: 'http://123.176.39.229:8989/homeservice-1.0'
};
