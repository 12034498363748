import { Component, ViewChild, OnInit ,NgModule ,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule ,DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../../_services/api.service';
import { appConfig } from '../../../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

declare var $ :any;


@Component({
  selector: 'app-specialist-form-edit',
  templateUrl: './specialist-form-edit.component.html',
  styleUrls: ['./specialist-form-edit.component.css']
})
export class SpecialistFormEditComponent implements OnInit {


  createSpecialist: FormGroup;

    public specialistName : any;
    public specilization : any;
	public personalNo : any;
	public personalEmail : any;

	public addressName : any;
	public addressType : any;
	public clinicAddress : any;
	public city : any;
	public pin : any;
	public state : any;
	public country : any;

	
	public secretaryName : any;
	public secretaryPhoneNo : any;

	public Message : any;

	public areas:any;
	public specializations:any;


	successModal: BsModalRef;

	Addresses: any[] = [];

	validemail : string ='Please enter the valid email.';
  	mobileno : string ='Please enter valid Phone No';
  	titleAlert : string = 'This field is required';

  	 public count : any;

  	 constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private _sanitizer: DomSanitizer,
    private titleService: Title,
    public toastr: ToastrService){

    	this.titleService.setTitle( 'Create New Specilist' );
    	 

		this.createSpecialist = fb.group({
		 specialistName: [null, Validators.required],
		 specilization: [null, Validators.required],
		 personalNo: [null, [Validators.required,
		  					Validators.minLength(9),
     						Validators.maxLength(10),
      						Validators.pattern('[0-9]+')]],
		 personalEmail: [null, Validators.required],
		 Addresses: this.fb.array([this.createAddress()]),
     
    	});
	 	
	}

	openModal(template) {
	    this.successModal = this.modalService.show(template);
	}


	createAddress() {
		return this.fb.group({
			
		  addressName : ['', Validators.required],
		  addressType : ['', Validators.required],
		  paHouseNo : new FormControl('', Validators.required),
		  plotcColony : new FormControl('', Validators.required),
		  area : new FormControl('', Validators.required),
		  areaId : new FormControl(''),
		  paCity : new FormControl('', Validators.required),
		  paPin :new FormControl('',[Validators.required,
		  					Validators.minLength(6),
     						Validators.maxLength(6),
      						Validators.pattern('[0-9]+')]),
		  paState : new FormControl('', Validators.required),
		  paCountry : new FormControl('India', Validators.required),
		  
		  secretaryName : ['', Validators.required],
		  secretaryPhoneNo : ['', [Validators.required,
		  					Validators.minLength(9),
     						Validators.maxLength(10),
      						Validators.pattern('[0-9]+')
    				   ]]
		})
	}





	addNewAddress() {  
	    this.count = this.count + 1; 
	    const arr = <FormArray>this.createSpecialist.get(`Addresses`);
	    arr.push(this.createAddress());    
	}

	removeNewAddress(index){
	    this.count = this.count - 1; 
	    const arr = <FormArray>this.createSpecialist.get(`Addresses`);
	    arr.removeAt(index);
	}

	

	getspecilistdata() {
	        this.apiService.getSpecialist('SPECIALIST').subscribe(specilist => { 
	          
	          console.log(specilist);
	        });
	}

	loadSpecializations() {
	        this.apiService.getSpecializations('SPECIALIST').subscribe(specializations => { 
	          this.specializations = specializations;

	          console.log(this.specializations[0]);
	        });
	}

	submitForm(post) {
		let j = post.Addresses.length;
		let adressaray = post.Addressess
		for (let i = 0; i < j; i++) {
		  let firstArray = {
		  	addressName : post.Addresses[i].addressName, 
		  	plotFlatNo : post.Addresses[i].paHouseNo,
		  	streetColony : post.Addresses[i].plotcColony,
		  	areaId : post.Addresses[i].areaId,
			addrType : post.Addresses[i].addressType,
		    currentAddress: true,};
		  let secondArray = { 
		  	contactNo : post.Addresses[i].secretaryPhoneNo,
		  	contactEmail :'',
		  	contactName : post.Addresses[i].secretaryName 
		  };
		  post.Addresses[i] = { "address" :firstArray ,"contactDetails" :secondArray };
		}
		post = {
			specialistName : post.specialistName,
			specialityId : post.specilization,
			personalEmail : post.personalEmail,
			personalPhone : post.personalNo,
			category : "SPECIALIST",
			workingLocations:post.Addresses }
		console.log('post Data::'+JSON.stringify(post));

		this.apiService.postSpecialist(post)
	            .subscribe(result => {
	                if (result != null) {
	                     console.log(result);
	                    this.createSpecialist.reset();
	                    this.toastr.success('Sponsor created successfully', 'Successful!');
		          } else {
		             this.toastr.error('Some thing went wrong', 'Sorry!');
		          }
		    }, error => {
		      console.log(error);
		      this.toastr.error(error);
		    }),
		    err   => {
		      this.toastr.error('500 Internal Server Error!');           
		};  

	}

	loadAreas() {	
        this.apiService.getAreas().subscribe(areas => { 
          this.areas = areas;
          this.areas = this.areas.areas;
          console.log(this.areas);
        });
  	}

 

  	autocompleListFormatter = (data: any) : SafeHtml => {
	    let html = `<span>${data.area}</span>`;
	    return this._sanitizer.bypassSecurityTrustHtml(html);
	}

	areaSelected(val,i){

		console.log(val);
		const control = this.createSpecialist.get(`Addresses.${i}`)['controls'];
		control.area.setValue(val.area);
		control.areaId.setValue(val.id);
		control.paCity.setValue(val.cityDistrict);
		control.paPin.setValue(val.pin);
		control.paState.setValue(val.state);
	}


  	ngOnInit() {
  		this.loadAreas();
  		this.loadSpecializations();
  		this.getspecilistdata();
  		 //$('#successModal').modal('show')
	}
}
