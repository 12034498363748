import { Component, ViewChild,ChangeDetectorRef,Injectable, OnInit ,EventEmitter,Input,Output,NgModule ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable, combineLatest, Subscription } from 'rxjs';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

declare var $ :any;
@Component({
  selector: 'app-diagnosis-widget',
  templateUrl: './diagnosis-widget.component.html',
  styleUrls: ['./diagnosis-widget.component.css']
})
export class DiagnosisWidgetComponent implements OnInit,OnChanges {
  @ViewChild(ModalDirective) modal: ModalDirective;
  @Output() childEvent = new EventEmitter();
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  @Input() public patientId:any;
  @Input() public visitId: any;
  @Input() public diagnosisList: any; 
  @Input() public addnew: any;
  @Input() public visitDate: any;
  

  public patientID:any;
  public visitID : any;
  public DiagnosisList : any;
  public VisitDate : any;
  public maxDate: Date;
  subscriptions: Subscription[] = [];



  name:string;

  showHide:boolean = false;

  modalRef: BsModalRef;

  addDiagnosis: FormGroup;

  public diagnosis_master_id:any;
  public diagnosisDesc: any;
  public icdCode: any;
  public remarks: any;
  public selected :any;
  

  

  records = [
  {id:'1',diagnosisDesc: 'Diagnosis Name 01', icdCode:'1256'},
  {id:'2',diagnosisDesc: 'Diagnosis Name 02', icdCode:'6898'},
  {id:'29',diagnosisDesc: 'Ameboma of intestine', icdCode:'A063'}
  ];

  historyRecords = [];


  addDiagnosisHistory: FormGroup;
  datepickerModel2: Date;

  public record_date1:any;
  public record_date: any;
  public result:any;
  public impact:any;
  public details:any;
  public diagnosisid : any;
  public diagnosisMasterId : any;
  public isChronic : any;
  public condition

  
  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    public toastr: ToastrService,
    private changeDetection: ChangeDetectorRef) { 
      this.maxDate = new Date();

      // Add Diagnosis form controls
     this.addDiagnosis = fb.group({
           diagnosis_master_id : new FormControl(null),
           diagnosisDesc: new FormControl(null, Validators.required),
           icdCode: new FormControl(null),
           chronicle: new FormControl(null),
           remarks: new FormControl(null),
     });

     // Add Diagnosis History form controls
     this.addDiagnosisHistory = fb.group({
           diagnosisid: new FormControl(null),  
           diagnosisMasterId : new FormControl(null), 
           record_date: new FormControl(null, Validators.required),
           result: new FormControl(null),
           impact: new FormControl(null),
           details: new FormControl(null, Validators.required),
     });

     this.dpConfig.containerClass = 'theme-blue';
     this.dpConfig.dateInputFormat  = 'DD/MM/YYYY'; 

  }

  testing(value){
    alert(value);
  }
  updateData(){
    this.childEvent.emit();
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  
  // Add Diagnosis Modal
  openModal(template: TemplateRef<any>) {
    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addDiagnosis.reset();
        this.isChronic = false;
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addDiagnosis.reset();
        this.isChronic = false;
        this.unsubscribe();
      })
    );
 
    this.subscriptions.push(_combine);

    this.modalRef = this.modalService.show(template);
  }

  addHistory(){
  	this.showHide = !this.showHide;
    console.log(this.showHide);
  }

  // Diagnosis Search service
  observableSource = (keyword: any): Observable<any[]> => {
    let url: string = appConfig.apiUrl + '/consultationSummary/diagnosis/search?pattern='+keyword;
    let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
    let options = new RequestOptions({ headers: headers });
    if (keyword) {     
      return this.http.get(url,options)
        .map((res: Response) => res.json())
    } else {
      return Observable.of([]);
    }
  }

  //Diagnosis value selections
  diagnosisSelected(val){
    this.selected = val.diagnosisDesc;
    this.icdCode = val.icdCode;
    this.diagnosis_master_id = val.id;
    this.isChronic = val.isChronic;
    console.log(val);
  }
  

  // Delete Diagnosis Record
  deleteDiagnosisRow(index,id) {

      this.apiService.deleteDiagnosis(Number(this.visitID),Number(id)).subscribe(result => {            
  
                 this.toastr.success('Diagnosis deleted Successfully', 'Successfull');
                     this.DiagnosisList.splice(index, 1);
               
          }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
      
  }
  


  // Open Diagnosis Modal
  openDiagnosisHistory(template: TemplateRef<any>,num: number,masterid:number){
    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addDiagnosisHistory.reset();
        this.showHide = false;
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addDiagnosisHistory.reset();
        this.showHide = false;
        this.unsubscribe();
      })
    );
 
    this.subscriptions.push(_combine);

     this.modalRef = this.modalService.show(template);
     this.diagnosisid = num;
     this.diagnosisMasterId = masterid;
     this.historyRecords =[];
     this.apiService.getDiagnosisHistory(Number(this.visitID),Number(num)).subscribe(result => {            
          
          this.historyRecords = result;
     }, error => {
            let edata;
            if(error.apierror){ 
              edata = error.apierror
            } else { edata = error }
            if(edata.status != "NOT_FOUND") {
              this.toastr.error(edata.message, edata.status);
            }
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }


  // Creating  Dignosis Record
  submitForm(post) {
    
    
    post =  { 
         diagnosis_master_id : post.diagnosis_master_id,
         disease : post.diagnosisDesc,
         disease_since : this.VisitDate,
         icd_code: post.icdCode,
         consultation_id : this.visitID,
         client_id : this.patientID,
         remarks : post.remarks,
         is_chronic: post.chronicle
    };
    console.log('post Data::'+JSON.stringify(post));
    this.apiService.postDiagnosis(post,Number(this.visitID))
            .subscribe(result => {
                
                     this.toastr.success('Diagnosis added Successfully', 'Successfull');
                     this.updateData();
                     this.modalRef.hide(); 
                
          }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
      
  }

  // Creating Diagnosis History Record
  submitForm1(post) {

     post.record_date = this.datePipe.transform(post.record_date, 'dd-MM-yyyy');
     console.log(post.diagnosisid);
     post = {
        client_id : this.patientID,
        diagnosis_id : post.diagnosisid,
        diagnosis_master_id : post.diagnosisMasterId,
        record_date : post.record_date,
        result : post.result,
        impact : post.impact,
        details : post.details
     };
     
     this.apiService.addDiagnosisHistory(post,Number(this.visitID),Number(post.diagnosis_id))
            .subscribe(result => {
                
                   this.toastr.success('History added Successfully');
                   this.historyRecords.push(post); 
                   this.addDiagnosisHistory.controls['record_date'].setValue('');
                   this.addDiagnosisHistory.controls['result'].setValue('');
                   this.addDiagnosisHistory.controls['impact'].setValue('');
                   this.addDiagnosisHistory.controls['details'].setValue('');
                   this.updateData();

          }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
   
    console.log('post Data::'+JSON.stringify(post));

    
  }

 




  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) { 

    this.patientID = this.patientId;
    this.visitID = this.visitId;
    this.DiagnosisList = this.diagnosisList;
    this.VisitDate = this.visitDate;
    if( this.DiagnosisList !== null && this.DiagnosisList !== undefined ) {
      this.DiagnosisList.reverse();
    }

    if(this.VisitDate) {
      console.log("Visit Date");
      console.log(this.VisitDate);
    }   

  }

}
