import { Component, ViewChild, OnInit ,EventEmitter,Input,Output,NgModule ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $ :any;

@Component({
  selector: 'app-consultant-visits-widget',
  templateUrl: './consultant-visits-widget.component.html',
  styleUrls: ['./consultant-visits-widget.component.css']
})
export class ConsultantVisitsWidgetComponent implements OnInit,OnChanges {

  @ViewChild(ModalDirective) modal: ModalDirective;
  @Output() childEvent = new EventEmitter();
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  @Input() public patientId:any;
  @Input() public visitId: any;
  @Input() public recommendations: any; 
  @Input() public addnew: any;
  

  public patientID:any;
  public visitID : any;
  public Recommendations : any;
  public maxDate:any  = new Date();

  public editpost: boolean;


  modalRef: BsModalRef;

  addAHSVisit: FormGroup;

  public ashSpecilists: any;

  public startDate: Date;
  public specilization:any;
  public consultantName: any;
  public drComments:any;
  public duration:any;
  public consultations:any;

  public specialistslist:any;
  public currentspecialization:any;
  public associatedSpecialists:any;
  public specializations:any;
  public consultationlist:any;

  public ahsRecomendations : any;
  public currentRecomendation : any;
  public indexval : any;

  public formStatus : boolean;



  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,public toastr: ToastrService) {
     
  	this.addAHSVisit = fb.group({
       specilization : [null, Validators.required],
       // consultantName: [null, Validators.required],
       drComments: [null, Validators.required],
       duration: [null, Validators.required],
      
       startDate: [null, Validators.required],
    });

  }

  updateData(){
    this.childEvent.emit();
  }


  // open AHS Partner Visits Modal
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.formStatus = true;
    this.loadSpecializations();
    this.loadConsultations();
    this.addAHSVisit.reset();
    this.addAHSVisit.controls['specilization'].enable();
  }

  //edit AHS Partner Visits function
  editRecomendation(template: TemplateRef<any>,index,val){
    this.loadSpecializations();
    this.loadConsultations();
    this.addAHSVisit.reset();
    this.modalRef = this.modalService.show(template);
    this.formStatus = false;
    this.indexval = index;
    this.currentRecomendation = val.id;
    this.currentspecialization = val.specializationId;
    // this.specilizationSelected(val.specialist.specialization);

    console.log(val);
    let visits = '';
    if(val.visits != null){
      let v = val.visits;
      v = v.slice(1, -1);
      v = v.replace(/\s/g,'');
      visits = v.split(",");
      
    }

    let startdate =  val.schedule.date.split("-");
    startdate = new Date(Number(startdate[2]) ,Number(startdate[1])-1, Number(startdate[0]));


    //this.addAHSVisit.controls['specilization'].disable();
    this.addAHSVisit.controls['specilization'].setValue(val.specializationId);
    //this.addAHSVisit.controls['consultantName'].disable();
    //this.addAHSVisit.controls['consultantName'].setValue(val.specialist.id);
    this.addAHSVisit.controls['drComments'].setValue(val.drComments);
    this.addAHSVisit.controls['duration'].setValue(val.consultation_duration);
    this.addAHSVisit.controls['startDate'].setValue(startdate);
   // this.addAHSVisit.controls['number'].setValue(duration);
    // this.addAHSVisit.controls['consultations'].setValue(visits);
  }

  // Submit AHS Partner Visits 
  submitForm(post) {
    if(this.formStatus == true){

      post = {
  			specialization_id : post.specilization,
  			consultation_id : this.visitID,
  			visit_type : "CONSULTANT_VISIT",
  			dr_comments : post.drComments,
  			dr_accompany_patient : "true",
  			// consultations : consultation_list,
        include_vitals :"false",
        consultant_treat_duration : post.duration,
        status : "OPEN"
  	  };

  	  this.apiService.postRecomendation(post,Number(this.visitID))
              .subscribe(result => {
              this.modalRef.hide();
               console.log(result);
               this.Recommendations.push(post);
               this.updateData();
               this.toastr.success('AHS Partner Visits added successfully');
         
      }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };

    }else{
      // alert(post.consultantName);

      post = {
        specialization_id : this.currentspecialization,
        consultation_id : this.visitID,
        spec_cons_id : this.currentRecomendation,
        visit_type : "CONSULTANT_VISIT",
        dr_comments : post.drComments,
        dr_accompany_patient : "true",
        // consultations : consultation_list,
        include_vitals :"false",
        consultant_treat_duration : post.duration,
        status : "OPEN"
      };

     

      this.apiService.putRecomendation(post,Number(this.visitID),Number(this.currentRecomendation))
              .subscribe(result => {
         
               console.log(result);
               this.modalRef.hide();
               this.Recommendations[this.indexval] = post;
               this.updateData();
               this.toastr.success('AHS Partner Visits updated successfully');
         
      }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };

    }

    console.log('post Data::'+JSON.stringify(post));
	
  }



 

  loadSpecializations() {
    this.apiService.getSpecializations('AHSPARTNER').subscribe(specializations => { 
      console.log("consulatations specializations")
      console.log(specializations);
      this.specializations = specializations;
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  // Load consultation function
  loadConsultations() {
    this.apiService.getPatientConsultationSummary(Number(this.patientId)).subscribe(consultationlist => { 
      this.consultationlist = consultationlist;
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  // get Associated AHS Patner function
  specilizationSelected(id){
    this.apiService.getAssociateAhspartner(Number(this.patientId)).subscribe(associatedSpecialists => { 
      this.specialistslist = associatedSpecialists;
      this.specialistslist = this.specialistslist.assocSpecialists;
      let data = this.specialistslist;
      this.associatedSpecialists = [];
      for(let i=0; i < data.length; i++){
          if(data[i].specialization == id){
            this.associatedSpecialists.push(data[i]);
          }
      }
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  //Delete recomendation function
  deleteRecomendation(index,id) {
      this.apiService.deleteRecomendation(Number(this.visitID),Number(id)).subscribe(result => {            
               
                     this.ahsRecomendations.splice(index, 1);
                     this.toastr.success('AHS Partner Visit deleted successfully');
               
   }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
      
  }

  

  ngOnInit() {
   
  }

  ngOnChanges(changes: SimpleChanges) { 


    this.patientID = this.patientId;
    this.visitID = this.visitId;
    this.Recommendations = this.recommendations;
    this.ahsRecomendations = [];
    if(this.Recommendations){
      for(let i = 0; i < this.Recommendations.length; i++){
          if(this.Recommendations[i].visitType == "CONSULTANT_VISIT" ){
            this.ahsRecomendations.push(this.Recommendations[i]);
          }
      }
    }
    

    if(this.Recommendations == null){
      console.log('test 1');
      console.log(this.Recommendations);
    }else{
      console.log('test 2');
      console.log('Recommendations');
      console.log(this.Recommendations);
    }

  }

}
