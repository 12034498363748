import { Component, OnInit, ViewContainerRef,OnChanges,SimpleChanges } from '@angular/core';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { AbstractControl,FormArray,ReactiveFormsModule, FormBuilder, FormGroup, FormControl, Validators, FormsModule ,NgForm  } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import {  ViewChild ,NgModule,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../_services/api.service';
import { appConfig } from '../../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var $ :any; 

@Component({
  selector: 'app-initiate-visit',
  templateUrl: './initiate-visit.component.html',
  styleUrls: ['./initiate-visit.component.css']
})
export class InitiateVisitComponent implements OnInit {
  public allPatients : any;
  public patients : any;
  public activePatient : any;

  isMeridian = false;

  modalRef: BsModalRef;
  modalRef1 : BsModalRef;
  modalRef2 : BsModalRef;


  addLabTest: FormGroup;
  editLabTest: FormGroup;

  initiateVisit: FormGroup;

  public schedDate:any;
  public schedTime: any;
  public visitType: any;
  public follwup:boolean;
  public orderByPeritus:any;

  public testName: any;
  public testID: any;
  public frequency: any;
  public selected : any;

  public hasData:boolean = false;

  problemSymptoms = [];

  p: number = 1;
  count = 5;

  bsValue: Date;
  mytime: Date;
  
  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,private route: ActivatedRoute,
    public toastr: ToastrService,private titleService: Title) {
      if (window.matchMedia('(max-width: 1024px)').matches) {
        this.count = 3;
      } else {
        this.count = 5; 
      }
     
    this.titleService.setTitle( 'Initiate Visit' );

    this.initiateVisit = fb.group({
           schedDate : ['', Validators.required],
           schedTime: ['', Validators.required],
           visitType: ['', Validators.required],
           orderByPeritus:['']
     });

     this.addLabTest = fb.group({
           testName : new FormControl(null, Validators.required),
           testID :new FormControl(null),
           frequency: new FormControl(null, Validators.required)
     });

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  updateTest(index,id,template: TemplateRef<any>){
      this.modalRef1 = this.modalService.show(template);
  }

  ngOnInit() {
     this.loadPatients();
     this.follwup = false;
  }

  selectVisitType(value){
      if(value == "FOLLOWUP"){
        this.follwup = true;
      }else{
        this.follwup = false;
      }
  }

  observableSource = (keyword: any): Observable<any[]> => {
    let url: string = appConfig.apiUrl + '/labTests/search?pattern='+keyword;
    let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
    let options = new RequestOptions({ headers: headers });
    if (keyword) {     
      return this.http.get(url,options)
        .map((res: Response) => { 
           
          return res.json();
        })
    } else {
      return Observable.of([]);
    }
  }

 
  testSelected(val){
    this.selected = val.testName;
    this.testID = val.id;
    console.log(val);
  }

  deleteTest(index){
    this.labTests.splice(index, 1);
  }

  submitForm(post) {

    post.schedDate = this.datePipe.transform(post.schedDate, 'dd-MM-yyyy');
    
    post.schedTime = this.datePipe.transform(post.schedTime, 'HH:mm');
    let order = undefined;
    
    let tests = [];
    this.labTests.forEach(val => {
        if(val.testId) {
          tests.push({testId : val.testId,
            testName : val.testName,
            testFreq : val.testFreq,
            testMasterId : val.testMasterId,
            clientId : this.activePatient.client_id
            })
        } else {
          tests.push({testMasterId : val.testMasterId,
            testName : val.testName,
            testFreq : val.testFreq,
            clientId : this.activePatient.client_id})
        }
        if(post.orderByPeritus == true){
            order = "PERITUS"
        }
        
    });
    let followups = [];
    this.SymptomsfollowUp.forEach(val => {
        followups.push({symptom_master_id : val.symptom_master_id,
                  observed_since : val.observed_since,
                  symptom_desc : val.symptom_desc})
    });
    post = {
            clientId: this.activePatient.client_id,
            consultationType: "VISIT",
            schedDate: post.schedDate,
            schedTime:post.schedTime,
            status: "CONFIRMED",
            visitType: post.visitType,
            labTests: tests,
            problemSymptoms:followups,
            orderBy : order
    }
    console.log(post);
    this.apiService.initiateVisit(post)
            .subscribe(result => {
              this.toastr.success('Visit initiated successfully', 'Successful!');
              this.loadPatients();
    this.initiateVisit.reset();
             
               
    }, error => {
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      }; 
  }



  submitForm1(post) {
    post = {
      testMasterId : post.testID,
      testName : post.testName,
      testFreq : post.frequency
    }
    this.labTests.push(post);
    this.modalRef.hide();
    this.addLabTest.reset();
    console.log('post Data::'+JSON.stringify(post));
  }


  public SymptomsfollowUp = [];
  public follwupIds = [];

  follwupSymptoms(val){
      if(this.SymptomsfollowUp.length < 1){
        this.SymptomsfollowUp.push(val);
        this.follwupIds.push(val.id);
      }else{
         if (this.follwupIds.indexOf(val.id) === -1) {
                this.SymptomsfollowUp.push(val);
                this.follwupIds.push(val.id);
         }else{
            this.SymptomsfollowUp.forEach((item,index) => {
              if(item.id == val.id){
                this.SymptomsfollowUp.splice(index, 1);
                this.follwupIds.splice(index, 1);
              }
            });
         }
      }  

      console.log(this.SymptomsfollowUp); 
  }



  loadPatients() {
        this.apiService.getPatientsData('ACTIVE').subscribe(patients => {
          this.allPatients =  patients;
          
          this.patients = [];
          this.activePatient = null;
          let data = []
          this.allPatients.forEach((element,index) => {
             if(element.currentActivity == "NORMAL"  ){
                  data.push(element);
             }
             if(element.currentActivity == null ){
                  data.push(element);
             }
             if( element.currentActivity == "BEING_MONITORED") {
               data.push(element);
             }
          });

          data.forEach((element,index) => {
             if(index == 0){
                 this.activePatient = element;
                this.loadLastVistHistory(element.client_id);
             }else{
                 this.patients.push(element);
             }
          });

          if(this.activePatient){
                this.hasData = true;
          }


          // this.patients = patients;
          // this.activePatient = patients[0];
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }

  public visitdata:any;
  public labTests:any;


  loadLastVistHistory(id) {
        this.labTests = [];
        this.visitdata = undefined;
        this.problemSymptoms = [];
        this.apiService.getLastVistHistory(id).subscribe(patients => { 
           this.visitdata = patients;
           this.labTests = this.visitdata.labTestReports;
           this.problemSymptoms = this.visitdata.problemSymptoms;
        });
  }



  selectClient(id){
      this.patients = [];
      let data = [];
      this.initiateVisit.reset();
      this.allPatients.forEach((element,index) => {
         if(element.currentActivity == "NORMAL"  ){
              data.push(element);
         }
         if(element.currentActivity == null ){
              data.push(element);
         }
         if( element.currentActivity == "BEING_MONITORED") {
               data.push(element);
             }
      });
      data.forEach((element,index) => {
          if(element.client_id == id){
              this.activePatient = element;
              this.loadLastVistHistory(element.client_id);
          }else if(element.pkg_name != null && element.currentActivity != 'VISIT_PENDING'){
                  this.patients.push(element);
          }
      });
 
  }

  



}
