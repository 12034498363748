import { Component, ViewChild, OnInit ,Input,NgModule ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

declare var $ :any; 

@Component({
  selector: 'app-problem-symptom-widget',
  templateUrl: './problem-symptom-widget.component.html',
  styleUrls: ['./problem-symptom-widget.component.css']
})
export class ProblemSymptomWidgetComponent implements OnChanges {

  @ViewChild(ModalDirective) modal: ModalDirective;

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  @Input() public patientId:any;
  @Input() public visitId: any;
  @Input() public problemSymptoms: any; 
  @Input() public addnew: any;
  

  public patientID:any;
  public visitID : any;
  public ProblemSymptoms : any;
  public currentSymptom : any;



  name:string;
  showHide:boolean = false;

  modalRef: BsModalRef;

  selected:any;

  public symptoms: Array<any>;

  datepickerModel1: Date;
  datepickerModel2:Date;
  maxDate: Date;

  addSymptom: FormGroup;

  public observed_since: any;
  public symptom_desc: any;

  PSrecords = [
  {id:'1',observed_since:'02-07-2018',symptom_desc: 'Problem Symptom Adding - Tesing', impact:'LOW',consultation_id:'1'},
  {id:'2',observed_since:'02-07-2018',symptom_desc: 'Problem Symptom Adding - Tesing', impact:'LOW',consultation_id:'1'},
  {id:'37',observed_since:'14-03-2018',symptom_desc: 'Problem Symptom', impact:'LOW',consultation_id:'1'}
  ];

  addPSHistory:FormGroup;

  PShistoryRecords = [];

  public record_date: any;
  public result: any;
  public remarks: any;
  public PSid: any;
  public PSmid: any;


  
  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,public toastr: ToastrService) 
  {
    this.maxDate = new Date();
     
      this.addSymptom = fb.group({
           observed_since: [null, Validators.required],
           symptom_desc: [null, Validators.required],
      });

      this.addPSHistory = fb.group({
           record_date: [null, Validators.required],
           result: [null, Validators.required],
           remarks: [null, Validators.required],
          
      });

      
  }



  observableSource1 = (keyword: any): Observable<any[]> => {
    let url: string = appConfig.apiUrl + '/consultationSummary/searchSymptom?pattern='+keyword;
    let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
    let options = new RequestOptions({ headers: headers });
    if (keyword) {
      return this.http.get(url,options)
        .map((res: Response) => {
          console.log(res.json());
          return res.json()
        })
    } else {
      return Observable.of([]);
    }
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  openPSHistory(template: TemplateRef<any>,num: number,num2: number,symptom){
     this.modalRef = this.modalService.show(template);
     this.PSid = num;
     this.PSmid = num2;
     this.PShistoryRecords = [];
     this.currentSymptom = symptom;
     let val1 = null;
		let renewDate = new Date();
    this.datepickerModel2 = new Date();
    
     this.apiService.getPSHistory(this.visitID,num).subscribe(result => {            
               this.PShistoryRecords = result;
               this.PShistoryRecords.reverse();
     }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            if(edata.status != "NOT_FOUND") {
            this.toastr.error(edata.message, edata.status);
          }
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  //pullSymptoms(symptom) {
    //  this.get_zone_id = _zone_id;
     // this.apiService.getSymptoms(symptom).subscribe(
      //  data   => {
        //  console.log(data);
        //  this.symptomss = data;          
       // }
     // );
   
 // }

  submitForm(post) {
    post.observed_since = this.datePipe.transform(post.observed_since, 'dd-MM-yyyy');
    
   
    
    let masterid = undefined;
    let sydes;
    if(post.symptom_desc.symptomMasterId) {
       masterid = post.symptom_desc.symptomMasterId;
       sydes = post.symptom_desc.symptomDescription;
    } else {
       sydes = post.symptom_desc;
    }
    let data = {
            observed_since : post.observed_since,
            symptom_desc : sydes,
            symptom_master_id : masterid,
            impact : 'LOW',
            consultation_id : this.visitID}
    console.log('post Data::'+JSON.stringify(data));
    this.apiService.postProblemSymptom(data,this.visitID)
            .subscribe(result => {
         
                this.modalRef.hide();
                this.problemSymptoms.push(result);
                // alert("Problem Symptom Added Successfully");
                this.addSymptom.reset();
                this.toastr.success("Problem Symptom Added Successfully");
                //this.problemSymptoms.reverse();
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }


  submitForm1(post) {
    post.record_date = this.datePipe.transform(post.record_date, 'dd-MM-yyyy');
    
    
    

    post = {
        symptom_id : this.PSid,
        symptom_master_id : this.PSmid,
        result : post.result,
        impact : "NORMAL",
        client_id : this.patientID,
        record_date :  post.record_date,
        remarks : post.remarks
     }

    console.log('post Data::'+JSON.stringify(post));
    this.apiService.addPSHistory(post,this.visitID,Number(post.symptom_id))
            .subscribe(result => {
                
                  this.PShistoryRecords.push(result);
                  this.addPSHistory.controls['record_date'].setValue('');
                  this.addPSHistory.controls['result'].setValue('');
                  this.addPSHistory.controls['remarks'].setValue('');

                  this.toastr.success("History added Successfully");
                  //alert("Problem Symptom History Added Successfully");
                  this.addSymptom.reset();
                  //this.PShistoryRecords.reverse();
              
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };

    
  }


  addHistory(){
    this.showHide = !this.showHide;
    console.log(this.showHide);
  }

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges) { 

    this.patientID = this.patientId;
    this.visitID = this.visitId;
    this.problemSymptoms = this.problemSymptoms;
    if( this.problemSymptoms !== null && this.problemSymptoms !== undefined ) {
      this.problemSymptoms.reverse();
    }
    console.log('Patient Id');
    console.log(this.patientID);

    // if(this.problemSymptoms == null){
    //   console.log('test 1');
    //   console.log(this.problemSymptoms);
    // }else{
    //   console.log('test 2');
    //   console.log(this.problemSymptoms);
    // }

  }

}
