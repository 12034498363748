import { Component, OnInit ,ChangeDetectionStrategy} from '@angular/core';
import { Injectable } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format
} from 'date-fns';
import { colors } from '../demo-utils/colors';
import { Title } from '@angular/platform-browser';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { DatePipe } from '@angular/common';
import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../_services/index';
import 'rxjs/add/operator/catch'; 
import 'rxjs/add/observable/throw';
import { HttpClientModule } from '@angular/common/http'; 
interface Film {
  id: number;
  title: string;
  release_date: string;
}

@Component({
  selector: 'app-doctor-weekly-schedule',
  templateUrl: './doctor-weekly-schedule.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./doctor-weekly-schedule.component.css']
})
export class DoctorWeeklyScheduleComponent implements OnInit {

  title :string;
  public data:any;
  public visits:any;
  public currentUser:any;

  constructor(private route: ActivatedRoute,
    private titleService: Title,
    private datePipe: DatePipe,
    private apiService: ApisService,
    private http: Http,
    private authenticationService: AuthenticationService,private router: Router) {
    this.titleService.setTitle( 'Weekly Schedule' );
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  
  view: string = 'week';

  viewDate: Date = new Date();

  events$: Observable<Array<CalendarEvent<{ film: Film }>>>;

  activeDayIsOpen: boolean = false;

  

  ngOnInit() {

    this.getWeeklySchedule();

    

  }

  getWeeklySchedule() {

    let renewDate = new Date();
    let val1 = renewDate.setDate(renewDate.getDate() - 120);
    let expireDate = new Date();
    let  val2 = expireDate.setDate(expireDate.getDate() + 120);
    let val3 = this.datePipe.transform(val2,'dd-MM-yyyy');
    let val4 = this.datePipe.transform(val1,'dd-MM-yyyy');
    // this.apiService.getWeeklySchedule(val1,val3).subscribe(user => { 
          

    //       return user;
    // });

    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay
    }[this.view];

    const params = new HttpParams()
      .set(
        'primary_release_date.gte',
        format(getStart(this.viewDate), 'YYYY-MM-DD')
      )
      .set(
        'primary_release_date.lte',
        format(getEnd(this.viewDate), 'YYYY-MM-DD')
      )
      .set('api_key', '0ec33936a68018857d727958dca1424f');

      this.visits = [];


      let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
      let options = new RequestOptions({ headers: headers });

    this.events$ = this.http
      .get(appConfig.apiUrl + '/schedule?type=VISIT,ADHOC&status=CONFIRMED&startDate='+val4+'&endDate='+val3, options)
      .map(( results ) => {
          this.visits = results.json();
          let data = [];
          console.log(this.visits);
          for(let i = 0; i< this.visits.length; i++){
            let startdate =  this.visits[i].visitSchedule.date.split("-");
             data.push({
              title: this.visits[i].patientDetails.name +' - '+ this.visits[i].patientDetails.address.area +' - '+ this.visits[i].visitSchedule.time,
              start:  new Date(Number(startdate[2]) ,Number(startdate[1])-1, Number(startdate[0])),
              color: colors.yellow,
              id: this.visits[i].visitId,
              status: this.visits[i].status,
              visitSchedule: this.visits[i].visitSchedule
            })

          }
          return data;

      })
      
  }

 

  eventClicked(event): void {
    console.log(event);
    let val3 = new Date();
    let date = this.datePipe.transform(val3, 'dd-MM-yyyy');
    if(this.currentUser.userrole == 'ROLE_OPS_ADMIN'){
      this.router.navigateByUrl('/pending-visit/'+event.id);
      
    } else {
      if(date == event.visitSchedule.date) {
        this.router.navigateByUrl('/scheduled-vist/'+event.id);
      }else {
        this.router.navigateByUrl('/pending-visit/'+event.id);
      }
     
    }
  }
  

}
