import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { AbstractControl,FormArray,ReactiveFormsModule, FormBuilder, FormGroup, FormControl, Validators, FormsModule ,NgForm  } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import {  ViewChild ,NgModule,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../../_services/api.service';
import { appConfig } from '../../../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var $ :any; 

@Component({
  selector: 'app-package-history',
  templateUrl: './package-history.component.html',
  styleUrls: ['./package-history.component.css']
})
export class PackageHistoryComponent implements OnInit {

  @ViewChild(ModalDirective) modal: ModalDirective;

	patientStatus: FormGroup;

	modalRef: BsModalRef;

	public user :any;
	public packages : any = [];
  public currentPackage : any;
  public message : any;

  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private titleService: Title,
    public toastr: ToastrService) {
     
    this.titleService.setTitle( 'Package History' );

    this.patientStatus = fb.group({

      patientFirstName: new FormControl(null, Validators.required),
    
      
       });

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  delete() {
    
    this.apiService.deletePatient(Number(this.user.client_id),this.message)
        .subscribe(result => {
                this.toastr.success('Patient deAssociated successfully', 'Successful!');
                this.modalRef.hide();
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };  
  }

   ngOnInit() {

  	this.user = this.route.snapshot.params['id'];
		console.log(this.user);
		this.getCurrentPatients(this.user);

		this.apiService.getPackages(Number(this.user))
	            .subscribe(result => {
	                if (result != null) {
	                     console.log(result);
	                     let last = result.length - 1;
	                     this.packages = result;
	                     this.currentPackage = result[last];
	                     console.log(this.currentPackage);
	                    // this.Message = "Specialist Created Successfully"
	                } else {
	                  
	                }
	    }, error => {
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      }; 
  }

  getCurrentPatients(id) {
        this.apiService.getCurrentPatients(id).subscribe(user => { 
          this.user = user;
          console.log('user data');
          console.log(this.user);

        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }


}
