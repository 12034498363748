﻿import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './../main/main.component';
import { AuthGuard } from './../_guards/index';

import { DoctorDashboardComponent } from './doctor-dashboard/doctor-dashboard.component';
import { OperatorDashboardComponent } from './operator-dashboard/operator-dashboard.component';
import { TodaysVisitScheduleComponent } from './todays-visit-schedule/todays-visit-schedule.component';
import { MonitorHealthComponent } from './monitor-health/monitor-health.component';
import { DiagnosticsComponent } from './diagnostics/diagnostics.component';
import { DoctorWeeklyScheduleComponent } from './doctor-weekly-schedule/doctor-weekly-schedule.component';
import { ConsultationSummaryComponent } from './consultation-summary/consultation-summary.component';

import { PatientListComponent } from './patient/patient-list/patient-list.component';
import { PatientDashboardComponent } from './patient/patient-details/patient-dashboard/patient-dashboard.component';
import { PersonalDetailsComponent } from './patient/patient-details/personal-details/personal-details.component';
import { PatientSponsorCallerComponent } from './patient/patient-details/patient-sponsor-caller/patient-sponsor-caller.component';
import { PackageHistoryComponent } from './patient/patient-details/package-history/package-history.component';
import { PatientConsultantsComponent } from './patient/patient-details/patient-consultants/patient-consultants.component';
import { DoctorCallbacksComponent } from './patient/patient-details/doctor-callbacks/doctor-callbacks.component';
import { DoctorVisitsComponent } from './patient/patient-details/doctor-visits/doctor-visits.component';
import { HospitalizationsComponent } from './patient/patient-details/hospitalizations/hospitalizations.component';
import { PatientSpecialistsComponent } from './patient/patient-details/patient-specialists/patient-specialists.component';
import { PatientSpecialistVisitsComponent } from './patient/patient-details/patient-specialist-visits/patient-specialist-visits.component';
import { OthersComponent } from './patient/patient-details/others/others.component';
import { RenewPackageComponent } from './patient/patient-details/renew-package/renew-package.component';
import { SpecialistFromComponent } from './patient/patient-details/specialist-from/specialist-from.component';
import { ConsultantFormComponent } from './patient/patient-details/consultant-form/consultant-form.component';
import { NewHospitalizationComponent } from './patient/patient-details/new-hospitalization/new-hospitalization.component';
import { SponsorComponent } from './patient/patient-details/sponsor/sponsor.component';

import { InitiateVisitComponent } from './visits/initiate-visit/initiate-visit.component';
import { InitiateSpecialistVisitComponent } from './visits/initiate-specialist-visit/initiate-specialist-visit.component';
import { InitiateConsultantVisitComponent } from './visits/initiate-consultant-visit/initiate-consultant-visit.component';
import { InitiateAdhocVisitComponent } from './visits/initiate-adhoc-visit/initiate-adhoc-visit.component';

import { DoctorClinicalLabComponent } from './doctor-consultation-summary/clinical-lab/doctor-clinical-lab.component';
import { DoctorDiagnosticsSymptomsComponent } from './doctor-consultation-summary/diagnostics-symptoms/doctor-diagnostics-symptoms.component';
import { DoctorMonitorTreatmentComponent } from './doctor-consultation-summary/monitor-treatment/doctor-monitor-treatment.component';
import { DoctorRecommedationsComponent } from './doctor-consultation-summary/recommedations/doctor-recommedations.component';
import { DoctorTreatmentComponent } from './doctor-consultation-summary/treatment/doctor-treatment.component';

import { PhoneSummarySheetComponent } from './phone-summary-sheet/phone-summary-sheet.component';
import { CallbackRequestsComponent } from './callback-requests/callback-requests.component';
import { PendingVistConfirmationComponent } from './pending-vist-confirmation/pending-vist-confirmation.component';

import { PartnersComponent } from './partners/partners.component';
import { PartnerFromComponent } from './partner-from/partner-from.component';
import { NewPatientComponent } from './patient/new-patient/new-patient.component';
import { PendingVisitsComponent } from './pending-visits/pending-visits.component';
import { ConsultationSummaryListComponent } from './consultation-summary-list/consultation-summary-list.component';
import { ScheduledVisitComponent } from './scheduled-visit/scheduled-visit.component';

import { MonitorHealthListComponent } from './monitor-health-list/monitor-health-list.component';
import { DiagnosticsListComponent } from './diagnostics-list/diagnostics-list.component';
import { CallbackRequestsListComponent } from './callback-requests-list/callback-requests-list.component';
import { PhoneSummarySheetListComponent } from './phone-summary-sheet-list/phone-summary-sheet-list.component';


import { SpecialistFormEditComponent } from './patient/patient-details/specialist-form-edit/specialist-form-edit.component';
import { ConsultantFormEditComponent } from './patient/patient-details/consultant-form-edit/consultant-form-edit.component';
import { SponsorEditComponent } from './patient/patient-details/sponsor-edit/sponsor-edit.component';

const appRoutes: Routes = [
    { path: '', component: MainComponent,
		children: [
			{ path: 'doctor-dashboard', component: DoctorDashboardComponent, canActivate: [AuthGuard], data: { title: 'Dashboard' } },
			{ path: 'operator-dashboard', component: OperatorDashboardComponent, canActivate: [AuthGuard], data: { title: 'Dashboard' } },
			{ path: 'todays-visit-schedule', component: TodaysVisitScheduleComponent, canActivate: [AuthGuard]},
                  { path: 'scheduled-vist/:id', component: ScheduledVisitComponent, canActivate: [AuthGuard]},
			{ path: 'monitor-health', component: MonitorHealthComponent, canActivate: [AuthGuard]},
                  { path: 'monitor-health-list/:id', component: MonitorHealthListComponent, canActivate: [AuthGuard]},
                  { path: 'diagnostics', component: DiagnosticsComponent, canActivate: [AuthGuard]},
			{ path: 'doctor-weekly-schedule', component: DoctorWeeklyScheduleComponent, canActivate: [AuthGuard]},
                  { path: 'consultation-summary', component:ConsultationSummaryComponent, canActivate: [AuthGuard]},
                  { path: 'consultation-summary/:id', component:ConsultationSummaryListComponent, canActivate: [AuthGuard]},

                  { path: 'new-patient', component: NewPatientComponent, canActivate: [AuthGuard] },
			{ path: 'patients-list', component: PatientListComponent, canActivate: [AuthGuard] },
			{ path: 'patient/patients-dashboard', component: PatientDashboardComponent, canActivate: [AuthGuard]  },
          	{ path: 'patient/:id/personal-details', component: PersonalDetailsComponent, canActivate: [AuthGuard]  },
            { path: 'patient/:id/sponsor-caller', component: PatientSponsorCallerComponent, canActivate: [AuthGuard]  },
            { path: 'patient/:id/sponsor', component: SponsorComponent, canActivate: [AuthGuard]  },
            { path: 'patient/:id/package-history', component: PackageHistoryComponent, canActivate: [AuthGuard]  },
            { path: 'patient/:id/specialists', component:PatientSpecialistsComponent, canActivate: [AuthGuard]  },
            { path: 'patient/:id/consultants', component:PatientConsultantsComponent, canActivate: [AuthGuard]  },
            { path: 'patient/:id/doctor-visits', component:DoctorVisitsComponent , canActivate: [AuthGuard] },
            { path: 'patient/:id/doctor-callbacks', component:DoctorCallbacksComponent, canActivate: [AuthGuard]  },
            { path: 'patient/:id/specialist-visits', component:PatientSpecialistVisitsComponent, canActivate: [AuthGuard]  },
            { path: 'patient/:id/hospitalizations', component:HospitalizationsComponent, canActivate: [AuthGuard]  },
            { path:'patient/:id/others',  component:OthersComponent, canActivate: [AuthGuard]  }, 
            { path:'patient/:id/renew-package', component:RenewPackageComponent, canActivate: [AuthGuard]  },
            { path: 'patient/:id/specialist-from', component:SpecialistFromComponent, canActivate: [AuthGuard]  },
            { path: 'patient/specialist-edit/:id', component:SpecialistFormEditComponent, canActivate: [AuthGuard]  },
            
            { path: 'patient/:id/consultant-from', component:ConsultantFormComponent, canActivate: [AuthGuard]  },
            { path: 'patient/consultant-edit/:id', component:ConsultantFormEditComponent, canActivate: [AuthGuard]  },
            
            { path: 'patient/:id/new-hospitalization', component:NewHospitalizationComponent, canActivate: [AuthGuard]  },

            { path: 'visits/initiate-visit', component:InitiateVisitComponent, canActivate: [AuthGuard]   },
            { path: 'visits/initiate-specialist-visit', component:InitiateSpecialistVisitComponent, canActivate: [AuthGuard] },
            { path: 'visits/initiate-consultant-visit', component:InitiateConsultantVisitComponent, canActivate: [AuthGuard] },
            { path: 'visits/initiate-adhoc-visit', component:InitiateAdhocVisitComponent, canActivate: [AuthGuard] },

            { path: 'clinical-lab', component: DoctorClinicalLabComponent, canActivate: [AuthGuard]},
            { path: 'diagnostics-symptoms', component: DoctorDiagnosticsSymptomsComponent, canActivate: [AuthGuard] },
            { path: 'monitor-treatment', component: DoctorMonitorTreatmentComponent, canActivate: [AuthGuard] },
            { path: 'recommedations', component: DoctorRecommedationsComponent, canActivate: [AuthGuard] },
            { path: 'treatment', component: DoctorTreatmentComponent, canActivate: [AuthGuard] },
            { path: 'phone-cunsultation-summery', component: PhoneSummarySheetComponent, canActivate: [AuthGuard] },
            { path: 'phone-cunsultation-summery-list/:id', component: PhoneSummarySheetListComponent, canActivate: [AuthGuard] },
            { path: 'call-back-requests', component: CallbackRequestsComponent, canActivate: [AuthGuard] },
            { path: 'call-back-requests-list/:id', component: CallbackRequestsListComponent, canActivate: [AuthGuard] },
            { path: 'pending-vist-confirmation', component: PendingVistConfirmationComponent, canActivate: [AuthGuard] },
            { path: 'pending-visit/:id', component: PendingVisitsComponent, canActivate: [AuthGuard] },
            { path: 'patners', component: PartnersComponent, canActivate: [AuthGuard] },
            { path: 'patners-from', component: PartnerFromComponent, canActivate: [AuthGuard] },
            
		]
	},


];

export const mainRouting = RouterModule.forRoot(appRoutes);