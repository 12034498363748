import { Component, ViewChild, OnInit ,NgModule , ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../_services/index';
import { ApisService } from '../../../_services/api.service';
import { appConfig } from '../../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
declare var $ :any; 
@Component({
  selector: 'app-patient-header',
  templateUrl: './patient-header.component.html',
  styleUrls: ['./patient-header.component.css']
})
export class PatientHeaderComponent implements OnInit {

  user:any;
  userphone: any;
  useremail: any;

  constructor(private apiService: ApisService,
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private datePipe: DatePipe,private route: ActivatedRoute,
    private titleService: Title) {

  	this.route.url.subscribe(url =>{
        	this.getCurrentPatients(this.route.snapshot.params['id']);
	});
  }

  getCurrentPatients(id) {
	        this.apiService.getCurrentPatients(id).subscribe(user => { 
            this.user = user;
            this.useremail = '';
            this.userphone = '';
            this.user.personalDetails.contacts.forEach(element => {
              console.log(element.type);
              if(element.type == 'EMAIL' && this.useremail == '') {
                this.useremail = element.value
              }
              if(element.type == 'PHONE' && this.userphone == '' ) {
                
                  this.userphone = element.value
               
              }
            });
            console.log('User Data');
            console.log(this.user);
	        });
	 }

  ngOnInit() {

  }

}
