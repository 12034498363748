import { Component, ViewChild,ChangeDetectorRef, OnInit,Output ,Input,NgModule,EventEmitter ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable, combineLatest, Subscription } from 'rxjs';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';

import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
declare var $ :any; 

@Component({
  selector: 'app-vital-tracking-widget',
  templateUrl: './vital-tracking-widget.component.html',
  styleUrls: ['./vital-tracking-widget.component.css']
})
export class VitalTrackingWidgetComponent implements OnChanges {

  @ViewChild(ModalDirective) modal: ModalDirective;

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  @Output() childEvent = new EventEmitter();
  @Input() public patientId:any;
  @Input() public visitId: any;
  @Input() public vitalrecordings: any; 
  @Input() public addnew: any;

  subscriptions: Subscription[] = [];
  
  public patientID:any;
  public visitID : any;
  public Vitalrecordings : any;

  public vitalsList:any;
  public selectedVital:any;

  modalRef: BsModalRef;
  modalRef1: BsModalRef;

  addVital:FormGroup;
  editVital:FormGroup;

  public vitalname: any;
  public vitalid : any;
  public fromvalue: any;
  public tovalue: any;
  public type: any;
  public frequency: any;
  public time: any;
  public startdate: any;
  public enddate: any;

  public e_vitalname: any;
  public e_vitalid:any;
  public e_fromvalue: any;
  public e_tovalue: any;
  public e_type: any;
  public e_frequency: any;
  public e_time: any;
  public e_startdate: any;
  public e_enddate: any;
  public e_remarks: any;
  public e_recordConfigId: any;

  public indexvalue: any;
  public Monitor:boolean = true; 
  public datepickerModel1:Date;
  public datepickerModel2:any  = new Date();
  public maxDate:any  = new Date();

  




  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    public toastr: ToastrService,
    private changeDetection: ChangeDetectorRef) {

     

    this.addVital = fb.group({
           vitalname : new FormControl(null, Validators.required),
           vitalid : new FormControl(null, Validators.required),
           e_recordConfigId : new FormControl(null,), 
           recordvalue: new FormControl(null),
           frequency: new FormControl(null),
           monitorVital: new FormControl(true),
           fromvalue: new FormControl(null),
           tovalue: new FormControl(null),
           time: new FormControl(null),
           startdate: new FormControl(null),
           enddate: new FormControl(null),
    });

    this.editVital = fb.group({
           e_vitalname : new FormControl(null),
           e_vitalid : new FormControl(null),
           e_fromvalue: new FormControl(null),
           e_tovalue: new FormControl(null),
           e_type: new FormControl(null),
           e_frequency: new FormControl(null),
           e_time: new FormControl(null),
           e_startdate: new FormControl(null),
           e_enddate: new FormControl(null),
           e_remarks: new FormControl(null),
           e_recordConfigId : new FormControl(null),
           recordvalue: new FormControl(null),
    });

  }

  openModal(template: TemplateRef<any>) {
    this.addVital.reset();
    this.Monitor = false;
        this.fromvalue = '';
        this.tovalue = '';
        this.type = '';
    this.datepickerModel1 = null;
    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addVital.reset();
        this.Monitor = false;
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addVital.reset();
        this.Monitor = false;
        this.fromvalue = '';
        this.tovalue = '';
        this.type = '';
        this.unsubscribe();
      })
    );
 
    this.subscriptions.push(_combine);

    this.modalRef = this.modalService.show(template);
  }

  statDateSelected(value) {
    if(value != null) {
      this.datepickerModel2 = value;
    }
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }




  listVitals(){
  	this.apiService.getVitals().subscribe(result => {
      
        console.log('Vitals');
        console.log(result);
        this.vitalsList = result
           
       
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  VitalSelected(id){
    let vitals = this.vitalsList;
    for(let i = 0; i<vitals.length; i++){
      if(vitals[i].id == id){
        this.fromvalue = vitals[i].normalRangeStart;
        this.tovalue = vitals[i].normalRangeEnd;
        this.type = vitals[i].unit
        this.addVital.controls['vitalid'].setValue(vitals[i].vitalName);  
      }
    }
  }

  MonotorVital(){
    let val = this.addVital.controls['monitorVital'].value;
    if( val == true ){
      this.Monitor = false;
    } else {
      this.Monitor = true;
    }
  }

  updateData(){
    this.childEvent.emit();
  }


  submitForm1(post){
    this.modalRef.hide();
    let values = undefined;

    

      post.startdate = this.datePipe.transform(post.startdate, 'dd-MM-yyyy');
      post.enddate = this.datePipe.transform(post.enddate, 'dd-MM-yyyy');
      let date3 = new Date();
      let date4 = this.datePipe.transform(date3, 'dd-MM-yyyy HH:mm');
      const res = moment().subtract(60, 'seconds').format("DD-MM-YYYY HH:mm");
      if(post.recordvalue) {
        values = [];
        values.push({ value: post.recordvalue, timeOfReading:res, timeOfRecording: res});
      } else {
        values = undefined;
      }

    

    

    

    post =  {
        consultationId : this.visitID,
        clientId : this.patientID,
        freqType : post.time,
        monitoringFreq : post.frequency,
        startDate : post.startdate,
        endDate :  post.enddate,
        vitalId : post.vitalname,
        name : post.vitalid,
        patientRangeStart :  post.fromvalue,
        patientRangeEnd : post.tovalue,
        values : values
    };
    console.log('post Data::'+JSON.stringify(post));
    this.apiService.postVitalsRecord(post,Number(this.visitID))
            .subscribe(result => {
               
                     console.log(result);
                     this.addVital.reset();
                     this.toastr.success('Vital added successfully', 'Successful!');
                     this.Vitalrecordings.push(result);
                     this.updateData();
                
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };   
  }

  editVitalRow(template: TemplateRef<any>,index,vital){
      this.modalRef1 = this.modalService.show(template);
      console.log(vital);
      this.selectedVital = vital;

      this.indexvalue = index;

      let startdate =  vital.startDate.split("-");
      startdate = new Date(Number(startdate[2]) ,Number(startdate[1])-1, Number(startdate[0]));
      let enddate =  vital.endDate.split("-");
      enddate = new Date(Number(enddate[2]) ,Number(enddate[1])-1, Number(enddate[0]));

      this.fromvalue = vital.allowedRangeStart;
      this.tovalue = vital.allowedRangeEnd;
      this.type = vital.unit;
      this.editVital.controls['e_vitalname'].setValue(vital.name);
      this.editVital.controls['e_vitalid'].setValue(vital.vitalId);
      this.editVital.controls['e_recordConfigId'].setValue(vital.recordConfigId);
      this.fromvalue = vital.normalRangeStart;
      this.tovalue = vital.normalRangeEnd;
      this.editVital.controls['e_fromvalue'].setValue(vital.patientRangeStart);
      this.editVital.controls['e_tovalue'].setValue(vital.patientRangeEnd);
      this.editVital.controls['e_type'].setValue(vital.unit);
      this.editVital.controls['e_frequency'].setValue(vital.monitoringFreq);
      this.editVital.controls['e_time'].setValue(vital.freqType);
      this.editVital.controls['e_startdate'].setValue(startdate);
      this.datepickerModel1 = startdate;
      this.editVital.controls['e_enddate'].setValue(enddate);
      this.editVital.controls['e_remarks'].setValue(vital.drFeedback); 
      let recordvalue = false;
      if(vital.values) {
        vital.values.forEach(element1 => {
            if(element1.recordedBy == 'konappa@peritussoft.com') {
              recordvalue = true;
              this.editVital.controls['recordvalue'].setValue(element1.value);
            }
          });
      }
      if(recordvalue == false) {
        this.editVital.controls['recordvalue'].setValue(''); 
      }
  }

  submitForm(post){
    this.modalRef1.hide();
    let recordvalue = post.recordvalue

    post.e_startdate = this.datePipe.transform(post.e_startdate, 'dd-MM-yyyy');
    post.e_enddate = this.datePipe.transform(post.e_enddate, 'dd-MM-yyyy');
    let visit_id = post.e_recordConfigId;
    
    post = {
      name : this.selectedVital.name,
      recordConfigId : this.selectedVital.recordConfigId,
      consultationId : this.visitID,
      clientId : this.patientID,
      freqType : post.e_time,
      monitoringFreq : post.e_frequency,
      startDate : post.e_startdate,
      endDate : post.e_enddate,
      vitalId : this.selectedVital.vitalId,
      patientRangeStart :  post.e_fromvalue,
      patientRangeEnd : post.e_tovalue,
      drFeedback: post.e_remarks,
      values : this.selectedVital.values
    }

    post.values.forEach(element => {
      if(element.recordedBy == "konappa@peritussoft.com") {
        element.value = recordvalue;
      }
    });
    console.log('post Data::'+JSON.stringify(post));
    this.apiService.putVitalsRecord(post,Number(this.visitID),Number(visit_id))
            .subscribe(result => {
                
             console.log(result);
             this.Vitalrecordings[this.indexvalue] = post;
             this.toastr.success('Vital updated successfully', 'Successful!');
             this.editVital.reset();
             this.updateData();
                
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };  
  }

  

  deleteVitalRow(index,recordid){
    this.apiService.deleteVitalsRecord(Number(this.visitID),Number(recordid)).subscribe(result => {            
               
                     this.Vitalrecordings.splice(index, 1);
                     this.toastr.success('Vital deleted successfully', 'Successful!');

    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };  
  }

  ngOnInit() {
    this.listVitals();
  }
  ngOnChanges(changes: SimpleChanges) { 

    this.patientID = this.patientId;
    this.visitID = this.visitId;
    this.Vitalrecordings = this.vitalrecordings;

     if(this.Vitalrecordings != null){
      let vitals = [];
      this.Vitalrecordings.forEach(element => {
        
      
        if(element.monitoringFreq == 1 && element.startDate == element.endDate && element.freqType ==  'DAY') {
          if(!element.values[0]){
            vitals.push(element);
          }
        }else {
            vitals.push(element);
        }
        
      });
      this.Vitalrecordings = vitals;
     }

    if(this.Vitalrecordings != null){ 
      this.Vitalrecordings.forEach(element => {
          if(element.values) {
             element.values.forEach(element1 => {
                if(element1.recordedBy == 'konappa@peritussoft.com') {
                  element.recoredvalue = element1.value;
                  element.timeOfRecording = element1.timeOfRecording;
                }
             });
          }
      });
    }
   

  }

}
