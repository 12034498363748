import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-clinical-lab',
  templateUrl: './doctor-clinical-lab.component.html',
  styleUrls: ['./doctor-clinical-lab.component.css']
})
export class DoctorClinicalLabComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
