import { Component, ViewChild, OnInit ,NgModule ,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../_services/api.service';
import { appConfig } from '../../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-initiate-specialist-visit',
  templateUrl: './initiate-specialist-visit.component.html',
  styleUrls: ['./initiate-specialist-visit.component.css']
})
export class InitiateSpecialistVisitComponent implements OnInit {

  createSpVisit: FormGroup;

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  public doctorComments : any;
  public specialistName : any;
	public specilization : any;
  public addressLocation : any;
  public specialistList : any;
  public associatedSpecialists : any;

	public address : any;
	public secretaryName : any;
	public secretaryPhone : any;
	public appointmentStatus : any;
	public appointmentDate : any;
	public opsComments : any;

  public recommendations:any;
  public activeSummaries:any;
  public activePatient : any;
  public consultationSummaries : any = [];
  public allConsultationSummaries : any = [];
  public visitId :any;
  public patientDeails : any;
  public patientId : any;
  public patientAddress :any;
  public schedTime:any;
  public doctorId:any;
  public addressList:any;
  public selectedAreaId:any;
  public Slectedvisits:any;
  public specilizationID: any;
  public orginatingConsultationId:any;
  isMeridian = false;
  hasData = false;

	minDate : Date;
	

  bsValue : Date;
  mytime : Date;

  p: number = 1;
  count = 5;

	validemail : string ='Please enter the valid email.';
  mobileno : string ='Please enter valid Phone No';
  titleAlert : string = 'This field is required';

  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,private route: ActivatedRoute,
    private titleService: Title,
    public toastr: ToastrService) {
      if (window.matchMedia('(max-width: 1024px)').matches) {
        this.count = 3;
      } else {
        this.count = 3;
      }
     
    this.titleService.setTitle( 'Initiate Specialist Visit' );
  	this.createSpVisit = fb.group({

		 doctorComments: [null, Validators.required],
		 specialistName: [null, Validators.required],
		 specilization: [null, Validators.required],
		 addressLocation : ['', Validators.required],
		 address : ['', Validators.required],
		 secretaryName : ['', Validators.required],
		 secretaryPhone: [null, [Validators.required,
		  					Validators.minLength(9),
     						Validators.maxLength(10),
      						Validators.pattern('[0-9]+')
    				   ]],
		 appointmentStatus :['', Validators.required],
		 appointmentDate : ['', Validators.required],
     schedTime : ['', Validators.required],
		 opsComments : ['', Validators.required],

     
    	});
  }

  loadConsultationSummaries() {
    this.apiService.getPatientsData('SPECIALISTRECOMMENDED').subscribe(recommendations => { 
        this.allConsultationSummaries = recommendations;
        this.consultationSummaries = [];
        let filtereddata = [];
        let j = this.allConsultationSummaries;
         

           if(j.length > 0) {
             //alert(filtereddata.length);
             j.forEach((Element,index) => {
                 
                 if(index == 0){
                    this.hasData = true;
                    this.activePatient = Element;
                    this.visitId  = this.activePatient.lastVisitId;
                    this.patientDeails = this.activePatient.patientDetails;
                    this.patientId = this.activePatient.client_id;
                    this.loadCurrentConsultationSummary(this.visitId); 
                  } else {
                    this.consultationSummaries.push(Element);
                  }
                
             });
            }
            console.log(this.consultationSummaries);
    }, error => {
      this.hasData = false;
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  loadCurrentConsultationSummary(id){
    //alert(id);
    this.apiService.getCurrentRecommendation(Number(id)).subscribe(consultationSummary => {  
        this.activeSummaries = consultationSummary;
        this.recommendations = consultationSummary;
        

    }, error => {
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }


  specilizationSelected(id){
    let rec = this.recommendations;
    for(let i = 0; i < rec.length; i++){

          if( rec[i].specializationId == id){
              this.createSpVisit.controls['doctorComments'].setValue(rec[i].drComments);

              this.apiService.getAssociateSpecialist(Number(this.patientId)).subscribe(associatedSpecialists => { 
                this.specialistList = associatedSpecialists;
                this.specialistList = this.specialistList.assocSpecialists;
                let data = this.specialistList;
                this.associatedSpecialists = [];
                for(let i=0; i < data.length; i++){
                 // alert(data[i].specialityId +' '+ id);
                    if(data[i].specialityId == id){
                      this.associatedSpecialists.push(data[i]);
                    }
                }
              }, error => {
                    let edata;
                    if(error.apierror){ edata = error.apierror
                    } else { edata = error }
                    this.toastr.error(edata.message, edata.status);
              }),
                err   => {
                  this.toastr.error('500 Internal Server Error!');           
              };
              // this.createSpVisit.controls['specilization'].setValue(rec[i].specialization);
              console.log(rec[i]);
              this.doctorId = rec[i].id;
              this.specilizationID = id ;
              // this.addressList = rec[i].specialist.workingLocations;
              this.Slectedvisits = rec[i].visits;
              this.orginatingConsultationId = rec[i].consultation_id
          }
    }
  }

  specilastSelected(id){
    this.associatedSpecialists.forEach(item => {
        if(item.id == id) {
              this.doctorId = item.id;
              this.addressList = item.workingLocations;
        }
    });

  }

  areaSelected(id){
    let rec = this.addressList;
    console.log(rec);
    for(let i = 0; i < rec.length; i++){

          if( rec[i].address.id == id){
              this.selectedAreaId = rec[i].address.id;
              this.createSpVisit.controls['address'].setValue(rec[i].address.area);
              this.createSpVisit.controls['secretaryName'].setValue(rec[i].contactDetails.contactName);
              this.createSpVisit.controls['secretaryPhone'].setValue(rec[i].contactDetails.contactNo);
          }
    }
  }

  selectClient(id){
    this.createSpVisit.reset();
        this.consultationSummaries = [];
        let filtereddata = [];
        let j = this.allConsultationSummaries;
        

           if(j.length > 0) {
             //alert(filtereddata.length);
             j.forEach((Element,index) => {
                // alert(Element.lastVisitId);
                 if(Element.client_id == id){
                    this.hasData = true;
                    this.activePatient = Element;
                    this.visitId  = this.activePatient.lastVisitId;
                    this.patientDeails = this.activePatient.patientDetails;
                    this.patientId = this.activePatient.client_id;
                    this.loadCurrentConsultationSummary(this.visitId); 
                  } else {
                    this.consultationSummaries.push(Element);
                  }
                
             });
            }
  }

  

  submitForm(post) {
    post.appointmentDate = this.datePipe.transform(post.appointmentDate, 'dd-MM-yyyy');
    post.schedTime = this.datePipe.transform(post.schedTime, 'HH:mm');
    post = {
        consultationType : "SPECIALIST",
        clientId : this.patientId,
        schedDate : post.appointmentDate,
        schedTime : post.schedTime,
        visitType : "NEW",
        doctorId : this.doctorId,
        visitLocation : this.selectedAreaId,
        status : post.appointmentStatus,
        originatingConsultationId: this.orginatingConsultationId
    } 
    this.apiService.initiateVisit(post)
      .subscribe(result => {
          if (result != null) {
            console.log(result);
            this.createSpVisit.reset();
            this.loadConsultationSummaries();
            this.toastr.success('Visit created successfully', 'Successful!');
          } else {
             this.toastr.error('Some thing went wrong', 'Sorry!');
          }
    }, error => {
        let edata;
        if(error.apierror){ edata = error.apierror
        } else { edata = error }
        this.toastr.error(edata.message, edata.status);
      }),
      err   => {
        this.toastr.error('500 Internal Server Error!');           
    };   
		console.log('post Data::'+JSON.stringify(post));
  }

  public activeData:any;
  cancelVisit(){

    if (this.specilizationID != undefined) {
      this.activeData = [];
      this.activeSummaries.forEach(element => {
          if(element.specializationId == this.specilizationID) {
            this.activeData = element;
          }
    });

    let post = {
      specialization_id : this.activeData.specializationId,
      consultation_id : this.visitId,
      spec_cons_id : this.activeData.id,
      visit_type : "SPECIALIST_VISIT",
      dr_comments : this.activeData.drComments,
      dr_accompany_patient : "true",
      consultations : this.activeData.visits,
      include_vitals :"false",
      reco_status : "CANCELLED",
     
    };

    this.apiService.putRecomendation(post,Number(this.visitId),Number(this.activeData.id))
    .subscribe(result => {
        this.toastr.success('Specialist Visit Recommendation Canceled');  
        this.loadConsultationSummaries();

        }, error => {
        let edata;
        if(error.apierror){ edata = error.apierror
        } else { edata = error }
        this.toastr.error(edata.message, edata.status);
        }),
        err   => {
        this.toastr.error('500 Internal Server Error!');           
        };

        } else {
        alert('Please select Specilization');
        }
  }


  ngOnInit() {
  	this.loadConsultationSummaries();
  }

}
