import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit, OnDestroy {

  bodyClasses = 'skin-blue sidebar-mini';
  body: HTMLBodyElement = document.getElementsByTagName('body')[0];

  constructor() { }

  ngOnInit() {
    // add the the body classes
     this.body.classList.add('skin-green');
    this.body.classList.add('sidebar-mini');
    this.body.classList.add('sidebar-collapse');
  }

   ngOnDestroy() {
    // remove the the body classes
     this.body.classList.add('skin-green');
    this.body.classList.add('sidebar-mini');
    this.body.classList.add('sidebar-collapse');
  }

}
