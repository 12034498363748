import { Component, ViewChild, OnInit,Output, EventEmitter,Input,NgModule ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef, ChangeDetectorRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule,DomSanitizer,SafeResourceUrl,SafeUrl } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable, combineLatest, Subscription } from 'rxjs';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';


import { saveAs } from 'file-saver/FileSaver';

import { ToastrService } from 'ngx-toastr';
declare var $ :any; 


@Component({
  selector: 'app-labtest-widget',
  templateUrl: './labtest-widget.component.html',
  styleUrls: ['./labtest-widget.component.css']
})
export class LabtestWidgetComponent implements OnInit,OnChanges {

  @ViewChild(ModalDirective) modal: ModalDirective;
  @Output() childEvent = new EventEmitter();

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  @Input() public patientId:any;
  @Input() public visitId: any;
  @Input() public LabTests: any; 
  @Input() public addnew: any;

  labTests: any;
  labOrders: any;
  labDatesMap: any;
  selectedTests: any;

  subscriptions: Subscription[] = [];

  modalRef: BsModalRef;
  modalRef1 : BsModalRef;
  modalRef2 : BsModalRef;
  modalRef3 : BsModalRef;
  modalRef4 : BsModalRef;
  modalRef5 : BsModalRef;
  modalRef6 : BsModalRef;

  addLabTest: FormGroup;
  editLabTest: FormGroup;
  orderByPeritus: FormGroup;
  uploadReports: FormGroup;
  updateOrderStatus: FormGroup;
  minDate: Date;

  orderByEnable:any = true;




  public testName: any;
  public testID: any;
  public frequency: any;
  public selected : any;

  public orderStatus:any;

  public deleteTestId : any;
  public indexValue: any;

  public sc_orderstatus:any;
  public sc_report:any;

  public currentfile:any;
  public currenttestId:any;
  public currentOrderId:any;
  public fileName:any;
  public filetype:any;
  public fileValue:any;
  public imageURL:SafeUrl;
  

  uploadDocument=false;

  public test = {44: "Carboxyhemoglobin", 103: "a1-Globulins", 104: "a2-Globulins", 105: "U+00DF-Globulins", 106: "U+03B3-Globulins", 107: "U+00DF2-Microglobulin", 114: "Haptoglobin", 116: "Hemoglobin", 117: "Hemoglobin Electrophoresis - Hb A1", 118: "Hemoglobin Electrophoresis - Hb A2", 119: " Hemoglobin Electrophoresis - Hb C", 120: "Hemoglobin Electrophoresis - Hb F", 121: "Hemoglobin Electrophoresis - Hb S", 123: "Immunoglobulins, IgA", 124: "Immunoglobulins, IgD", 125: "Immunoglobulins, IgE", 126: "Immunoglobulins IgG", 127: "Immunoglobulins, IgG1", 128: "Immunoglobulins, IgG2", 129: "Immunoglobulins, IgG3", 130: "Immunoglobulins, IgG4", 131: "Immunoglobulins, IgM", 150: "Mean corpuscular hemoglobin (MCH)", 151: "Mean corpuscular hemoglobin concentration (MCHC)", 156: "Methemoglobin", 159: "Myoglobin", 300: "Globulins", 302: "Hemoglobin A1c"}

  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    public toastr: ToastrService,
    private sanitizer:DomSanitizer,
    private changeDetection: ChangeDetectorRef) {

     this.minDate = new Date();

     this.addLabTest = fb.group({
           testName : new FormControl(null, Validators.required),
           testID :new FormControl(null),
           frequency: new FormControl(null, Validators.required)
     });

     this.editLabTest = fb.group({
           testName : new FormControl(null, Validators.required),
           testID :new FormControl(null),
           frequency: new FormControl(null, Validators.required)
     });
     
     this.orderByPeritus = fb.group({
           sc_date : new FormControl(null, Validators.required)
     });

     this.updateOrderStatus = fb.group({
           sc_orderstatus : new FormControl(null, Validators.required)
     });

     this.uploadReports = fb.group({
           sc_report : new FormControl(null, Validators.required)
     });

  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  openModal(template: TemplateRef<any>) {
    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addLabTest.reset();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addLabTest.reset();
        this.unsubscribe();
      })
    );
 
    this.subscriptions.push(_combine);
    this.modalRef = this.modalService.show(template);
  }
  openModal2(template: TemplateRef<any>) {
    this.modalRef4 = this.modalService.show(template);
  }
  openModal3(template: TemplateRef<any>) {
    this.modalRef6 = this.modalService.show(template);
  }

  observableSource = (keyword: any): Observable<any[]> => {
    let url: string = appConfig.apiUrl + '/labTests/search?pattern='+keyword;
    let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
    let options = new RequestOptions({ headers: headers });
    if (keyword) {     
      return this.http.get(url,options)
        .map((res: Response) => { 
           
          return res.json();
        })
    } else {
      return Observable.of([]);
    }
  }

  testSelected(val){
    this.selected = val.testName;
    this.testID = val.id;
    console.log(val);
  }

  updateTest(index,labtest,template: TemplateRef<any>){
      this.modalRef1 = this.modalService.show(template);
      this.selectedTests = labtest;
      console.log(this.selectedTests);
      this.editLabTest.controls['testName'].setValue(labtest.testName);
      this.editLabTest.controls['testID'].setValue(labtest.testId);
      this.editLabTest.controls['frequency'].setValue(labtest.testFreq);
  }

  stopTest(index,id,template: TemplateRef<any>){
      this.modalRef2 = this.modalService.show(template);
      this.deleteTestId = id;
      this.indexValue = index;
  }
  deleteTest(index,id){
    
    this.apiService.stopLabTest(Number(this.visitId),Number(this.deleteTestId))
            .subscribe(result => {
                
                     console.log(result);
                     // this.labTests.splice(this.indexValue, 1);
                     this.modalRef2.hide();
                     this.updateData();
                
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      }; 
  }

  viewReports(id,template: TemplateRef<any>){
      
      this.apiService.getLabTestReports(Number(this.visitId),Number(id))
            .subscribe((result) => {   
            const filename1 = 'test.txt';
            saveAs(result); 
      }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      }; 
  }

  uploadreport(id,orderid,testid,template: TemplateRef<any>){
     this.modalRef5 = this.modalService.show(template);
     this.currenttestId = testid;
     this.currentOrderId = orderid;
  }

  onChange(newValue){
  	if(newValue == 4){
  		this.uploadDocument=true;
  	}else{
  		this.uploadDocument=false;
  	}
  }

  updateData(){
  this.childEvent.emit();
  }

  submitForm(post) {

    post = {
      testMasterId : post.testID,
      testName : post.testName,
      clientId : this.patientId,
      originatingConsultationId : this.visitId,
      testFreq : post.frequency
    }

    this.apiService.postLabTest(post,Number(this.visitId))
            .subscribe(result => {
               
                     console.log(result);
                     this.labTests.push(result);
                     this.modalRef.hide();
                     this.addLabTest.reset();
                     this.toastr.success('Labtest added successfully', 'Successful!');
                    
                     this.updateData();

                
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
    
    console.log('post Data::'+JSON.stringify(post));
  }

  updateForm(post) {

    post = {
      testId :this.selectedTests.testId,
      // testName : this.selectedTests.testName,
      clientId : this.patientId,
      monitorStatus: "ACTIVE",
      originatingConsultationId : this.visitId,
      testFreq : post.frequency
    }

    this.apiService.putLabTest(post,Number(this.visitId),Number(this.selectedTests.testId))
            .subscribe(result => {
               
                     console.log(result);
                     this.labTests.push(result);
                     this.modalRef1.hide();
                     this.addLabTest.reset();
                     this.toastr.success('Labtest updated successfully', 'Successful!');
                    
                     this.updateData();

                
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
    
    console.log('post Data::'+JSON.stringify(post));


  }

  submitForm1(post){
    
    post.sc_date = this.datePipe.transform(post.sc_date, 'dd-MM-yyyy HH:mm');
    let testids = [];
    this.labOrders.forEach( (x,index) => {   
      if(x.status != 'REPORT_READY' || x.status != 'ORDER_PENDING') {
        testids.push(x.testOrderId);
      }
    })
    let date = new Date();
    let date1;
    date1 = this.datePipe.transform(date, 'dd-MM-yyyy');
   

    post = {
      orderBy: "PERITUS",
      orderIds: testids,
      orderDate:date1,
      sampleCollectionDateTime: post.sc_date
    }


    this.apiService.postOrderTests(post,Number(this.visitId))
            .subscribe(result => {
               
                     console.log(result);
                     this.modalRef4.hide();
                     this.orderByPeritus.reset();
                     this.updateData();
                     this.toastr.success('Labtest ordered successfully', 'Successful!');
                    
                
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
    console.log(post);
   
  }

  submitForm2(post){
    let testids ='';
    let count = 0;
    this.labOrders.forEach( (x,index) => {
      if(x.status != 'REPORT_READY'){
        if(count == 0){
            testids = testids + x.testOrderId;
            count = 2;
        } else{
            testids = testids +','+ x.testOrderId
        } 
      } 
    })

    this.apiService.putOrderStatus(testids,post.sc_orderstatus,Number(this.visitId))
            .subscribe(result => {
                
                     console.log(result);
                     this.modalRef6.hide();
                     this.updateOrderStatus.reset();
                     this.updateData();
                     this.toastr.success('Laborders updated successfully', 'Successful!');
                     
                
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
    
  }

  getslectedOrder(orderdate,testids){
    this.apiService.getLabOrders(Number(this.visitId),orderdate,testids)
            .subscribe(result => {
                     console.log(result);
                     this.labOrders = [];
                     this.labOrders = result; 
                     this.toastr.success('Laborders updated successfully', 'Successful!');
                      // alert("LabTest added Successfully");
               
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }



  selectOrders(val){
    console.log(val);
    if( val == 0){
      this.labOrders = [];
      // this.labOrders = this.LabTests.latestOrders;
      if(this.LabTests.pendingOrders.length > 0) {
        this.labOrders = this.LabTests.pendingOrders;
      } else {
        this.labOrders = this.LabTests.latestOrders;
      }
    }else{
      this.labDatesMap.forEach(values =>{
         if(values.orderDate == val){
            console.log(values);
            this.getslectedOrder(values.orderDate,values.testIds)
         }
      });
    }
  }



  onFileChange(event) {
    this.currentfile =  event.target.files;
    console.log(this.currentfile); 
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      
      reader.onload = () => {
        this.fileName = file.name;
        this.filetype = file.type;
        this.fileValue = reader.result.split(',')[1];
        
      };
    }
  }

  submitForm3(post){
    post = this.currentfile.item(0);

    console.log('post Data::'+post); 
    this.apiService.postLapReports(post,Number(this.visitId),Number(this.currenttestId),Number(this.currentOrderId))
            .subscribe(result => {
              this.updateData();
              this.modalRef5.hide();
              this.toastr.success('Report uploaded successfully', 'Successful!');
              return result;
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) { 

    if(this.LabTests){
    this.labTests = this.LabTests.tests;
    }
    let orderdatemap;
    if(this.labTests){
      if(this.LabTests.pendingOrders.length > 0) {
        this.labOrders = this.LabTests.pendingOrders;
        orderdatemap = this.LabTests.testOrders;
      } else {
        this.labOrders = this.LabTests.latestOrders;
        orderdatemap = this.LabTests.testOrders;
      }
      if(this.labOrders.length > 0) {
        this.labOrders.forEach(element => {
            if(element.orderBy == 'SELF' ) {
              this.orderByEnable = false;
            }
        });
      }
      this.labDatesMap = [];
      orderdatemap.forEach(element => {
       let date =  element.orderDate.split(" ");
       date = date[0].split("-");
       element.date = new Date(Number(date[2]) ,Number(date[1])-1, Number(date[0]));
       this.labDatesMap.push(element);
     });
      this.labDatesMap.sort(function(a, b) {
          a = new Date(a.date);
          b = new Date(b.date);
          return a>b ? -1 : a<b ? 1 : 0;
      });
      if(this.labOrders.length > 0){
        this.orderStatus = null;
        this.labOrders.forEach(element => {
            if(element.status == 'ORDER_PENDING'){
              this.orderStatus = 'ORDER_PENDING';
            }
        });
      }
    }
    
    

  }

}
