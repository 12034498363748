import { Component, ViewChild, OnInit ,Input,NgModule ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../../_services/api.service';
import { appConfig } from '../../../../app.config';
import { DatePipe } from '@angular/common';

declare var $ :any;
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-hospitalizations',
  templateUrl: './hospitalizations.component.html',
  styleUrls: ['./hospitalizations.component.css']
})
export class HospitalizationsComponent implements OnInit {

  

  public consultationlist:any;
  public user :any;
  public currentConsultation:any;

  public consultationSummaries : any;
  public activePatient : any;
  public activeSummaries : any;
  public patientDeails : any;
  public patientAddress : any;
  public visitId : any;
  public patientId : any;
  public currentVisitDate : any;
  public currentVisittime = [];
  
  public consultationSession : any;

  public assessments : any;
  public problemSymptoms : any;
  public LabTests : any;
  public diagnosisList : any;
  public examinations : any;
  public media :any;
  public nextVisitDate : any;
  public nonDrugTherapies : any;
  public notes : any;
  public pills : any;
  public recommendations:any;
  public vitalrecordings : any;
  public hasData:boolean = false;
  public visitStatus: any;

  public latestConsultation : any;
  public editenable: any = 0;

  dischargeInfo: FormGroup;

  public description:any;


  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private titleService: Title,
    public toastr: ToastrService,
    private route: ActivatedRoute,) {
     
  	this.titleService.setTitle( 'Hospitalizations');
    this.route.url.subscribe(url =>{
        this.patientId = this.route.snapshot.params['id'];
    });

    this.dischargeInfo = fb.group({ 
      description: [null, Validators.required],
    });
  }

  endVisit(id){
    this.apiService.endVisitSession(id).subscribe(patients => { 
            this.toastr.success("Hospitalization completed successfully");
            this.visitStatus = 'FINISHED';;
            this.loadCurrentConsultationSummary(id);
        }, error => {
           let edata;
           if(error.apierror){ edata = error.apierror
           } else { edata = error }
           this.toastr.error(edata.message, edata.status);
         }),
         err   => {
           this.toastr.error('500 Internal Server Error!');           
       };
 }


  loadConsultations() {
    this.apiService.getPatientConsultationSummary(Number(this.user)).subscribe(consultationlist => { 
      if(consultationlist) {
        consultationlist.forEach(element => {
          if(element.latestConsultation == true) {
            this.latestConsultation = element.visitId;
          }
        });
      }
      this.consultationlist = [];
      consultationlist.forEach(values => {
          if(values.consultationType == 'HOSPITALIZATION' && (values.status == 'FINISHED' || values.status == 'INPROGRESS' )){
            this.consultationlist.push(values);
          }
      });
      if(this.consultationlist.length > 0){
          this.hasData = true;
          this.visitId = this.consultationlist[0].visitId;
          this.visitStatus = this.consultationlist[0].status;
        this.loadCurrentConsultationSummary(this.consultationlist[0].visitId);
      }
    }, error => {
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  loadCurrentConsultationSummary(id){

    this.consultationlist.forEach(element => {
      if(id == element.visitId) {
       if(element.latestConsultation && element.status != 'CANCELLED') {
          this.editenable = 1;
       } else {
          if(this.visitStatus == 'INPROGRESS') {
            this.editenable = 1;
          } else {
            this.editenable = 0;
          }
        }
      }
    });
    this.apiService.getCurrentConsultationSummary(Number(id)).subscribe(consultationSummary => {  
        

        this.activeSummaries = consultationSummary[0];
        this.problemSymptoms = this.activeSummaries.symptomsList;
        this.diagnosisList = this.activeSummaries.diagnosisList;
        this.LabTests = this.activeSummaries.labTests;
        this.consultationSession = this.activeSummaries.consultationSession;
        this.media = this.activeSummaries.media;
        this.pills = this.activeSummaries.pills;
        this.currentVisitDate = this.consultationSession.startTime;
        this.currentVisittime = this.consultationSession.visitDate; 
        this.description = this.activeSummaries.dischargeSummary;
        
    }, error => {
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  updateData(){
    this.loadCurrentConsultationSummary(this.visitId);
  }

  submitForm(post) {
    this.apiService.putDischargeSummary(post.description,Number(this.visitId))
	            .subscribe(result => {     
            			this.toastr.success('Discharge Summary updated successfully', 'Successful!');
	                
	    }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            }; 
  }

  
  selectVisit(id){
    console.log(id);
    this.visitId = id;
    this.consultationlist.forEach(element => {
      if(element.visitId == id) {
        this.visitStatus = element.status;
      }
    });
    this.loadCurrentConsultationSummary(id);
  }

  ngOnInit() {
  	this.user = this.route.snapshot.params['id'];
  	this.loadConsultations();
  }

}
