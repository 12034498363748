﻿import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions,RequestOptionsArgs, Response } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'; 
import 'rxjs/add/observable/throw';
import { HttpClientModule } from '@angular/common/http'; 
import { HttpModule } from '@angular/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { AuthenticationService } from '../_services/index';
import { appConfig } from '../app.config';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ApisService {
    constructor(
        private http: Http,
        private httpclient : HttpClient,
        private authenticationService: AuthenticationService) {
    }

    // Operations Dashboard Service
    getOperationsDashboard(): Observable<any[]> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/dashboard/ROLE_OPS_ADMIN', options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    // Doctor Dashboard Service
    getDoctorDashboard(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/dashboard/ROLE_DOCTOR', options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("Doctor Dashboard")
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }


    // Patients List Service
    getPatients(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/patients', options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    // get patient status service
    getPatientsStatus(search): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/patients/'+search, options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    // get individual patient details service
    getCurrentPatients(id): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/patients/'+id, options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    // get patient last visit history service
    getLastVistHistory(id): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/schedule/'+id, options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("last Visit Results");
                         console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    // Create patient Service
    postPatients(data): Observable<any[]> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(appConfig.apiUrl + '/patients', JSON.stringify(data), options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
            }).catch(this.handleError);
    }

    // update patient details service
    putPatients(data,id): Observable<any[]> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.put(appConfig.apiUrl + '/patients/'+id, JSON.stringify(data), options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
            }).catch(this.handleError);
    }

    // upload media service
    postPatientMedia(post: File,id): Observable<any[]> {
        let formdata: FormData = new FormData();
 
        formdata.append('file', post);
        // formdata.append('clientId',id);
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token  });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/patients/'+id+'/profile', formdata, options)
            .map((response: Response) =>{
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("last Visit Results");
                         console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }


    // get areas service
    getAreas(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/areas', options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    


    // Create Diagnosis service
    postDiagnosis(post,id): Observable<boolean> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+id+'/diagnosis', JSON.stringify(post), options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
            }).catch(this.handleError);
    }


    // Delete Dignosis service
    deleteDiagnosis(visit_id,id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json','Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'DELETE','Access-Control-Allow-Headers': 'X-Requested-With,Content-Type' });
        let options = new RequestOptions({ headers: headers });

        return this.http.delete(appConfig.apiUrl + '/consultationSummary/'+visit_id+'/diagnosis/'+id,options)
            .map((response: Response) =>{
                console.log(response.text());
                if( response.text() == '1'){
                    return true;
                }else{
                    return false;
                }
        }).catch(this.handleError);
    }

    // Create Diagnosis service
    createDignosis(data:any): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(appConfig.apiUrl + '/consultationSummary/1/diagnosis', JSON.stringify(data),options)
            .map((response: Response)  => response.json()).catch(this.handleError);
    }

    // get diagnosis history list service
    getDiagnosisHistory(visitId,num): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/consultationSummary/'+visitId+'/diagnosis/'+num+'/history', options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json()}).catch(this.handleError);
    }

    // creating diagnosis history service
    addDiagnosisHistory(post,visitId,diagnosisId): Observable<any[]>{

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+visitId+'/diagnosis/'+diagnosisId+'/history', JSON.stringify(post), options)
            .map((response: Response) => response.json() ).catch(this.handleError);
    }


    // create problem symptom service
    postProblemSymptom(post,
        consultation_id): Observable<any[]> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });


        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+consultation_id+'/problemSymptom', JSON.stringify(post), options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
            }  ).catch(this.handleError);
    }

    // create problem history service
    addPSHistory(post,visitid,problemId): Observable<any[]>{

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });


        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+visitid+'/problemSymptom/'+problemId+'/history', JSON.stringify(post), options)
            .map((response: Response) => {
                if(response.text() == ""){
                    console.log("No Results Found");
                    let response =[];
                    return response;
                }else{
                    console.log(response.json());
                     return response.json();
                }
            }).catch(this.handleError);
    }

    // problem history list service
    getPSHistory(visitid,num): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
       
        // get users from api
        return this.http.get(appConfig.apiUrl + '/consultationSummary/'+visitid+'/problemSymptom/'+num+'/history', options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json()}).catch(this.handleError);
    }


    // crreate lab test service
    postLabTest(post,id): Observable<any[]> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });


        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+id+'/diagnostics', JSON.stringify(post), options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
            }).catch(this.handleError);
    }

    // update lab test service
    putLabTest(post,id,diagnosisId): Observable<any[]> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.put(appConfig.apiUrl + '/consultationSummary/'+id+'/diagnostics/'+diagnosisId, JSON.stringify(post), options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
            }).catch(this.handleError);
    }

    // stop lab test service
    stopLabTest(id1,id2): Observable<any[]> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.put(appConfig.apiUrl + '/consultationSummary/'+id1+'/diagnostics/'+id2+'/stop','', options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
            }  ).catch(this.handleError);
    }

    // create order test service
    postOrderTests(post,id): Observable<any[]> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });


        return this.http.put(appConfig.apiUrl + '/consultationSummary/'+id+'/diagnostics/orderTests', JSON.stringify(post), options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
            }).catch(this.handleError);
    }

   
    // update order status service
    putOrderStatus(testids,status,id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token , 'Content-Type': 'application/json','Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'PUT','Access-Control-Allow-Headers': 'X-Requested-With,Content-Type' } );
        let options = new RequestOptions({ headers: headers });
        return this.http.put(appConfig.apiUrl + '/consultationSummary/'+id+'/diagnostics/orderStatus/'+testids+'?status='+status,'', options)
            .map((response: Response) => {

                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        //console.log("stratVisit");
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    // getLabTestReports(id1,id2): Observable<any> {
    //     // add authorization header with jwt token
    //     let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,responseType: 'blob'  });
    //     let options = new RequestOptions({ headers: headers});
    //     //let options = new RequestOptions({ headers: headers });
    //     // get users from api
    //     return this.http.get(appConfig.apiUrl + '/consultationSummary/'+id1+'/labTestReport/'+id2+'?base64=false',options)
    //         .map(res => {
                    
    //                      // return new Blob([response.blob()], { type: 'application/pdf' })
    //                     return res;
                    
    //         }).catch(this.handleError);
    // }

    // lab test reports list service
    getLabTestReports(id1,id2): Observable<any> {
        // add authorization header with jwt token
        let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.authenticationService.token,responseType: 'blob'  });
        // get users from api
        return this.httpclient.get(appConfig.apiUrl + '/consultationSummary/'+id1+'/labTestReport/'+id2+'?base64=false',
        {headers, responseType: 'blob'})
            .map(res => {
                    
                         // return new Blob([response.blob()], { type: 'application/pdf' })
                        return res;
                    
            }).catch(this.handleError);
    }

    // images list display service
    getMediaImage(id1,id2): Observable<any> {
        // add authorization header with jwt token
        let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.authenticationService.token,responseType: 'blob'  });
        //let options = new RequestOptionsArgs({ headers: headers});
        //let options = new RequestOptions({ headers: headers });
        // get users from api
        return this.httpclient.get(appConfig.apiUrl + '/consultationSummary/'+id1+'/media/'+id2,
        {headers, responseType: 'blob'})
            .map(res => {
                    
                         // return new Blob([response.blob()], { type: 'application/pdf' })
                        return res;
                    
            }).catch(this.handleError);
    }

 
    // upload lab reports service
    postLapReports(post: File,id,testid,orderid): Observable<any[]> {
        let formdata: FormData = new FormData();
        formdata.append('file', post);
        formdata.append('format', 'PDF');
        formdata.append('testId', testid);
        formdata.append('testOrderId', orderid);
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token  });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+id+'/labTestReport/'+orderid, formdata, options)
            .map((response: Response) =>{
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("last Visit Results");
                         console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }


    // lab orders list service
    getLabOrders(id,orderDate,testIds): Observable<any[]> {
        // let params: URLSearchParams = new URLSearchParams();
        // params.set('orderDate', orderDate);
        // params.set('testIds', testIds);
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token  });
        let options = new RequestOptions({ headers: headers });
        return this.http.get(appConfig.apiUrl + '/consultationSummary/'+id+'/diagnostics/listOrders?orderDate='+orderDate+'&testIds='+testIds,  options)
            .map((response: Response) =>{
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("last Visit Results");
                         console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }


    // examination list service
    getExaminations(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/consultationSummary/examinations', options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json()}).catch(this.handleError);
    }

    // create examination service
    postExaminations(data,id): Observable<any[]> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+id+'/examination', JSON.stringify(data), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    // update examination service
    putExaminations(data,id,exam_id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.put(appConfig.apiUrl + '/consultationSummary/'+id+'/examination/'+exam_id, JSON.stringify(data), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    // delete examination service
    deleteExaminations(visitid,id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json','Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'DELETE','Access-Control-Allow-Headers': 'X-Requested-With,Content-Type' });
        let options = new RequestOptions({ headers: headers });

        return this.http.delete(appConfig.apiUrl + '/consultationSummary/'+visitid+'/examination/'+id,options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
        }).catch(this.handleError);
    }

    // create drug service
    postDrug(drug): Observable<any[]> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(appConfig.apiUrl + '/consultationSummary/drug', JSON.stringify(drug), options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
        }).catch(this.handleError);
    }

    // create assesment service
    postAssessment(post,id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+id+'/assessment', JSON.stringify(post), options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
        }).catch(this.handleError);
    }

    // update assessment service
    putAssessment(post,id,postId): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.put(appConfig.apiUrl + '/consultationSummary/'+id+'/assessment/'+postId, JSON.stringify(post), options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
        }).catch(this.handleError);
    }


    // create medication service
    addMadication(post,id): Observable<any[]>{

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });


        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+id+'/prescription', JSON.stringify(post), options)
            .map((response: Response) => {
                if(response.text() == ""){
                    console.log("No Results Found");
                    let response =[];
                    return response;
                }else{
                    console.log(response.json());
                     return response.json();
                }
            }).catch(this.handleError);
    }

    // update medication service
    putMadication(post,id,madication_id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.put(appConfig.apiUrl + '/consultationSummary/'+id+'/prescription/'+madication_id, JSON.stringify(post), options)
            .map((response: Response) => response.json() 
            ).catch(this.handleError);
    }

    // continue medication service
    continueMadication(post,id,clinetId,data): Observable<any[]>{

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });


        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+clinetId+'/continuePrescription/'+id+'?remarks='+data, JSON.stringify(post), options)
            .map((response: Response) => {
                if(response.text() == ""){
                    console.log("No Results Found");
                    let response =[];
                    return response;
                }else{
                    console.log(response.json());
                     return response.json();
                }
            }).catch(this.handleError);
    }



    // pause medication service
    pauseMadication(drugSchId,resumeOn,interferenceStatus,remarks): Observable<boolean> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        return this.http.put(appConfig.apiUrl + '/consultationSummary/1/prescription/3/interfere', JSON.stringify({
            drugSchId:drugSchId,
            resumeOn:resumeOn,
            interferenceStatus:interferenceStatus,
            remarks:remarks,
        }), options)
            .map((response: Response) => response.json() ).catch(this.handleError);
    }




    // create non drug therapy service
    postNDT(post,id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+id+'/ndt', JSON.stringify(post), options)
            .map((response: Response) => response.json() 
            ).catch(this.handleError);
    }


    // update non drug therapy service
    putNDT(post,id,ndt_id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.put(appConfig.apiUrl + '/consultationSummary/'+id+'/ndt/'+ndt_id, JSON.stringify(post), options)
            .map((response: Response) => response.json() 
            ).catch(this.handleError);
    }

    // pause non drug therapy service
    pauseNDT(post,id,ndt_id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.put(appConfig.apiUrl + '/consultationSummary/'+id+'/prescription/'+ndt_id+'/interfere', JSON.stringify(post), options)
            .map((response: Response) => response.json() 
            ).catch(this.handleError);
    }

    // Vitals list service
    getVitals(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/consultationSummary/track_health/vitals', options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json()}).catch(this.handleError);
    }

    // create vital record service
    postVitalsRecord(post,id): Observable<any[]> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+id+'/track_health', JSON.stringify(post), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    // update vital record service
    putVitalsRecord(post,id,vital_id): Observable<any[]> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.put(appConfig.apiUrl + '/consultationSummary/'+id+'/track_health/'+vital_id, JSON.stringify(post), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    // delete vital record service
    deleteVitalsRecord(visit_id,id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json','Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'DELETE','Access-Control-Allow-Headers': 'X-Requested-With,Content-Type' });
        let options = new RequestOptions({ headers: headers });

        return this.http.delete(appConfig.apiUrl + '/consultationSummary/'+visit_id+'/track_health/'+id,options)
            .map((response: Response) =>{
                console.log(response.text());
                if( response.text() == '1'){
                    return true;
                }else{
                    return false;
                }
        }).catch(this.handleError);
    }

    getActiveVitals(id): Observable<any[]> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.get(appConfig.apiUrl + '/track_health/'+id, options)
            .map((response: Response) =>{
            console.log(response.json());
        return response.json()}).catch(this.handleError);
    }

    recordVitals(clientid,id,post): Observable<any[]> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/track_health/'+clientid+'/'+id, JSON.stringify(post), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }



    getMonitorRecords(id): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/track_health/'+id, options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json()}).catch(this.handleError);
    }


    postTasks(post,id): Observable<any[]> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+id+'/drNotes', JSON.stringify(post), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    putTasks(post,id,task_id): Observable<any[]> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.put(appConfig.apiUrl + '/consultationSummary/'+id+'/drNotes/'+task_id, JSON.stringify(post), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    completeTasks(id): Observable<any[]> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.put(appConfig.apiUrl + '/track_health/actionItems/markClosed/'+id, "", options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    deleteTasks(visit_id,id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json','Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'DELETE','Access-Control-Allow-Headers': 'X-Requested-With,Content-Type' });
        let options = new RequestOptions({ headers: headers });

        return this.http.delete(appConfig.apiUrl + '/consultationSummary/'+visit_id+'/drNotes/'+id,options)
            .map((response: Response) =>{
                console.log(response.text());
                if( response.text() == '1'){
                    return true;
                }else{
                    return false;
                }
        }).catch(this.handleError);
    }

    getMedia(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/consultationSummary/track_health/vitals', options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json()}).catch(this.handleError);
    }


    postMedia(post: File,id,remakrs): Observable<any[]> {
        let formdata: FormData = new FormData();
 
        formdata.append('file', post);
        formdata.append('type', 'PHOTO');
        formdata.append('remarks', remakrs);
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token  });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+id+'/media', formdata, options)
            .map((response: Response) =>{
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("last Visit Results");
                         console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    deleteMedia(visit_id,id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json','Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'DELETE','Access-Control-Allow-Headers': 'X-Requested-With,Content-Type' });
        let options = new RequestOptions({ headers: headers });

        return this.http.delete(appConfig.apiUrl + '/consultationSummary/'+visit_id+'/media/'+id,options)
            .map((response: Response) =>{
                console.log(response.text());
                if( response.text() == '1'){
                    return true;
                }else{
                    return false;
                }
        }).catch(this.handleError);
    }


    getSpecializations(value): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/consultationSummary/specializations?category='+value, options)
            .map((response: Response) =>{
                //console.log(response);
                console.log("specilazations");
                return response.json()}).catch(this.handleError);
    }


    getSpecialists(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/specialist', options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json()}).catch(this.handleError);
    }

    getSpecialist(id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.get(appConfig.apiUrl + '/specialist/5',  options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }


    postSpecialist(data): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/specialist', JSON.stringify(data), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    postAssociateSpecialist(data,clientid,spid): Observable<boolean> {
        // add authorization header with jwt token
        console.log(clientid+','+spid);
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/patients/'+clientid+'/specialist/'+spid, JSON.stringify(data), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    getAssociateSpecialist(id): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/patients/'+id+'/specialist', options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json()}).catch(this.handleError);
    }

    deleteAssociation(clientid,spid): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json','Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'DELETE','Access-Control-Allow-Headers': 'X-Requested-With,Content-Type' });
        let options = new RequestOptions({ headers: headers });

        return this.http.delete(appConfig.apiUrl + '/patients/'+clientid+'/specialist/'+spid,options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
        }).catch(this.handleError);
    }


    getAhspartner(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/ahspartner', options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json()}).catch(this.handleError);
    }

    postAssociateAhspartner(data,clientid,spid): Observable<boolean> {
        // add authorization header with jwt token
        console.log(clientid+','+spid);
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/patients/'+clientid+'/ahspartner/'+spid, JSON.stringify(data), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }


    getAssociateAhspartner(id): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/patients/'+id+'/ahspartner', options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json()}).catch(this.handleError);
    }


    postAhspartner(data): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/ahspartner', JSON.stringify(data), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    deleteAHSAssociation(clientid,spid): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json','Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'DELETE','Access-Control-Allow-Headers': 'X-Requested-With,Content-Type' });
        let options = new RequestOptions({ headers: headers });

        return this.http.delete(appConfig.apiUrl + '/patients/'+clientid+'/ahspartner/'+spid,options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
        }).catch(this.handleError);
    }



    postRecomendation(data,id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/consultationSummary/'+id+'/recommendation', JSON.stringify(data), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    putRecomendation(post,id,rec_id): Observable<any[]> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.put(appConfig.apiUrl + '/consultationSummary/'+id+'/recommendation/'+rec_id, JSON.stringify(post), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }


    deleteRecomendation(visit_id,id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json','Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'DELETE','Access-Control-Allow-Headers': 'X-Requested-With,Content-Type' });
        let options = new RequestOptions({ headers: headers });

        return this.http.delete(appConfig.apiUrl + '/consultationSummary/'+visit_id+'/recommendation/'+id,options)
            .map((response: Response) =>{
                console.log(response.text());
                if( response.text() == '1'){
                    return true;
                }else{
                    return false;
                }
        }).catch(this.handleError);
    }


    getPatientsList(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/patients', options)
            .map((response: Response) =>{
                    console.log(response.text());
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                         return response.json()
                    }
               }).catch(this.handleError);
    }



    getPatners(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/partners', options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    postPatners(data): Observable<any> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(appConfig.apiUrl + '/partners', JSON.stringify(data), options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
            }).catch(this.handleError);
    }

    initiateAdhocVisit(data): Observable<boolean> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(appConfig.apiUrl + '/adhocVisit', JSON.stringify(data), options)
            .map((response: Response) =>{
                if(response.text() == ""){
                    console.log("No Results Found");
                }else{
                    console.log(response.json());
                     return response.json();
                }
            }).catch(this.handleError);
    }


    initiateVisit(data): Observable<boolean> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(appConfig.apiUrl + '/schedule', JSON.stringify(data), options)
            .map((response: Response) =>{
                if(response.text() == ""){
                    console.log("No Results Found");
                }else{
                    console.log(response.json());
                     return response.json();
                }
        }).catch(this.handleError);
    }

    rescheduleVisit(data,id): Observable<boolean> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.put(appConfig.apiUrl + '/schedule/reschedule/'+id, JSON.stringify(data), options)
            .map((response: Response) =>{
                if(response.text() == ""){
                    console.log("No Results Found");
                }else{
                    console.log(response.json());
                     return response.json();
                }
            }).catch(this.handleError);
    }



    getTodaysVisits(date): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/schedule?type=VISIT,ADHOC&status=CONFIRMED&startDate='+date, options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    getPendingVisits(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/schedule?type=VISIT,ADHOC&status=CONFIRMED,APPOINTMENTREQUESTED', options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }


    getWeeklySchedule(val1,val2): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/schedule?type=VISIT,ADHOC&status=CONFIRMED&startDate='+val1+'&endDate='+val2, options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    getPendingCallBacks(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/schedule?type=PHONE&status=CONFIRMED', options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }




    startVisitSession(id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token , 'Content-Type': 'application/json' } );
        let options = new RequestOptions({ headers: headers });
        return this.http.put(appConfig.apiUrl + '/startConsultationSession/'+id,'', options)
            .map((response: Response) => {

                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("stratVisit");
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    endVisitSession(id): Observable<boolean> {
        // add authorization header with jwt token

        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.put(appConfig.apiUrl + '/endConsultationSession/'+id,'', options)
            .map((response: Response) => {

                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("endVisit");
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

   

    cancelVisit(id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json','Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'DELETE','Access-Control-Allow-Headers': 'X-Requested-With,Content-Type' });
        let options = new RequestOptions({ headers: headers });

        return this.http.delete(appConfig.apiUrl + '/schedule/'+id+'?remarks=testing remarks deletion',options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
        }).catch(this.handleError);
    }


    getConsultationSummary(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/schedule?summarySheet=PENDING,INPROGRESS&type=VISIT,ADHOC', options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("ConsultationsummarySheet");
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    getPhoneConsultationSummary(): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/schedule?summarySheet=PENDING,INPROGRESS&type=PHONE', options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("ConsultationsummarySheet");
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    getPatientConsultationSummary(id): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/scheduleSummaries/?clientId='+id, options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("ConsultationsummarySheet");
                        console.log(response.json());
                         return response.json();
                    }
        }).catch(this.handleError);
    }

    getCurrentConsultationSummary(id): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/consultationSummary?consultationIds='+id, options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("ConsultationsummarySheet");
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }


    getCurrentRecommendation(id): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/consultationSummary/'+id+'/recommendations?consultationType=SPECIALIST', options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("ConsultationsummarySheet");
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    getCurrentRecommendation1(id): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/consultationSummary/'+id+'/recommendations?consultationType=CONSULTANT', options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("ConsultationsummarySheet");
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    getPatientClientId(id): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/patients/byPerson/'+id, options)
            .map((response: Response) => {
                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                         return response.json();
                    }
            }).catch(this.handleError);
    }


    getPatientsData(query): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/patients?status='+query, options)
            .map((response: Response) => {

                    if(response.text() == ""){
                        console.log("No Results Found");
                    }else{
                        console.log("data");
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }


    getPackages(id): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });
        // get users from api
        
        return this.http.get(appConfig.apiUrl + '/patients/'+id+'/package', options)
            .map((response: Response) => {

                    if(response.text() == ""){
                        console.log("No Results Found");
                        return null;
                    }else{
                        console.log("data");
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    postPackage(data,id): Observable<boolean> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(appConfig.apiUrl + '/patients/'+id+'/package', JSON.stringify(data), options)
            .map((response: Response) =>{
                    if(response.text() == ""){
                        console.log("No Results Found");
                        return null;
                    }else{
                        console.log("data");
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }

    putPackage(data,id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.put(appConfig.apiUrl + '/patients/'+id+'/package', JSON.stringify(data), options)
            .map((response: Response) =>{
                    if(response.text() == ""){
                        console.log("No Results Found");
                        return null;
                    }else{
                        console.log("data");
                        console.log(response.json());
                         return response.json();
                    }
            }).catch(this.handleError);
    }







    //sponsor APis starts

    


    postSponsor(data,id): Observable<boolean> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/patients/sponsorCarers', JSON.stringify(data), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    postAssociateSponsor(data,clientid,spid): Observable<boolean> {
        // add authorization header with jwt token
        console.log(clientid+','+spid);
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token ,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(appConfig.apiUrl + '/patients/'+clientid+'/sponsorCarers/'+spid, JSON.stringify(data), options)
            .map((response: Response) => response.json() 
        ).catch(this.handleError);
    }

    getAssociateSponsor(clientid): Observable<any[]>{
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
       
        // get users from api
        return this.http.get(appConfig.apiUrl + '/patients/'+clientid+'/sponsorCarers/', options)
            .map((response: Response) =>{
                console.log("associateSponsor");
                console.log(response.json());
                return response.json()}).catch(this.handleError);
    }

    deleteAssociatedSponsor(clientid,spid): Observable<any> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json','Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'DELETE','Access-Control-Allow-Headers': 'X-Requested-With,Content-Type' });
        let options = new RequestOptions({ headers: headers });

        return this.http.delete(appConfig.apiUrl + '/patients/'+clientid+'/sponsorCarers',options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
        }).catch(this.handleError);
    }

    deletePatient(clientid, message): Observable<any> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json','Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'DELETE','Access-Control-Allow-Headers': 'X-Requested-With,Content-Type' });
        let options = new RequestOptions({ headers: headers });

        return this.http.delete(appConfig.apiUrl + '/patients/'+clientid+'?closingComments='+message,options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
        }).catch(this.handleError);
    }

    putDischargeSummary(data,id): Observable<any[]> {

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.put(appConfig.apiUrl + '/consultationSummary/dischargeSummary/'+id+'?remarks='+data, '', options)
            .map((response: Response) =>{
                console.log(response.json());
                return response.json();
            }).catch(this.handleError);
    }



    public handleError = (error: Response) => {
        console.log(error)
        return Observable.throw(error.json())
    }


     //sponsor APis starts







}