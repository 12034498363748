import { Component, ViewChild, OnInit, NgModule, ViewContainerRef, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-consultation-summary-list',
  templateUrl: './consultation-summary-list.component.html',
  styleUrls: ['./consultation-summary-list.component.css']
})
export class ConsultationSummaryListComponent implements OnInit {


  public consultationSummaries: any = [];
  public allconsultationSummaries: any = [];
  public activePatient: any;
  public activeSummaries: any;
  public patientDeails: any;
  public patientAddress: any;
  public visitId: any;
  public patientId: any;
  public currentVisitDate: any;
  public currentVisittime = [];

  public consultationSession: any;

  public assessments: any;
  public problemSymptoms: any;
  public LabTests: any;
  public diagnosisList: any;
  public examinations: any;
  public media: any;
  public nextVisitDate: any;
  public nonDrugTherapies: any;
  public notes: any;
  public pills: any;
  public recommendations: any;
  public vitalrecordings: any;

  public doctorId: any;
  public opsID: any;

  modalRef: BsModalRef;

  assessmentForm: FormGroup;

  validemail: string = 'Please enter the valid email.';
  mobileno: string = 'Please enter valid Phone No';
  titleAlert: string = 'This field is required';

  p: number = 1;
  count = 5;


  constructor(private apiService: ApisService,
    private fb: FormBuilder,
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe, private route: ActivatedRoute,
    private titleService: Title,
    public toastr: ToastrService) {

    if (window.matchMedia('(max-width: 1024px)').matches) {
      this.count = 3;
    } else {
      this.count = 5;
    }

    // setting page  title
    this.titleService.setTitle('Consultation Summary');

    // assessment form group
    this.assessmentForm = fb.group({
      assessment_data: new FormControl(null, Validators.required),
    });


    this.route.url.subscribe(url => {
      this.visitId = this.route.snapshot.params['id'];
      this.loadCurrentConsultationSummary(this.visitId);
      this.loadConsultationSummaries(this.visitId);
    });

  }

  // create are update assessment  function
  submitForm(post) {
    let date = new Date();
    let assess_date = this.datePipe.transform(date, 'dd-MM-yyyy');

    console.log('post Data::' + JSON.stringify(post));
    if (this.assessments[0]) {

      post = {
        assessment: post.assessment_data,
        consultation_id: this.visitId,
        assess_date: assess_date,
        id: this.assessments[0].id
      }

      this.apiService.putAssessment(post, Number(this.visitId), Number(this.assessments[0].id))
        .subscribe(result => {

          this.toastr.success("Assessment updated", "Success");
        }, error => {
          let edata;
          if (error.apierror) {
            edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err => {
          this.toastr.error('500 Internal Server Error!');
        };
    } else {

      post = {
        assessment: post.assessment_data,
        consultation_id: this.visitId,
        assess_date: assess_date
      }

      this.apiService.postAssessment(post, Number(this.visitId))
        .subscribe(result => {
          this.assessments = [];
          this.assessments.push(result);
          this.toastr.success("Assessment created", "Success");
        }, error => {
          let edata;
          if (error.apierror) {
            edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err => {
          this.toastr.error('500 Internal Server Error!');
        };
    }
  }


  //  getting consultation summaries list function
  loadConsultationSummaries(id) {

    this.apiService.getConsultationSummary().subscribe(consultationSummaries => {
      this.allconsultationSummaries = consultationSummaries;
      this.consultationSummaries = [];
      let data = this.allconsultationSummaries;
      for (let i = 0; i < data.length; i++) {

        if (data[i].visitId == id) {
          //alert(id);
          this.activePatient = data[i];
          this.patientDeails = this.activePatient.patientDetails;
          this.doctorId = this.activePatient.doctorDetails.doctorId;
          this.opsID = this.activePatient.doctorDetails.opsMgrId;
          this.patientId = this.patientDeails.patient_id;
          this.patientAddress = this.patientDeails.address;
        } else {
          this.consultationSummaries.push(data[i])
        }
      }

    }, error => {
      let edata;
      if (error.apierror) {
        edata = error.apierror
      } else { edata = error }
      this.toastr.error(edata.message, edata.status);
    }),
      err => {
        this.toastr.error('500 Internal Server Error!');
      };
  }

  updateData() {
    this.loadCurrentConsultationSummary(this.activePatient.visitId);
  }




  // getting current consultation summary details function
  loadCurrentConsultationSummary(id) {
    this.apiService.getCurrentConsultationSummary(Number(id)).subscribe(consultationSummary => {

      this.activeSummaries = consultationSummary[0];
      this.problemSymptoms = this.activeSummaries.symptomsList;
      this.LabTests = this.activeSummaries.labTests;
      this.consultationSession = this.activeSummaries.consultationSession;
      this.assessments = this.activeSummaries.assessments;

      this.diagnosisList = this.activeSummaries.diagnosisList;
      this.examinations = this.activeSummaries.examinations;
      this.media = this.activeSummaries.media;
      this.nextVisitDate = this.activeSummaries.nextVisitDate;
      this.nonDrugTherapies = this.activeSummaries.nonDrugTherapies;
      this.notes = this.activeSummaries.notes;
      this.pills = this.activeSummaries.pills;
      this.recommendations = this.activeSummaries.recommendations;
      this.vitalrecordings = this.activeSummaries.vitalrecordings;

      this.currentVisitDate = this.consultationSession.startTime;
      this.currentVisittime = this.consultationSession.visitDate;

      console.log("time" + this.currentVisittime);
      if (this.assessments[0]) {
        this.assessmentForm.controls['assessment_data'].setValue(this.assessments[0].assessment);
      }

    });
  }

  // complite visit function
  endVisit(id) {
    this.apiService.endVisitSession(id).subscribe(patients => {
      this.toastr.success("Consultation successfully completed", "Success");
      this.router.navigate(['/consultation-summary/']);
    }, error => {
      let edata;
      if (error.apierror) {
        edata = error.apierror
      } else { edata = error }
      this.toastr.error(edata.message, edata.status);
    }),
      err => {
        this.toastr.error('500 Internal Server Error!');
      };
  }



  ngOnInit() {

  }

}
