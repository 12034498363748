import { Component, ViewChild, OnInit ,Input,NgModule ,OnChanges,SimpleChanges ,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule,DomSanitizer, SafeHtml  } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../_services/api.service';
import { appConfig } from '../../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

declare var $ :any;

@Component({
  selector: 'app-new-patient',
  templateUrl: './new-patient.component.html',
  styleUrls: ['./new-patient.component.css']
})
export class NewPatientComponent implements OnInit {

	@ViewChild(ModalDirective) modal: ModalDirective;

  	public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  	public areas:any;

	updatePersonalDetails: FormGroup;

	modalRef: BsModalRef;

	public patientFirstName : any;
	public patientLastName : any;
	public patientGender : any;
	public patientDOB : any;
	public patientImage : any;

	public paHouseNo : any;
	public plotcColony : any;
	public area : any;
	public areaId : any;
	public paCity : any;
	public paPin : any;
	public paState : any;
	public paCountry : any;

	public paPhoneType : any;
	public paPhoneNo : any;

	public paEmail : any;

	public paInFindings : any;
	public icdCode : any;
	public diagnosis_master_id : any;
	public paSince : any;

	currentfile:any;
	fileName:any;
	filetype:any;
	fileValue:any;

	profileImg:any;

	public paRemarks : any;

	paAddresses: any[] = [];
	paPhonenos: any[] = [];
	paEmails: any[] = [];
	paFindings: any[] = [];

	createdUser:any[] = [];

	datepickerModel: Date;
	maxDate: Date;


	validemail : string ='Please enter the valid email.';
  	mobileno : string ='Please enter valid Phone No';
  	titleAlert : string = 'Please enter valid details';

  	 public count  : any;
  	 public count1 : any;
  	 public count2 : any;
  	 public count3 : any;


	constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private _sanitizer: DomSanitizer,
    private titleService: Title,
    public toastr: ToastrService){

		this.titleService.setTitle( 'Create Client' );
		this.maxDate = new Date();

		this.updatePersonalDetails = fb.group({

		 patientFirstName: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
		 patientLastName: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
		 patientGender: new FormControl(null, Validators.required),
		 patientDOB: new FormControl(null, Validators.required),
		 patientImage:new FormControl(null),
		 paRemarks:new FormControl(null),

		 paAddresses: this.fb.array([this.createAddress()]),
		 paPhonenos: this.fb.array([this.createPhoneno()]),
		 paEmails: this.fb.array([this.createEmail()]),
		 paFindings: this.fb.array([this.createFindings()]),
     
    	});
    	 
	 	
	}

	openModal(template: TemplateRef<any>) {
	    this.modalRef = this.modalService.show(template);
	}

	createAddress() {
		return this.fb.group({
		  paHouseNo : new FormControl('', [Validators.required, Validators.maxLength(50)]),
		  plotcColony : new FormControl('', [Validators.required, Validators.maxLength(100)]),
		  area : new FormControl('', Validators.required),
		  areaId : new FormControl(''),
		  paCity : new FormControl('', Validators.required),
		  paPin :new FormControl('',[Validators.required,
		  					Validators.minLength(6),
     						Validators.maxLength(6),
      						Validators.pattern('[0-9]+')]),
		  paState : new FormControl('', Validators.required),
		  paCountry : new FormControl('India', Validators.required)
		})

	}

	addNewAddress() {  
	    this.count = this.count + 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paAddresses`);
	    arr.push(this.createAddress());    
	}

	removeNewAddress(index){
	    this.count = this.count - 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paAddresses`);
	    arr.removeAt(index);
	}



	createPhoneno() {
		return this.fb.group({
		  paPhoneType : new FormControl('', Validators.required),
		  paPhoneNo : new FormControl('',  [Validators.required,
		  					Validators.minLength(10),
     						Validators.maxLength(10),
      						Validators.pattern('[0-9]+')
    				   ]),
		})
	}

	addPhoneno() {  
	    this.count1 = this.count1 + 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paPhonenos`);
	    arr.push(this.createPhoneno());    
	}

	removePhoneno(index){
	    this.count1 = this.count1 - 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paPhonenos`);
	    arr.removeAt(index);
	}


	createEmail() {
		return this.fb.group({
		  paEmail :  new FormControl('', Validators.required),
		})
	}

	addEmail() {  
	    this.count2 = this.count2 + 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paEmails`);
	    arr.push(this.createEmail());    
	}

	removeEmail(index){
	    this.count2 = this.count2 - 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paEmails`);
	    arr.removeAt(index);
	}






	createFindings() {
		return this.fb.group({
		  diagnosisDesc : new FormControl(''),
		  icdCode : new FormControl(''),
		  diagnosis_master_id : new FormControl(''),
		  paSince : new FormControl(''),
		})
	}

	addFinding() {  
	    this.count3 = this.count3 + 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paFindings`);
	    arr.push(this.createFindings());    
	}

	removeFinding(index){
	    this.count3 = this.count3 - 1; 
	    const arr = <FormArray>this.updatePersonalDetails.get(`paFindings`);
	    arr.removeAt(index);
	}



	areaSelected(val,i){
		console.log(val);
		const control = this.updatePersonalDetails.get(`paAddresses.${i}`)['controls'];
		control.area.setValue(val.area);
		control.areaId.setValue(val.id);
		control.paCity.setValue(val.cityDistrict);
		control.paPin.setValue(val.pin);
		control.paState.setValue(val.state);
	}

	// public formValues:any;
	public num:any = 0;
	

	submitdata() {

		 
		let post = JSON.parse(JSON.stringify(this.updatePersonalDetails.value));
		  
		
		
	    // this.formValues = Object.assign({}, this.updatePersonalDetails.value) ;

		
		
		console.log(this.updatePersonalDetails.value);
		//console.log(post);
		post.patientDOB = this.datePipe.transform(post.patientDOB, 'dd-MM-yyyy');
		let contacts:any;
		let j = post.paAddresses.length;
		for (let i = 0; i < j; i++) {
			let current_address;
			if( i == 0){
				current_address = true;
			} else {
				current_address = false;
			}
		  let firstArray = {
		  	plotFlatNo : post.paAddresses[i].paHouseNo, 
		  	streetColony : post.paAddresses[i].plotcColony,
		  	areaId : post.paAddresses[i].areaId,
			addrType : "RESIDENCE",
		    currentAddress: current_address,};
			post.paAddresses[i] = firstArray;
		}
		let l = post.paPhonenos.length;
		for (let i = 0; i < l; i++) {
			let primary_contact;
			if( i == 0){
				primary_contact = true;
			} else {
				primary_contact = false;
			}
		  	let firstArray = {
		  	type : post.paPhonenos[i].paPhoneType, 
		  	value : post.paPhonenos[i].paPhoneNo,
		  	primary_contact : primary_contact
		  	};
		  	post.paPhonenos[i] = firstArray;
		}

		let k = post.paEmails.length;
		 for (let i = 0; i < k; i++) {
		  	let firstArray = {
		  	type : "EMAIL", 
		  	value : post.paEmails[i].paEmail,
		  	primary_contact : false
		  };
		  post.paPhonenos.push(firstArray);
		}

		let findings = [];
		let p = post.paFindings.length;
		for(let i = 0; i < p; i++){
			if( post.paFindings[i].icdCode != '' ) {
				post.paFindings[i].paSince = this.datePipe.transform(post.paFindings[i].paSince, 'dd-MM-yyyy');
				let data = {
					predated : true,
					disease : post.paFindings[i].diagnosisDesc, 
					icd_code : post.paFindings[i].icdCode,
					diagnosis_master_id : post.paFindings[i].diagnosis_master_id,
					disease_since : post.paFindings[i].paSince,
				};
			   findings.push(data);
			}
		}

		
		post = { clientStatus :  null,
				 diseaseProfile : findings,
				 drInitialComments:post.paRemarks,
			     personalDetails :
			     {
			         firstName :  post.patientFirstName,
			         lastName :  post.patientLastName,
			         gender :  post.patientGender,
			         dob :  post.patientDOB,
			         addresses :post.paAddresses,
			         contacts : post.paPhonenos

			    }
		}

			


		this.apiService.postPatients(post)
	 	           .subscribe(result => {
	 	              
	 	                    this.toastr.success('User Created Successfully', 'Congrats!');
		                    this.createdUser = result;
		                    this.uploadImage(this.createdUser);
		                    this.updatePersonalDetails.reset();
		                    
	 	              
			    }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            }; 

	  console.log(post);
	}

	uploadImage(userid){
		let image = this.currentfile.item(0);
	    this.apiService.postPatientMedia(image,Number(userid.personalDetails.id))
           .subscribe(result => {
               if (result != null) {
                    console.log(result);
                    this.toastr.success('upload profile image Successfully', 'Congrats!');
               // this.Message = "Specialist Created Successfully"
               } else {
	                  this.toastr.error('Some thing went wrong', 'Sorry!');
	                }
	    }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            };  
	}

	
	onFileChange(event) {
	    this.currentfile =  event.target.files;
	    console.log(this.currentfile);
	    console.log(this.currentfile.item(0));
	    
	}

	loadAreas() {	
        this.apiService.getAreas().subscribe(areas => { 
          this.areas = areas;
          this.areas = this.areas.areas;
          console.log(this.areas);
        });
  	}

  	autocompleListFormatter = (data: any) : SafeHtml => {
	    let html = `<span>${data.area}</span>`;
	    return this._sanitizer.bypassSecurityTrustHtml(html);
	}

	observableSource = (keyword: any): Observable<any[]> => {
	    let url: string = appConfig.apiUrl + '/consultationSummary/diagnosis/search?pattern='+keyword;
	    let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
	    let options = new RequestOptions({ headers: headers });
	    if (keyword) {     
	      return this.http.get(url,options)
	        .map((res: Response) => {
	        	console.log(res.json())
	        	return res.json() 
	        })
	        
	    } else {
	      return Observable.of([]);
	    }
	}


	diagnosisSelected(val,i){  
	    console.log(val);
	    const control = this.updatePersonalDetails.get(`paFindings.${i}`)['controls'];
		control.diagnosisDesc.setValue(val.diagnosisDesc);
		control.icdCode.setValue(val.icdCode);
		control.diagnosis_master_id.setValue(val.id);
	}


	
	
	ngOnInit() {
		this.loadAreas();
	}
}
