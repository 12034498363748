import { Component, OnInit, ViewContainerRef,OnChanges,SimpleChanges } from '@angular/core';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { AbstractControl,FormArray,ReactiveFormsModule, FormBuilder, FormGroup, FormControl, Validators, FormsModule ,NgForm  } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import {  ViewChild ,NgModule,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var $ :any; 

@Component({
  selector: 'app-doctor-dashboard',
  templateUrl: './doctor-dashboard.component.html',
  styleUrls: ['./doctor-dashboard.component.css']
})
export class DoctorDashboardComponent implements OnInit {

  users:any;

  allData:any;

  public callbackRequested:any = [];
  public vitalMonitoring:any = [];
  public pendingVisit:any = [];
  public packageExp:any = [];
  public visitSummaryPending:any = [];
  public todaysVisits:any = [];
  public testordersavilable:any = [];
  public hospatalized:any = [];
  public actionItems:any = [];

  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private titleService: Title,
    public toastr: ToastrService) {

    this.titleService.setTitle( 'Doctor Dashboard' );
  }

  ngOnInit() {
    this.loadDashboard();
  }

  loadDashboard() {
        this.apiService.getDoctorDashboard().subscribe(patients => { 
           this.allData = patients;
           this.filterData(this.allData);
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            if(edata.status == 'INTERNAL_SERVER_ERROR') {
              this.toastr.error(edata.message, edata.status);
            }
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }

  ActionItem(item) {
    localStorage.removeItem('homedata'); 
    localStorage.setItem('homedata', JSON.stringify(item));
    if(item.consultationType == 'VISIT' || item.consultationType == 'ADHOC') {
        this.router.navigate(['/patient/'+item.clientId+'/doctor-visits/']);
    } else if (item.consultationType == 'PHONE'){
        this.router.navigate(['/patient/'+item.clientId+'/doctor-callbacks/']);
    } else if (item.consultationType == 'SPECIALIST'){
        this.router.navigate(['/patient/'+item.clientId+'/specialist-visits/']);
    } else if (item.consultationType == 'HOSPITALIZATION'){
        this.router.navigate(['/patient/'+item.clientId+'/hospitalizations/']);
    }
  }

  


  filterData(data){
      console.log(data);
      this.callbackRequested = [];
      this.vitalMonitoring = [];
      this.pendingVisit = [];
      this.packageExp = [];
      this.visitSummaryPending = [];
      this.todaysVisits = [];
      this.testordersavilable = [];
      this.hospatalized = [];
      if( data.aiNotes ){
        this.actionItems = data.aiNotes;
      }
      data.dashPanels.forEach(element => {
         element.participations.forEach(element1 => {
             if(element1  ==  'CALLBACKSREQUESTED'){
                 this.callbackRequested.push(element);
             }
             if(element1  ==  'VITALMONITORED'){
                 this.vitalMonitoring.push(element);
             }
             if(element1  ==  'TODAYVISITCONFIRMED'){
                 this.todaysVisits.push(element);
             }
             if(element1  ==  'PKGEXPIRED'){
                 this.packageExp.push(element);
             }
             if(element1  ==  'INCOMPLETEVISITSUMMARYSHEET'){
                 this.visitSummaryPending.push(element);
             }
             if(element1 == 'TESTSORDERSAVAILABLE'){
                 this.testordersavilable.push(element);
             }
             if(element1 == 'HOSPITALIZED'){
                 this.hospatalized.push(element);
             }
             
             



             

         })
      })
  }
  
 

}
