import { Component, OnInit, ViewContainerRef,OnChanges,SimpleChanges } from '@angular/core';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { AbstractControl,FormArray,ReactiveFormsModule, FormBuilder, FormGroup, FormControl, Validators, FormsModule ,NgForm  } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import {  ViewChild ,NgModule,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
declare var $ :any; 

@Component({
  selector: 'app-todays-visit-schedule',
  templateUrl: './todays-visit-schedule.component.html',
  styleUrls: ['./todays-visit-schedule.component.css']
})
export class TodaysVisitScheduleComponent implements OnInit {

  public patients : any;
  public allVisits: any = [];
  public activePatient : any;
  public todaysVisits : any = [];

  public currentVisit : any;
  public currentPatient : any;
  public currentVisitAddress : any;
  public currentVisitDate : any;
  public currentVisittime = [];
  public labtests : any;
  public lastVisitDetails : any;
  public media : any;
  public visitId : any;
  public problemSymptoms : any;
  public patientId:any;


  
  public timeBtn1 : boolean;
  public timeBtn2 : boolean;
  public timeValue1 : boolean;
  public timeValue2 : boolean;

  public startTime : any;
  public endTime :any;
  public hasData:boolean = false;

  p: number = 1;
  count = 5;

  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,private route: ActivatedRoute,
    public toastr: ToastrService,private titleService: Title) {

      if (window.matchMedia('(max-width: 1024px)').matches) {
        this.count = 3;
      } else {
        this.count = 5;
      }
     
    this.titleService.setTitle( 'Schedule Visits' );

  	this.timeBtn1 = true;
  	this.timeBtn2 = false;
  	this.timeValue1 = false;
  	this.timeValue2 = false;
    this.loadTodaysVisits();
  }

  // loadPatients() {
  //       this.apiService.getPatients().subscribe(patients => { 
  //         this.patients = patients;
  //         // this.activePatient = patients[0];
  //         this.activePatient = patients[0];
  //       });
  // }

  loadTodaysVisits() {
  	let date = new Date();
 		let todaydate = this.datePipe.transform(date, 'dd-MM-yyyy');
        this.apiService.getTodaysVisits(todaydate).subscribe(todaysVisits => { 
          this.allVisits = todaysVisits;

          let j = this.allVisits;
          for(let i = 0; i < j.length; i++){
              if(i != 0){
                this.todaysVisits.push(this.allVisits[i]);
              }
          }
          if(this.allVisits.length > 0){
            this.hasData = true;
            this.currentVisit = this.allVisits[0];
            this.currentPatient = this.currentVisit.patientDetails;
            this.patientId = this.currentPatient.patient_id;
            this.currentVisitAddress = this.currentVisit.patientDetails.address;
            this.currentVisitDate = this.currentVisit.visitSchedule.date;
            this.currentVisittime = this.currentVisit.visitSchedule.time;
            this.visitId = this.currentVisit.visitId;
            this.labtests = this.currentVisit.labTests;
            this.media = this.currentVisit.media;
            this.problemSymptoms = this.currentVisit.problemSymptoms;
          }

        }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            };
  }


  // selectClient(id){
  //     let i = this.patients;
  //     for(let j = 0; j < i.length; j++){
  //       if(i[j].client_id == id){
  //         this.activePatient = i[j];
  //       }
  //     }
  // }

  startVisit(id){
  		this.apiService.startVisitSession(id).subscribe(patients => { 
    	     	
    	     		let time = new Date();
 		          this.startTime = this.datePipe.transform(time, 'HH:mm');
 		          //alert(this.startTime +','+ id);
 		          this.timeBtn1 = !this.timeBtn1;
 		          this.timeBtn2 = !this.timeBtn2;
 		          this.timeValue1 = !this.timeValue1;
              this.router.navigate(['/consultation-summary/'+id]);

    	    
         }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            };
  }

  endVisit(id){
 		this.apiService.endVisitSession(id).subscribe(patients => { 
    	     	
    	     		let time = new Date();
           		this.endTime = this.datePipe.transform(time, 'HH:mm');
           		this.timeBtn2 = !this.timeBtn2;
           		this.timeValue2 = !this.timeValue2;

         }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            };
  }



  ngOnInit() {
  	
  }

}
