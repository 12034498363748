import { BrowserModule,Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { Ng2CompleterModule } from "ng2-completer";
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import {NgPipesModule} from 'ngx-pipes';
import { AuthGuard } from './../_guards/index';
import { JwtInterceptorProvider, ErrorInterceptorProvider } from './../_helpers/index';
import { AlertService, AuthenticationService, UserService } from './../_services/index';
import {NgxPaginationModule} from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { mainRouting } from './main.routing';
import { PatientModule } from './patient/patient.module';
import { SharedModule } from './../shared/shared.module';
import { MainComponent } from './main.component';
import { HeaderComponent } from './../shared/header/header.component';
import { FooterComponent } from './../shared/footer/footer.component';
import { LeftSidebarComponent } from './../shared/left-sidebar/left-sidebar.component';
import { DoctorDashboardComponent } from './doctor-dashboard/doctor-dashboard.component';
import { OperatorDashboardComponent } from './operator-dashboard/operator-dashboard.component';
import { TodaysVisitScheduleComponent } from './todays-visit-schedule/todays-visit-schedule.component';
import { MonitorHealthComponent } from './monitor-health/monitor-health.component';
import { DiagnosticsComponent } from './diagnostics/diagnostics.component';
import { DoctorWeeklyScheduleModule } from './doctor-weekly-schedule/doctor-weekly-schedule.module';


import { InitiateVisitComponent } from './visits/initiate-visit/initiate-visit.component';
import { InitiateSpecialistVisitComponent } from './visits/initiate-specialist-visit/initiate-specialist-visit.component';
import { InitiateConsultantVisitComponent } from './visits/initiate-consultant-visit/initiate-consultant-visit.component';
import { InitiateAdhocVisitComponent } from './visits/initiate-adhoc-visit/initiate-adhoc-visit.component';


import { DoctorClinicalLabComponent } from './doctor-consultation-summary/clinical-lab/doctor-clinical-lab.component';
import { DoctorDiagnosticsSymptomsComponent } from './doctor-consultation-summary/diagnostics-symptoms/doctor-diagnostics-symptoms.component';
import { DoctorMonitorTreatmentComponent } from './doctor-consultation-summary/monitor-treatment/doctor-monitor-treatment.component';
import { DoctorRecommedationsComponent } from './doctor-consultation-summary/recommedations/doctor-recommedations.component';
import { DoctorTreatmentComponent } from './doctor-consultation-summary/treatment/doctor-treatment.component';

import { PhoneSummarySheetComponent } from './phone-summary-sheet/phone-summary-sheet.component';
import { CallbackRequestsComponent } from './callback-requests/callback-requests.component';
import { PendingVistConfirmationComponent } from './pending-vist-confirmation/pending-vist-confirmation.component';


import { PartnersComponent } from './partners/partners.component';
import { PartnerFromComponent } from './partner-from/partner-from.component';
import { ConsultationSummaryComponent } from './consultation-summary/consultation-summary.component';
import { ScheduledVisitComponent } from './scheduled-visit/scheduled-visit.component';
import { ConsultationSummaryListComponent } from './consultation-summary-list/consultation-summary-list.component';
import { PendingVisitsComponent } from './pending-visits/pending-visits.component';
import { PartnerEditComponent } from './partner-edit/partner-edit.component';
import { MonitorHealthListComponent } from './monitor-health-list/monitor-health-list.component';
import { DiagnosticsListComponent } from './diagnostics-list/diagnostics-list.component';
import { CallbackRequestsListComponent } from './callback-requests-list/callback-requests-list.component';
import { PhoneSummarySheetListComponent } from './phone-summary-sheet-list/phone-summary-sheet-list.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    BrowserAnimationsModule,
    HttpClientModule,
    mainRouting,
    SharedModule,
    DoctorWeeklyScheduleModule,
    PatientModule,
    Ng2CompleterModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NguiAutoCompleteModule,
    TabsModule.forRoot(),
    NgPipesModule,
    NgxPaginationModule,
    Ng2SearchPipeModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    LeftSidebarComponent,
    MainComponent,
    DoctorDashboardComponent,
    OperatorDashboardComponent,
    TodaysVisitScheduleComponent,
    MonitorHealthComponent,
    DiagnosticsComponent,
    InitiateVisitComponent,
    InitiateSpecialistVisitComponent,
    InitiateConsultantVisitComponent,
    InitiateAdhocVisitComponent,
    DoctorClinicalLabComponent,
    DoctorDiagnosticsSymptomsComponent,
    DoctorMonitorTreatmentComponent,
    DoctorRecommedationsComponent,
    DoctorTreatmentComponent,
    PhoneSummarySheetComponent,
    CallbackRequestsComponent,
    PendingVistConfirmationComponent,
    PartnersComponent,
    PartnerFromComponent,
    ConsultationSummaryComponent,
    ScheduledVisitComponent,
    ConsultationSummaryListComponent,
    PendingVisitsComponent,
    PartnerEditComponent,
    MonitorHealthListComponent,
    DiagnosticsListComponent,
    CallbackRequestsListComponent,
    PhoneSummarySheetListComponent

  ],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    JwtInterceptorProvider,
    ErrorInterceptorProvider,
    Title
  ]
})
export class MainModule { }
