﻿import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { JwtHelper } from 'angular2-jwt';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { appConfig } from '../app.config';

@Injectable()
export class AuthenticationService {

    public token: string;
    public userrole: string;

    jwtHelper: JwtHelper = new JwtHelper();

    constructor(private http: Http) {
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }

    login(username: string, password: string): Observable<boolean> {
        return this.http.post(appConfig.apiUrl + '/login', JSON.stringify({username: username,password: password}))
            .map((response: Response) => {
                // login successful if there's a jwt token in the response

                let token = response.text();

                if (token) {
                    // set token property
                    this.token = token;
                    this.userrole = this.jwtHelper.decodeToken(token).roles;
                   
                    // store username and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify({ username: username, token: token,userrole:this.userrole }));

                    // return true to indicate successful login
                    return true;
                } else {
                    // return false to indicate failed login
                    return false;
                }
            }).catch(this.handleError)
    }
   
    public handleError = (error: Response) => {
        console.log(error);
        // Do messaging and error handling here
        return Observable.throw(error.json())
    }
    
    

    logout(): void {
        // clear token remove user from local storage to log user out
        this.token = null;
        localStorage.removeItem('currentUser');
    }
}