import { Component, ViewChild, OnInit ,NgModule ,  ViewContainerRef,TemplateRef ,Input ,OnChanges,SimpleChanges  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var $ :any; 


@Component({
  selector: 'app-phone-summary-sheet',
  templateUrl: './phone-summary-sheet.component.html',
  styleUrls: ['./phone-summary-sheet.component.css']
})
export class PhoneSummarySheetComponent implements OnInit {

  public consultationSummaries : any;
  public allconsultationSummaries : any;
  public activePatient : any;
  public activeSummaries : any;
  public patientDeails : any;
  public patientAddress : any;
  public visitId : any;
  public patientId : any;
  public currentVisitDate : any;
  public currentVisittime = [];
  
  public consultationSession : any;

  public problemSymptoms : any;
  public LabTests : any;
  public diagnosisList : any;
  public nextVisitDate : any;
  public pills : any;

  public hasData:boolean = false;

  modalRef: BsModalRef;

  assessmentForm: FormGroup;

  validemail : string ='Please enter the valid email.';
  mobileno : string ='Please enter valid Phone No';
  titleAlert : string = 'This field is required';

  p: number = 1;
  count = 5;


  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,private route: ActivatedRoute,
    private titleService: Title,
    public toastr: ToastrService) {

      if (window.matchMedia('(max-width: 1024px)').matches) {
        this.count = 3;
      } else {
        this.count = 5;
      }

     
        this.titleService.setTitle( 'Phone Consultation Summary' );
        this.assessmentForm = fb.group({
           assessment_data : new FormControl(null, Validators.required),
        });
   }

   submitForm(post) {
      
    
      
    }


  loadConsultationSummaries() {
    this.apiService.getPhoneConsultationSummary().subscribe(consultationSummaries => { 
        this.allconsultationSummaries = consultationSummaries
        this.consultationSummaries = [];
        if(this.allconsultationSummaries.length > 0){
          this.hasData = true;
          let j = this.allconsultationSummaries;
          for(let i = 0; i < j.length; i++){
              if(i != 0){
                this.consultationSummaries.push(this.allconsultationSummaries[i]);
              }
          }
          this.activePatient = this.allconsultationSummaries[0];
          this.visitId  = this.activePatient.visitId;
          this.patientDeails = this.activePatient.patientDetails;
          this.patientId = this.patientDeails.patient_id;
          this.patientAddress = this.patientDeails.address;
          this.loadCurrentConsultationSummary(this.activePatient.visitId);
        }
        
    }, error => {
        let edata;
        if(error.apierror){ edata = error.apierror
        } else { edata = error }
        this.toastr.error(edata.message, edata.status);
      }),
      err   => {
        this.toastr.error('500 Internal Server Error!');           
    };
  }
  loadCurrentConsultationSummary(id){
    this.apiService.getCurrentConsultationSummary(Number(id)).subscribe(consultationSummary => {  
        
        this.activeSummaries = consultationSummary[0];
        this.problemSymptoms = this.activeSummaries.symptomsList;
        this.LabTests = this.activeSummaries.labTests;
        this.consultationSession = this.activeSummaries.consultationSession;
        

        this.diagnosisList = this.activeSummaries.diagnosisList;
        this.pills = this.activeSummaries.pills;
        this.nextVisitDate = this.activeSummaries.nextVisitDate;

        this.currentVisitDate = this.consultationSession.startTime;
        this.currentVisittime = this.consultationSession.visitDate; 

        console.log("time" + this.currentVisittime);
        
    }, error => {
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

   endVisit(id){
     this.apiService.endVisitSession(id).subscribe(patients => { 
            
                 this.hasData = false;
                 this.loadConsultationSummaries();
             
         }, error => {
                let edata;
                if(error.apierror){ edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.message, edata.status);
              }),
              err   => {
                this.toastr.error('500 Internal Server Error!');           
            };
  }

  updateData(){
    
    this.loadCurrentConsultationSummary(this.visitId);
  }

  

  ngOnInit() {
  	this.loadConsultationSummaries();
  }
}
