import { Component, OnInit ,NgModule ,Injectable,  ViewContainerRef }  from '@angular/core';
import { CommonModule,DatePipe } from '@angular/common';  
import { FormsModule,ReactiveFormsModule,FormArray, FormBuilder, FormGroup, FormControl, Validators }   from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { ModalModule } from 'ngx-bootstrap/modal';
import { Ng2CompleterModule } from "ng2-completer";
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { FileUploaderModule } from "ng4-file-upload";
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { MadicationComponent } from './madication/madication.component';
import { NonDrugTherapyComponent } from './non-drug-therapy/non-drug-therapy.component';
import { DiagnosisWidgetComponent } from './diagnosis-widget/diagnosis-widget.component';
import { ProblemSymptomWidgetComponent } from './problem-symptom-widget/problem-symptom-widget.component';
import { ExaminationWidgetComponent } from './examination-widget/examination-widget.component';
import { LabtestWidgetComponent } from './labtest-widget/labtest-widget.component';
import { VitalTrackingWidgetComponent } from './vital-tracking-widget/vital-tracking-widget.component';
import { ActionItemWidgetComponent } from './action-item-widget/action-item-widget.component';
import { ConsultantVisitsWidgetComponent } from './consultant-visits-widget/consultant-visits-widget.component';
import { SpecialistVisitsWidgetComponent } from './specialist-visits-widget/specialist-visits-widget.component';
import { HospitalizationWidgetComponent } from './hospitalization-widget/hospitalization-widget.component';
import { UsersWidgetComponent } from './users-widget/users-widget.component';
import { MediaComponent } from './media/media.component';


import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http'; 
import { VitalNontrackingWidgetComponent } from './vital-nontracking-widget/vital-nontracking-widget.component';

@NgModule({
  imports: [ 
  BrowserModule, 
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  BsDatepickerModule.forRoot(),
  ModalModule.forRoot(),
  FileUploaderModule,
  NguiAutoCompleteModule,
  PdfViewerModule,
  BrowserAnimationsModule],
  providers: [CommonModule,Title,DatePipe],
  declarations: [MadicationComponent, NonDrugTherapyComponent, DiagnosisWidgetComponent, ProblemSymptomWidgetComponent, ExaminationWidgetComponent, LabtestWidgetComponent, VitalTrackingWidgetComponent, ActionItemWidgetComponent, ConsultantVisitsWidgetComponent, SpecialistVisitsWidgetComponent, HospitalizationWidgetComponent, UsersWidgetComponent, MediaComponent, VitalNontrackingWidgetComponent],
  exports: [MadicationComponent, NonDrugTherapyComponent, DiagnosisWidgetComponent, ProblemSymptomWidgetComponent, ExaminationWidgetComponent, LabtestWidgetComponent, VitalTrackingWidgetComponent, ActionItemWidgetComponent, ConsultantVisitsWidgetComponent, SpecialistVisitsWidgetComponent, HospitalizationWidgetComponent,UsersWidgetComponent,MediaComponent, VitalNontrackingWidgetComponent]
})
export class SharedModule {

}