import { Component,ElementRef, ChangeDetectorRef,ViewChild,Output,EventEmitter, OnInit ,Input,NgModule ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { FormBuilder, FormGroup, FormControl, Validators  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable, combineLatest, Subscription } from 'rxjs';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver/FileSaver';
declare var $ :any; 


@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit,OnChanges {

  @ViewChild(ModalDirective) modal: ModalDirective;
  @Output() childEvent = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;


  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  @Input() public patientId:any;
  @Input() public visitId: any;
  @Input() public media: any; 
  @Input() public addnew: any;
  
  public patientID:any;
  public visitID : any;
  public Media : any;
  subscriptions: Subscription[] = [];


  public file : any;

  public fileName :any;
  public filetype :any;
  public fileValue :any;
  public currentfile  :any;

  modalRef: BsModalRef;

  addMedia:FormGroup;

  public image: any;
  public remarks: any;

  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,public toastr: ToastrService,
    private changeDetection: ChangeDetectorRef) {
     
  	this.addMedia = fb.group({
           image : new FormControl(null, Validators.required),
           remarks : new FormControl(null, Validators.required)
    });


  }

  openModal(template: TemplateRef<any>) {

    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addMedia.reset();
        
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addMedia.reset();
        this.unsubscribe();
      })
    );
 
    this.subscriptions.push(_combine);

    this.modalRef = this.modalService.show(template);
    
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  updateData(){
    this.childEvent.emit();
  }

  submitForm(post){
    
    let image = this.currentfile.item(0);
    console.log('post Data::'+ post.remarks); 
    this.apiService.postMedia(image,Number(this.visitID),post.remarks)
            .subscribe(result => {
              this.modalRef.hide();
              this.updateData();
              return result;
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };

            
   
  }

  fileuploaderFileChange(files: FileList){
  		console.log(files);
  }

  onFileChange(event) {
    this.currentfile =  event.target.files;
    console.log(this.currentfile); 
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      
      reader.onload = () => {
        this.fileName = file.name;
        this.filetype = file.type;
        this.fileValue = reader.result.split(',')[1];
        
      };
    }
  }


  openImage(data){
    console.log(data);
    this.apiService.getMediaImage
    (Number(this.visitId),Number(data.id))
            .subscribe((result) => {   
            const filename1 = 'test.txt';
            saveAs(result); 
      }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      }; 
  }



  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) { 

    this.patientID = this.patientId;
    this.visitID = this.visitId;
    this.Media = this.media;

    if(this.Media == null){
      console.log('test 1');
      console.log(this.Media);
    }else{
      console.log('test 2');
      console.log(this.Media);
    }

  }

}
