import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'angular-calendar';
import { DemoUtilsModule } from '../demo-utils/module';
import { DoctorWeeklyScheduleComponent } from './doctor-weekly-schedule.component';
import { SharedModule } from './../../shared/shared.module';
import {NgPipesModule} from 'ngx-pipes';

@NgModule({
  imports: [
    CommonModule,
    CalendarModule.forRoot(),
    DemoUtilsModule,
    SharedModule,
    NgPipesModule
  ],
  declarations: [DoctorWeeklyScheduleComponent],
  exports: [DoctorWeeklyScheduleComponent]
})
export class DoctorWeeklyScheduleModule {}