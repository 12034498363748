import { Component, OnInit, ViewContainerRef,OnChanges,SimpleChanges } from '@angular/core';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { AbstractControl,FormArray,ReactiveFormsModule, FormBuilder, FormGroup, FormControl, Validators, FormsModule ,NgForm  } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import {  ViewChild ,NgModule,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';

import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
declare var $ :any; 



@Component({
  selector: 'app-diagnostics',
  moduleId: module.id,
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.css']
})
export class DiagnosticsComponent implements OnInit {
 
  public patients:any;
  public activePatient:any;

  p: number = 1;

 
  
  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,private route: ActivatedRoute,
    public toastr: ToastrService,
    private titleService: Title) {
      
     this.titleService.setTitle( 'Diagnostics' );

  }

  loadPatients() {
          this.apiService.getPatientsData('TESTORDERSPENDING').subscribe(patients => { 
            this.patients = patients;
            // this.activePatient = patients[0];
            this.activePatient = patients[0];
            //this.loadActiveVitals(this.activePatient.client_id);
          }, error => {
            this.toastr.error(error);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
          };
   }

  ngOnInit() {
    this.loadPatients();
  	 //this.loadDignostics();
  }

}
