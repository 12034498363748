import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-diagnostics-symptoms',
  templateUrl: './doctor-diagnostics-symptoms.component.html',
  styleUrls: ['./doctor-diagnostics-symptoms.component.css']
})
export class DoctorDiagnosticsSymptomsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
