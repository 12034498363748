import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/index';
import { User, logins } from '../_models/index';
import { ToastrService } from 'ngx-toastr';

@Component({
    moduleId: module.id,
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    error = '';
    currentUser: logins;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        public toastr: ToastrService,
        public vcr: ViewContainerRef) {
    }

    ngOnInit() {
        // reset login status
        this.authenticationService.logout();
    }

    // login function to
    login() {
        this.authenticationService.login(this.model.username, this.model.password)
            .subscribe(result => {

                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                if (this.currentUser.userrole == "ROLE_CONSULTATION_DOCTOR") {
                    this.router.navigate(['/doctor-dashboard']);
                } else if (this.currentUser.userrole == "ROLE_OPS_ADMIN") {
                    this.router.navigate(['/operator-dashboard']);
                }


            }, error => {
                let edata;
                if (error.apierror) {
                    edata = error.apierror
                } else { edata = error }
                this.toastr.error(edata.status, edata.message);
            }),
            err => {
                this.toastr.error('500 Internal Server Error!');
            };
    }


}
