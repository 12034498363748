import { Component, ViewChild, OnInit,ChangeDetectorRef ,EventEmitter,Input,Output,NgModule ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable, combineLatest, Subscription } from 'rxjs';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';

import { ToastrService } from 'ngx-toastr';

declare var $ :any;
@Component({
  selector: 'app-non-drug-therapy',
  templateUrl: './non-drug-therapy.component.html',
  styleUrls: ['./non-drug-therapy.component.css']
})
export class NonDrugTherapyComponent implements OnInit,OnChanges {

  @ViewChild(ModalDirective) modal: ModalDirective;
  @Output() childEvent = new EventEmitter();
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  @Input() public patientId:any;
  @Input() public visitId: any;
  @Input() public nonDrugTherapies: any; 
  @Input() public addnew: any;
  

  public patientID:any;
  public visitID : any;
  public NonDrugTherapies : any;
  public selectedTherapy : any;

 

  public datepickerModel1: Date;
  public datepickerModel2: Date;

  datepickerModel5:any  = new Date();
  public maxDate:any  = new Date();

  modalRef1: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  modalRef4: BsModalRef;

  addNDT: FormGroup;
  pauseNDT: FormGroup;
  resumeNDT: FormGroup;
  stoppedNDT: FormGroup;

  subscriptions: Subscription[] = [];


  public therapy_name:any;
  public startDate: any;
  public endDate:any;
  public therapy_desc:any;
  public pause:any;
  public selected:any;
  public masterId:any
  public id:any
  public fromType:any;
  public tillDate : any;
  public remarks : any;

  public status : any;
  public indexvalue : any;
 


  records = [
  {id:'1',masterId:'2',therapy_name: 'Diagnosis Name 01', startDate:'12-05-2018',endDate:'25-05-2018',therapy_desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt explicabo maiores officiis corporis',duration:'22 Days',pause:0},
  {id:'2',masterId:'2',therapy_name: 'Diagnosis Name 01', startDate:'12-05-2018',endDate:'25-05-2018',therapy_desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt explicabo maiores officiis corporis',duration:'22 Days',pause:1},
  {id:'7',masterId:'3',therapy_name: 'Sura Namaskar Therapy', startDate:'14-03-2018',endDate:'29-12-2018',therapy_desc:'testing therapy',duration:'22 Days',pause:0},
  ];




  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    public toastr: ToastrService,private changeDetection: ChangeDetectorRef) {

     

     this.addNDT = fb.group({
           therapy_name : [null, Validators.required],
           startDate: [null, Validators.required],
           endDate: [null, Validators.required],
           therapy_desc: [null, Validators.required],
           pause:[0],
           masterId:[null],
           id:[null],
           fromType:[null]
     });

     this.pauseNDT = fb.group({
           remarks : [null, Validators.required],
           tillDate: [null, Validators.required],
           id : [null, Validators.required],
     });

     this.resumeNDT = fb.group({
           remarks : [null, Validators.required],
           id : [null, Validators.required],
     });

     this.stoppedNDT = fb.group({
           remarks : [null, Validators.required],
           id : [null, Validators.required],
     });
     

    
  }

  updateData(){
    this.childEvent.emit();
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  statDateSelected(value) {
    if(value != null) {
      this.datepickerModel5 = value;
    }
  }



  observableSource = (keyword: any): Observable<any[]> => {
    // this.selected = undefined;
    // this.masterId = undefined;
    let url: string = appConfig.apiUrl + '/consultationSummary/therapy?pattern='+keyword;
    let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
    let options = new RequestOptions({ headers: headers });
    if (keyword) {   
      this.selected = keyword;
      return this.http.get(url,options)
        .map((res: Response) => {
          console.log(res.json());
          return res.json()
        })
    } else {
      
      return Observable.of([]);
    }
  }


  therapySelected(val){
    if(val.therapy_name) {
      this.selected = val.therapy_name;
      this.masterId = val.id;
    } else {
      this.selected = val;
    }
    console.log(val);
  }


  updateTherapy(index,template: TemplateRef<any>,val){
    console.log(val);
    this.indexvalue = index;
    this.selectedTherapy = val;

    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addNDT.reset();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addNDT.reset();
        this.unsubscribe();
      })
    );
 
    this.subscriptions.push(_combine);
    
    this.modalRef1 = this.modalService.show(template);
    this.fromType = "UPDATE";
    let startdate =  val.startDateTime.split(" ");
    startdate = startdate[0].split("-");
    let enddate =  val.endDate.split("-");
    this.selected =  {id: val.masterId, therapy_name: val.therapy_name, remarks: val.remarks};
    this.datepickerModel1 = new Date(Number(startdate[2]) ,Number(startdate[1])-1, Number(startdate[0]));
    this.datepickerModel2 = new Date(Number(enddate[2]) ,Number(enddate[1])-1, Number(enddate[0]));
    this.therapy_desc = val.remarks;
    this.pause = val.status;
    this.status = val.status;
    this.id = val.id;
    this.addNDT.controls['therapy_name'].disable(); 


  }


  openModal(template: TemplateRef<any>) {
    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addNDT.reset();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.addNDT.reset();
        this.unsubscribe();
      })
    );
 
    this.subscriptions.push(_combine);
    this.addNDT.controls['therapy_name'].enable(); 
    this.modalRef1 = this.modalService.show(template);
    this.fromType = "NEW"
  }

  openModal1(template: TemplateRef<any>,val,index) {
    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.pauseNDT.reset();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.pauseNDT.reset();
        this.unsubscribe();
      })
    );
 
    this.subscriptions.push(_combine);

    this.modalRef2 = this.modalService.show(template);
    console.log(val);
    this.indexvalue = index;
    this.pauseNDT.controls['id'].setValue(val.id);  
  }

  openModal2(template: TemplateRef<any>,val,index) {
    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.resumeNDT.reset();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.resumeNDT.reset();
        this.unsubscribe();
      })
    );
 
    this.subscriptions.push(_combine);

    this.modalRef2 = this.modalService.show(template);
    this.resumeNDT.controls['id'].setValue(val.id); 
    this.indexvalue = index; 
  }

  openModal3(template: TemplateRef<any>,val,index) {
    const _combine = combineLatest(
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
 
    
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.stoppedNDT.reset();
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        this.stoppedNDT.reset();
        this.unsubscribe();
      })
    );
 
    this.subscriptions.push(_combine);

    this.modalRef3 = this.modalService.show(template);
    this.stoppedNDT.controls['id'].setValue(val.id);
    this.indexvalue = index;
  }


  submitForm(post) {
    let val1 = new Date(post.startDate);
    let val2 = new Date(post.endDate);
    let diff = val2.getDate()  - val1.getDate();
   


    post.startDate = this.datePipe.transform(post.startDate, 'dd-MM-yyyy HH:mm');
    post.endDate = this.datePipe.transform(post.endDate, 'dd-MM-yyyy');


    console.log('post Data::'+JSON.stringify(post));
    let masterid = undefined;
    let sydes;
    if(this.masterId != undefined) {
       masterid = this.masterId;
       sydes = this.selected;
    } else {
       sydes = post.therapy_name;
    }
   
    if(post.fromType == "NEW"){
       post = {
        masterId : masterid,
        therapy_name : sydes,
        startDateTime : post.startDate,
        endDate : post.endDate,
        consultation_id : this.visitID,
        remarks : post.therapy_desc,
        consumptionDuration : diff
      }
      this.apiService.postNDT(post,Number(this.visitID))
        .subscribe(result => {
           
                 console.log(result);
                 this.toastr.success('Therapy added successfully', 'Successful!');
                 this.NonDrugTherapies.push(post);
                 this.addNDT.reset();
                 this.updateData();
                 this.modalRef1.hide();
           
      }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      }; 
    }else{
      console.log(this.selectedTherapy);
      post = {
        id: post.id,
        masterId : post.masterId,
        therapy_name : post.therapy_name,
        startDateTime : post.startDate,
        endDate : post.endDate,
        consultation_id : this.visitID,
        remarks : post.therapy_desc,
      }
      this.apiService.putNDT(post,Number(this.visitID),Number(post.id))
        .subscribe(result => {
            
                 this.toastr.success('Therapy updated successfully', 'Successful!');
                 //this.NonDrugTherapies.push(post);
                 this.addNDT.reset();
                 this.updateData();
                 this.modalRef1.hide();
      }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      }; 
    }
  }

  submitForm1(post) {
    let todaysDate = new Date();
    let date = this.datePipe.transform(todaysDate, 'dd-MM-yyyy');
    post.tillDate = this.datePipe.transform(post.tillDate, 'dd-MM-yyyy');


    post = {
      drugSchId: post.id,
      interferenceDate: date,
      interferenceStatus: "PAUSED",
      treatmentType: "NONDRUG",
      remarks: post.remarks,
      resumeOn: post.tillDate
    }

    this.apiService.pauseNDT(post,Number(this.visitID),Number(post.drugSchId))
        .subscribe(result => {
            
                 console.log(result);
                 this.toastr.success('Therapy paused successfully', 'Successful!');
                 //this.NonDrugTherapies.push(post);
                 //this.NonDrugTherapies[this.indexvalue] = post;
                 this.pauseNDT.reset();
                 this.modalRef2.hide();
                 this.updateData();
            
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };

    console.log('post Data::'+JSON.stringify(post));

  }

  submitForm2(post) {
    
    post = {
      drugSchId: post.id,
      interferenceStatus: "RESUMED",
      treatmentType: "NONDRUG",
      remarks: post.remarks,
    }

    this.apiService.pauseNDT(post,Number(this.visitID),Number(post.drugSchId))
        .subscribe(result => {
            
                 console.log(result);
                 this.toastr.success('Therapy resumed successfully', 'Successful!');
                 //this.NonDrugTherapies.push(post);
                 //this.NonDrugTherapies[this.indexvalue] = post;
                 this.modalRef2.hide();
                 this.resumeNDT.reset();
                 this.updateData();
           
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      }; 

    console.log('post Data::'+JSON.stringify(post));

  }

  submitForm3(post) {
    
    post = {
      drugSchId: post.id,
      interferenceStatus: "STOPPED",
      treatmentType: "NONDRUG",
      remarks: post.remarks,
    }

    this.apiService.pauseNDT(post,Number(this.visitID),Number(post.drugSchId))
        .subscribe(result => {
            
                 console.log(result);
                 this.toastr.success('Therapy stopped successfully', 'Successful!');
                 //this.NonDrugTherapies.push(post);
                 //this.NonDrugTherapies[this.indexvalue] = post;
                 this.stoppedNDT.reset();
                 this.updateData();
                 this.modalRef3.hide();
            
    }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
      }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };

    console.log('post Data::'+JSON.stringify(post));

  }




  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) { 

    this.patientID = this.patientId;
    this.visitID = this.visitId;
    this.NonDrugTherapies = this.nonDrugTherapies;
    this.NonDrugTherapies.forEach(element => {
      let a = element.startDateTime.split(' ');
      element.startDateTime = a[0];
    });

    if(this.NonDrugTherapies == null){
      console.log('test 1');
      console.log(this.NonDrugTherapies);
    }else{
      console.log('test 2');
      console.log('VisitId' + this.visitID +'Patient ID'+ this.patientID);
      console.log(this.NonDrugTherapies);
    }

  }

}
