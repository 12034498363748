import { Component, ViewChild, OnInit, NgModule, ViewContainerRef, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { User, logins } from '../../_models/index';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-callback-requests',
  templateUrl: './callback-requests.component.html',
  styleUrls: ['./callback-requests.component.css']
})
export class CallbackRequestsComponent implements OnInit {



  @ViewChild(ModalDirective) modal: ModalDirective;

  currentRole: logins;

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  public patients: any;

  public allVisits: any = [];
  public visits = [];

  public currentVisit: any;
  public currentPatient: any;
  public currentVisitAddress: any;
  public currentVisitDate: any;
  public currentVisittime: any;
  public labtests: any;
  public lastVisitDetails: any;

  public problemSymptoms: any;
  public LabTests: any;

  public visitId: any;
  public patientId: any;

  initiateVisit: FormGroup;
  public schedDate: any;
  public schedTime: any;
  public user: any;



  bsValue: Date;
  mytime: Date;
  // mytime = new Date(2018, 4, 2, 10, 0);

  p: number = 1;
  count = 5;

  constructor(private apiService: ApisService,
    private fb: FormBuilder,
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private titleService: Title,
    public toastr: ToastrService) {

    if (window.matchMedia('(max-width: 1024px)').matches) {
      this.count = 3;
    } else {
      this.count = 5;
    }

    // initiate visit form
    this.initiateVisit = fb.group({
      schedDate: ['', Validators.required],
      schedTime: ['', Validators.required],
    });

    this.titleService.setTitle('Call Back Requests');

    this.currentRole = JSON.parse(localStorage.getItem('currentUser'));

  }

  ngOnInit() {
    this.loadPendingVisits();

  }

  // loading pending visits function
  loadPendingVisits() {
    this.apiService.getPendingCallBacks().subscribe(visits => {
      this.allVisits = visits;

      this.currentVisit = this.allVisits[0];

      let j = this.allVisits;
      for (let i = 0; i < j.length; i++) {
        if (this.allVisits[i].visitId != this.currentVisit.visitId) {
          this.visits.push(this.allVisits[i]);
        }
      }

      this.currentPatient = this.currentVisit.patientDetails;
      this.currentVisitAddress = this.currentVisit.patientDetails.address;
      this.currentVisitDate = this.currentVisit.visitSchedule.date;
      this.currentVisittime = this.currentVisit.visitSchedule.time;
      this.visitId = this.currentVisit.visitId;
      this.patientId = this.currentPatient.patient_id;
      this.getCurrentPatients(this.patientId);

      this.problemSymptoms = this.currentVisit.problemSymptoms;
      this.LabTests = this.currentVisit.labTests;


      let startdate = this.currentVisitDate.split("-");
      let enddate = this.currentVisittime.split(":");
      this.bsValue = new Date(Number(startdate[2]), Number(startdate[1]) - 1, Number(startdate[0]));
      this.mytime = new Date(Number(startdate[2]), Number(startdate[1]) - 1, Number(startdate[0]), Number(enddate[0]), Number(enddate[1]));


      this.initiateVisit.controls['schedDate'].setValue(this.bsValue);
      this.initiateVisit.controls['schedTime'].setValue(this.mytime);

    }, error => {
      let edata;
      if (error.apierror) {
        edata = error.apierror
      } else { edata = error }
      if (edata.status != "NOT_FOUND") {
        this.toastr.error(edata.message, edata.status);
      }
    }),
      err => {
        this.toastr.error('500 Internal Server Error!');
      };

  }

  // getting current patient service details
  getCurrentPatients(id) {
    this.apiService.getCurrentPatients(id).subscribe(user => {
      this.user = user;
      console.log('user data');
      console.log(this.user);

    }, error => {
      let edata;
      if (error.apierror) {
        edata = error.apierror
      } else { edata = error }
      this.toastr.error(edata.message, edata.status);
    }),
      err => {
        this.toastr.error('500 Internal Server Error!');
      };
  }

  updateData() {
    this.loadPendingVisits();
  }



  // start call back function
  startCallback(id) {
    this.apiService.startVisitSession(id).subscribe(patients => {


      this.router.navigate(['/phone-cunsultation-summery-list/' + id]);


    }, error => {
      let edata;
      if (error.apierror) {
        edata = error.apierror
      } else { edata = error }
      this.toastr.error(edata.message, edata.status);
    }),
      err => {
        this.toastr.error('500 Internal Server Error!');
      };
  }


  // cancel call back function
  cancelCallback(id) {
    this.apiService.cancelVisit(id).subscribe(patients => {

      this.loadPendingVisits();
      this.toastr.success('Callback Canceled Successfully!');

    }, error => {
      let edata;
      if (error.apierror) {
        edata = error.apierror
      } else { edata = error }
      this.toastr.error(edata.message, edata.status);
    }),
      err => {
        this.toastr.error('500 Internal Server Error!');
      };
  }


  // confirm call back function
  confirmCallback(id) {
    this.apiService.startVisitSession(id).subscribe(patients => {
    }, error => {
      let edata;
      if (error.apierror) {
        edata = error.apierror
      } else { edata = error }
      this.toastr.error(edata.message, edata.status);
    }),
      err => {
        this.toastr.error('500 Internal Server Error!');
      };
  }



  // call back form submit function
  submitForm(post) {

    post.schedDate = this.datePipe.transform(post.schedDate, 'dd-MM-yyyy');
    post.schedTime = this.datePipe.transform(post.schedTime, 'HH:mm');
    post = {
      clientId: this.patientId,
      schedDate: post.schedDate,
      schedTime: post.schedTime,
      status: "CONFIRMED"
    }
    console.log('post Data::' + JSON.stringify(post));
    this.apiService.rescheduleVisit(post, Number(this.visitId))
      .subscribe(result => {
        this.toastr.success('Callback Confirmed Successfully!');
      }, error => {
        let edata;
        if (error.apierror) {
          edata = error.apierror
        } else { edata = error }
        this.toastr.error(edata.message, edata.status);
      }),
      err => {
        this.toastr.error('500 Internal Server Error!');
      };
  }

}
