import { Component, ViewChild, OnInit ,NgModule ,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../../_services/api.service';
import { appConfig } from '../../../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { ToastrService } from 'ngx-toastr';

declare var $ :any;
@Component({
  selector: 'app-patient-sponsor-caller',
  templateUrl: './patient-sponsor-caller.component.html',
  styleUrls: ['./patient-sponsor-caller.component.css']
})
export class PatientSponsorCallerComponent implements OnInit {



  associateSponsor: FormGroup;

  public relation:any;
  public sponsorName:any;
  public sponsorId : any;
  public selected:any;
  public user : any;

  public allassociations = [];
  public currentSponsor: any;
  public currentSponsorId: any;
  public associations: any;
  public currentrelation:any;
  public nosponsor:boolean = true;

  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private titleService: Title,
    public toastr: ToastrService) {

     

    this.titleService.setTitle( 'Sponsor Details' );

  	this.associateSponsor = fb.group({
           relation : [null, Validators.required],
           sponsorName: ['', Validators.required],
           sponsorId:[null]
     });
	 
  }



	observableSource = (keyword: any): Observable<any[]> => {
	    let url: string = appConfig.apiUrl + '/patients/sponsors?pattern='+keyword;
	    let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
	    let options = new RequestOptions({ headers: headers });
	    if (keyword) {     
	      return this.http.get(url,options)
	        .map((res: Response) =>{ 
	        	console.log(res.json());
	        	return res.json()
	        })
	    } else {
	      return Observable.of([]);
	    }
	}

	sponsorSelected(val){
		this.selected = val.name;
		this.sponsorId = val.id;
	}


	submitForm(post) {
	    console.log('post Data::'+JSON.stringify(post));
      var user = this.route.snapshot.params['id'];
      console.log('ids');
      console.log(this.user.personId);
      console.log(post.sponsorId);
      if ( this.user.personId == post.sponsorId) {
          post.relation = 'SELF'
      } 
      post = {
              relation : post.relation,
              clientId : user,
              sponsorId :post.sponsorId
            }
      this.apiService.postAssociateSponsor(post,Number(user),Number(post.sponsorId))
              .subscribe(result => {
              if (result != null) {
                console.log(result);
                this.associateSponsor.reset();
                this.getAssociateSponsor(this.user.client_id);
                this.toastr.success('Sponsor Associated successfully', 'Successfull');
              } else {
                 this.toastr.error('Some thing went wrong', 'Sorry!');
              }
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };   
        console.log('post Data::'+JSON.stringify(post));
   }


  getCurrentPatients(id) {
        this.apiService.getCurrentPatients(id).subscribe(user => { 
          this.user = user;
          this.getAssociateSponsor(this.user.client_id);
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }

  // getAssociateSponsor(id){
  //       this.apiService.getAssociateSponsor(Number(id)).subscribe(user => { 
  //         this.allassociations = user;
  //         this.associations = [];
  //         this.allassociations.forEach(values => {
  //              if(this.user.personId == values.sponsor_id){
  //                  this.currentrelation == "Self"
  //              }
  //         });
  //       });
  // }

  getAssociateSponsor(id){
        this.apiService.getAssociateSponsor(Number(id)).subscribe(user => { 
          console.log(user);
          this.allassociations = user;
          this.associations = [];
          this.nosponsor = false;
          this.allassociations.forEach(values => {
              if(values.sponsor_id == null || (values.id == values.sponsor_id)){
                  this.currentSponsor = values;
                  this.currentSponsorId = values.id;
              }else if(values.id == this.user.personId){
                this.currentrelation = values.relationWithSponsor;
              } else {
                this.associations.push(values);
              }
          });
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
            if(edata.status == 'NOT_FOUND') {
              this.nosponsor = true;
            }
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }


  deleteAssociation() {
        this.apiService.deleteAssociatedSponsor(Number(this.user.client_id),Number(this.currentSponsorId))
        .subscribe(result => {
                this.toastr.success('Sponsor deassociated successfully', 'Successfull');
                this.associations = [];
                this.currentSponsor = [];
                this.currentSponsorId = [];
                this.currentrelation = [];
                this.allassociations = [];
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };  
   }

   public sponsor;
   ViewClient(id) {
     this.apiService.getPatientClientId(Number(id))
        .subscribe(result => {
          console.log(result);
          if(result){
              this.sponsor = result
             this.router.navigate(['/patient/'+this.sponsor.client_id+'/personal-details']);
          }
                
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
        });  
   }



  ngOnInit() {

    this.user = this.route.snapshot.params['id'];
    console.log(this.user);
    this.getCurrentPatients(this.user);
    
  }

  

}
