import { Component, ViewChild, OnInit ,Input,NgModule ,OnChanges,SimpleChanges,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap, Params } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../../../_services/api.service';
import { appConfig } from '../../../../app.config';
import { DatePipe, TitleCasePipe} from '@angular/common';

declare var $ :any;
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-doctor-visits',
  templateUrl: './doctor-visits.component.html',
  styleUrls: ['./doctor-visits.component.css']
})
export class DoctorVisitsComponent implements OnInit {
  

  public consultationlist:any;
  public user :any;
  public currentConsultation:any;

  public consultationSummaries : any;
  public activePatient : any;
  public activeSummaries : any;
  public patientDeails : any;
  public patientAddress : any;
  public visitId : any;
  public patientId : any;
  public currentVisitDate : any;
  public currentVisittime = [];
  
  public consultationSession : any;

  public assessments : any;
  public problemSymptoms : any;
  public LabTests : any;
  public diagnosisList : any;
  public examinations : any;
  public media :any;
  public nextVisitDate : any;
  public nonDrugTherapies : any;
  public activenonDrugTherapies : any;
  public notes : any;
  public pills : any;
  public activepills : any;
  public recommendations:any;
  public vitalrecordings : any;

  public latestConsultation : any;
  public editenable: any = 0;
  public patientDetails: any;

  public hasData:boolean = false;
  public localData:any;
  public visitStatus : any; 

  public vital1:any;
  public vital2:any;
  public vital3:any;
  public vital4:any;
  public vital5:any;
  public vital6:any;
  public vital7:any;

  public tab1:any = true;
  public tab2:any = false;
  public tab3:any = false;

  public sp_visits:any;
  public ahs_visits:any;
  public hsp_visitts:any;
  assessmentForm: FormGroup;


  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private route: ActivatedRoute,
    public toastr: ToastrService) {
     
  	this.titleService.setTitle( 'Doctor Visits' );

    this.route.url.subscribe(url =>{
        this.patientId = this.route.snapshot.params['id'];
    });

    // assessment form group
    this.assessmentForm = fb.group({
      assessment_data: new FormControl(null, Validators.required),
    });

  }

   // create are update assessment  function
   submitForm(post) {
    let date = new Date();
    let assess_date = this.datePipe.transform(date, 'dd-MM-yyyy');

    console.log('post Data::' + JSON.stringify(post));
    if (this.assessments[0]) {

      post = {
        assessment: post.assessment_data,
        consultation_id: this.visitId,
        assess_date: assess_date,
        id: this.assessments[0].id
      }

      this.apiService.putAssessment(post, Number(this.visitId), Number(this.assessments[0].id))
        .subscribe(result => {

          this.toastr.success("Assessment updated", "Success");
        }, error => {
          let edata;
          if (error.apierror) {
            edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err => {
          this.toastr.error('500 Internal Server Error!');
        };
    } else {

      post = {
        assessment: post.assessment_data,
        consultation_id: this.visitId,
        assess_date: assess_date
      }

      this.apiService.postAssessment(post, Number(this.visitId))
        .subscribe(result => {
          this.assessments = [];
          this.assessments.push(result);
          this.toastr.success("Assessment created", "Success");
        }, error => {
          let edata;
          if (error.apierror) {
            edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err => {
          this.toastr.error('500 Internal Server Error!');
        };
    }
  }



  loadConsultations() {
    this.apiService.getPatientConsultationSummary(Number(this.user)).subscribe(consultationlist => { 
      
      if(consultationlist) {
        consultationlist.forEach(element => {
          if(element.latestConsultation == true) {
            this.latestConsultation = element.visitId;
          }
        });
      }
      
      this.consultationlist = [];
      consultationlist.forEach(values => {
          if( (values.consultationType == 'VISIT' || values.consultationType == 'ADHOC') ){
            this.consultationlist.push(values);
          }
      });
      if(this.consultationlist.length > 0){
          this.hasData = true; 
          this.visitId = '';
          if (this.localData != undefined){
            this.consultationlist.forEach(element => {
              if(element.visitId == this.localData.consultation_id || element.visitId == this.localData.visitId) {
                this.visitId = element.visitId;
                this.loadCurrentConsultationSummary(element.visitId);
                if(this.localData.type == 'ACTION_ITEM') {
                  this.tab1 = false;
                  this.tab2 = false;
                  this.tab3 = true;
                } else {
                  this.tab1 = false;
                  this.tab2 = true;
                  this.tab3 = false;
                }
               
              } 
            });
          }
          if(this.visitId == '') {
            
            this.visitId = this.consultationlist[0].visitId;
            this.visitStatus = this.consultationlist[0].status;
            this.loadCurrentConsultationSummary(this.consultationlist[0].visitId);
          }
          
      }
    }, error => {
        let edata;
        if(error.apierror){ edata = error.apierror
        } else { edata = error }
        this.toastr.error(edata.message, edata.status);
      }),
      err   => {
        this.toastr.error('500 Internal Server Error!');           
    };
  }

  loadCurrentConsultationSummary(id){

   

    this.consultationlist.forEach(element => {
      if(id == element.visitId) {
       if(element.latestConsultation && element.status != 'CANCELLED') {
          this.editenable = 1;
       } else {
          if(this.visitStatus == 'INPROGRESS') {
            this.editenable = 1;
          } else {
            this.editenable = 0;
          }
        }
      }
    });

  	
    this.apiService.getCurrentConsultationSummary(Number(id)).subscribe(consultationSummary => {  



        this.activeSummaries = consultationSummary[0];
        this.problemSymptoms = this.activeSummaries.symptomsList;
        this.LabTests = this.activeSummaries.labTests;
        this.consultationSession = this.activeSummaries.consultationSession;
        this.assessments = this.activeSummaries.assessments;

        this.diagnosisList = this.activeSummaries.diagnosisList;
        var examinationwithoutsort = this.activeSummaries.examinations;
        this.media = this.activeSummaries.media;
        this.nextVisitDate = this.activeSummaries.nextVisitDate;
        this.nonDrugTherapies = this.activeSummaries.nonDrugTherapies;
        this.notes = this.activeSummaries.notes;
        this.pills = this.activeSummaries.pills;
        this.recommendations = this.activeSummaries.recommendations;
        this.vitalrecordings = this.activeSummaries.vitalrecordings;

        if (this.assessments[0]) {
          this.assessmentForm.controls['assessment_data'].setValue(this.assessments[0].assessment);
        }

        this.examinations = examinationwithoutsort.sort(function IHaveAName(a, b) { // non-anonymous as you ordered...
            return b.examMasterId > a.examMasterId ?  1 // if b should come earlier, push a to end
                : b.examMasterId < a.examMasterId ? -1 // if b should come later, push a to begin
                : 0;                   // a and b are equal
        });



        this.sp_visits = [];
        this.ahs_visits = [];
        this.hsp_visitts = [];
        this.recommendations.forEach(element => {
          if(element.visitType == 'SPECIALIST_VISIT') {
            this.sp_visits.push(element);
          }
          if(element.visitType == 'CONSULTANT_VISIT') {
            this.ahs_visits.push(element);
          }
          if(element.visitType == 'HOSPITALIZATION') {
            this.hsp_visitts.push(element);
          }
        });
        this.activepills = [];
        this.activenonDrugTherapies = [];
        this.pills.forEach(element => {
          element.dosage[0].foodGapInMinutes = Math.abs(element.dosage[0].foodGapInMinutes);
          if(element.status != 'STOPPED' && element.status != 'PAUSED') {
            this.activepills.push(element);
          }
        });
        
        this.nonDrugTherapies.forEach(element => {
          if(element.status != 'STOPPED' && element.status != 'PAUSED') {
            this.activenonDrugTherapies.push(element);
          }
        });


        this.vital1 = '';
        this.vital2 = '';
        this.vital3 = '';
        this.vital4 = '';
        this.vital5 = '';
        this.vital6 = '';
        this.vital7 = '';
        this.vitalrecordings.forEach(element => {
          if(element.values != null) {
            if(element.name == 'Temperature' && element.values[0]) {
              this.vital1 = element.values[0].value + ' ' + 'F';
            }
            if(element.name == 'Pulse' && element.values[0]) {
              this.vital2 = element.values[0].value + ' ' + 'min';
            }
            if(element.name == 'Systolic' && element.values[0]) {
              this.vital3 = element.values[0].value + ' ' + 'mmHg';
            }
            if(element.name == 'Diastolic' && element.values[0]) {
              this.vital4 = element.values[0].value + ' '  + 'mmHg';
            }
            if(element.name == 'Respiratory Rate' && element.values[0]) {
              this.vital5 = element.values[0].value + ' ' + 'min';
            }
            if(element.name == 'Height') {
              this.vital6 = element.values[0].value + ' ' +'ft';
            }
            if(element.name == 'Weight') {
              this.vital7 = element.values[0].value + ' ' + 'kg';
            }
          }
        });

        this.currentVisitDate = this.consultationSession.startTime;
        this.currentVisittime = this.consultationSession.visitDate; 

        console.log("time" + this.currentVisittime);
        if( this.assessments[0]){
          //this.assessmentForm.controls['assessment_data'].setValue(this.assessments[0].assessment);
        } 
        
    }, error => {
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  

  updateData(){
    this.loadCurrentConsultationSummary(this.visitId);
  }

  selectVisit(id){
    console.log(id);
    this.visitId = id;
    this.consultationlist.forEach(element => {
      if(element.visitId == id) {
        this.visitStatus = element.status;
      }
    });
    this.loadCurrentConsultationSummary(id);
  }

  ngOnInit() {
    this.localData = undefined;
    if(localStorage.getItem('homedata')) {
      this.localData = JSON.parse(localStorage.getItem('homedata'));
    }
  	this.user = this.route.snapshot.params['id'];
    this.loadConsultations();
    this.getCurrentPatients(this.user);
  }

  getCurrentPatients(id) {
    this.apiService.getCurrentPatients(id).subscribe(user => { 
      this.patientDetails = user;
  });
}
  public doctor_pdf:boolean;
  print(value): void {
    
      let name = this.patientDetails.personalDetails.firstName + ' ' + this.patientDetails.personalDetails.lastName
      
      let printContents, popupWin;
      if( value == 0) {
      printContents = document.getElementById('print-section').innerHTML;
    } else {
      printContents = document.getElementById('print-section-1').innerHTML;
    }
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Summary Sheet - ${name}</title>
            
            <style>
              table {
                font-family: Calibri;
              }
              th {
                padding:5px;
                text-align:left;
              }
              td {
                padding:5px;
              
              }
              .doc th, .doc td{
                border:0px solid #ccc;
              }
              .hide{
                display:none
              }
              .bordered-table th,.bordered-table td{
                border:1px solid #000;
                border-right:0px;
                border-bottom:0px;
              }
              .bordered-table td,.bordered-table th {
                border-right:1px solid #000
              }
              table tr.bordered-table:last-child td{
                border-bottom:1px solid #000
              }
            
            
           
              @media print { 
                
               
               
               
              }
            </style>
            <script>
            setTimeout(function(){ window.print();  }, 1000);
            </script>
          </head>
      <body >${printContents}</body>
        </html>`
      );
      popupWin.document.close();
  }


}
