import { Component, ViewChild, OnInit ,NgModule ,AfterViewInit ,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import {  ElementRef, AfterContentInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
// Variable in assets/js/scripts.js file
declare var AdminLTE: any;
import { chart } from 'highcharts';

@Component({
  selector: 'app-monitor-health',
  templateUrl: './monitor-health.component.html',
  styleUrls: ['./monitor-health.component.css']
})
export class MonitorHealthComponent implements OnInit  {

  @ViewChild('chat0') chat0: ElementRef;
  @ViewChild('chat1') chat1: ElementRef;
  @ViewChild('chat2') chat2: ElementRef;
  @ViewChild('chat3') chat3: ElementRef;
  @ViewChild('chat4') chat4: ElementRef;
  chart0: Highcharts.ChartObject;
  chart1: Highcharts.ChartObject;
  chart2: Highcharts.ChartObject;
  chart3: Highcharts.ChartObject;
  chart4: Highcharts.ChartObject;

  public view0 = true;
  public view1 = true;
  public view2 = true;
  public view3 = true;
  public view4 = true;

  public title0:any;
  public title1:any;
  public title2:any;
  public title3:any;
  public title4:any;

  title = "Title";
  activeVitals:any;

  modalRef: BsModalRef;

  public patients:any;
  public allPatients:any;
  public activePatient:any;

  public vitalname:any;
  public currentValue:any;
  public recordDate:any;
  public recordTime:any;
  public selectedVital:any;
  public lastVisitSummary:any;
  public vitalRecodings:any;

  public hasData:boolean = false;

  recordVital:FormGroup;

  p: number = 1;
  count = 5;

  todaysDate:Date = new Date;


    constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private titleService: Title,
    public toastr: ToastrService) {

      if (window.matchMedia('(max-width: 1024px)').matches) {
        this.count = 3;
      } else {
        this.count = 5;
      }
       
      this.titleService.setTitle( 'Monitor Health' );

      this.recordVital = fb.group({
             vitalname : new FormControl(null, Validators.required),
             currentValue : new FormControl(null, Validators.required),
             recordDate : new FormControl(null, Validators.required), 
             recordTime: new FormControl(null, Validators.required),
      });

    }


   
    loadActiveVitals(id){
      this.apiService.getActiveVitals(Number(id)).subscribe(vitals => { 
        this.activeVitals = vitals;
        this.lastVisitSummary = this.activeVitals.lastVisitSummary;
        this.vitalRecodings = this.activeVitals.vitalRecodings;
         if(this.vitalRecodings){
            this.vitalRecodings.forEach((items,index) =>{
                  let cp1 = [];
                  let cp2 = [];
                  let cat = [];

                  if(this.vitalRecodings[index].values != null){
                   this.vitalRecodings[index].values.forEach( item => {
                    cp1.push(this.vitalRecodings[index].normalRangeStart);
                    cp2.push(item.value);
                    cat.push(item.timeOfReading);
                 });  
                 }
                 if(index == 0){
                    this.title0 = this.vitalRecodings[0].name;
                    this.chart0 = null;
                    this.graphs(cp1,cp2,cat);
                 }
                 if(index == 1){
                    this.title1= this.vitalRecodings[1].name;
                    this.chart1 = null;
                    this.graphs1(cp1,cp2,cat);
                 }
                 if(index == 2){
                    this.title2 = this.vitalRecodings[2].name;
                    this.chart2 = null;
                    this.graphs2(cp1,cp2,cat);
                 }
                 if(index == 3){
                    this.title3 = this.vitalRecodings[3].name;
                    this.chart3 = null;
                    this.graphs3(cp1,cp2,cat);
                 }
                 if(index == 4){
                    this.title4 = this.vitalRecodings[4].name;
                    this.chart4 = null;
                    this.graphs4(cp1,cp2,cat);
                 }
            });
            
          }

          for(let i = 0; i < 5; i++){
            if(i < this.vitalRecodings.length){
            }else{
              if(i==0){ this.view0 = false;}
              if(i==1){ this.view1 = false;}
              if(i==2){ this.view2 = false;}
              if(i==3){ this.view3 = false;}
              if(i==4){ this.view4 = false;}
            }
          }
      }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
    }



    loadPatients() {
          this.apiService.getPatientsData('VITALMONITORED').subscribe(patients => { 
            
            this.allPatients = patients;
            this.patients = [];

            let j = this.allPatients;
            if(j.length > 0){
              for(let i = 0; i < j.length; i++){
                
                // if(j[i].lastVisitId != null){
                //   if(i == 0){
                //     this.hasData = true;
                //     this.activePatient = patients[0];
                //     this.loadActiveVitals(this.activePatient.client_id);  
                //   } else {
                //     this.patients.push(this.allPatients[i]);
                //   }
                // }

                if(i == 0){
                  this.hasData = true;
                  this.activePatient = patients[0];
                  this.loadActiveVitals(this.activePatient.client_id);  
                } else {
                  this.patients.push(this.allPatients[i]);
                }
              }
            // this.activePatient = patients[0];
            }
          }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
    }

    recordVitals(template: TemplateRef<any>){
      this.modalRef = this.modalService.show(template);
    }

    VitalSelected(value){
      console.log(value);
      this.vitalRecodings.forEach(element=> {
            if(element.recordConfigId = value ){
              this.selectedVital = element;
            }
      })
    }

    submitForm(post){
        post.recordDate = this.datePipe.transform(post.recordDate, 'dd-MM-yyyy');
        post.recordTime = this.datePipe.transform(post.recordTime, 'HH:mm');
        

        post = {
          recordConfigId : post.vitalname,
          timeOfReading : post.recordDate +' '+post.recordTime,
          value : post.currentValue
        }
        console.log(post);

        this.apiService.recordVitals(Number(this.activePatient.client_id),Number(post.recordConfigId),post).subscribe(patients => { 
            this.modalRef.hide();
            this.loadActiveVitals(this.activePatient.client_id);
            this.recordVital.reset();
        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };

        
  }

  graphs(cp1,cp2,cat){
     this.chart0 = null;
      const options: Highcharts.Options = {
        chart: { type: 'line' },
        title: {  text: '' },
        credits: {  enabled: false },
        xAxis: {
          categories: cat
        },
        yAxis: {
          title: {
            text: 'Vital Values'
          }
        },
        series: [{
          name: 'Expected',
          color: '#3498db',
          data: cp1
        }, {
          name: 'Actual',
          color:'#00a65a',
          data: cp2
        }]
      };
      this.chart0 = chart(this.chat0.nativeElement, options);
  }
  graphs1(cp1,cp2,cat){
     this.chart1 = null;
      const options1: Highcharts.Options = {
        chart: { type: 'line' },
        title: {  text: '' },
        credits: {  enabled: false },
        xAxis: {
          categories: cat
        },
        yAxis: {
          title: {
            text: 'Vital Values'
          }
        },
        series: [{
          name: 'Expected',
          data: cp1
        }, {
          name: 'Actual',
          data: cp2
        }]
      };
      this.chart1 = chart(this.chat1.nativeElement, options1);
  }
  graphs2(cp1,cp2,cat){
     this.chart2 = null;
      const options2: Highcharts.Options = {
        chart: { type: 'line' },
        title: {  text: '' },
        credits: {  enabled: false },
        xAxis: {
          categories: cat
        },
        yAxis: {
          title: {
            text: 'Vital Values'
          }
        },
        series: [{
          name: 'Expected',
          data: cp1
        }, {
          name: 'Actual',
          data: cp2
        }]
      };
      this.chart2 = chart(this.chat2.nativeElement, options2);
  }
  graphs3(cp1,cp2,cat){
     this.chart3 = null;
      const options3: Highcharts.Options = {
        chart: { type: 'line' },
        title: {  text: '' },
        credits: {  enabled: false },
        xAxis: {
          categories: cat
        },
        yAxis: {
          title: {
            text: 'Vital Values'
          }
        },
        series: [{
          name: 'Expected',
          data: cp1
        }, {
          name: 'Actual',
          data: cp2
        }]
      };
      this.chart3 = chart(this.chat3.nativeElement, options3);
  }
  graphs4(cp1,cp2,cat){
     this.chart4 = null;
      const options4: Highcharts.Options = {
        chart: { type: 'line' },
        title: {  text: '' },
        credits: {  enabled: false },
        xAxis: {
          categories: cat
        },
        yAxis: {
          title: {
            text: 'Vital Values'
          }
        },
        series: [{
          name: 'Expected',
          data: cp1
        }, {
          name: 'Actual',
          data: cp2
        }]
      };
      this.chart4 = chart(this.chat4.nativeElement, options4);
  }
 


  ngOnInit() {
    
    
      
  }

  ngAfterContentInit() {
    this.loadPatients();
  }

  ngOnDestroy() {
    this.chart0 = null;
    this.chart1 = null;
    this.chart2 = null;
    this.chart3 = null;
    this.chart4 = null;
  }

}
