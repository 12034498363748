import { Component, ViewChild, OnInit ,NgModule ,  ViewContainerRef,TemplateRef  }  from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

import { FormArray, FormBuilder, FormGroup, FormControl, Validators, FormsModule  } from '@angular/forms';
import { Router , ActivatedRoute, ParamMap } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { HttpClient } from '@angular/common/http';
import { CompleterService, CompleterData, RemoteData, CompleterItem } from 'ng2-completer';
import { Subject } from 'rxjs/Subject';
import 'rxjs/Rx';


import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/observable/of';
import { AuthenticationService } from '../../_services/index';
import { BsModalService ,ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApisService } from '../../_services/api.service';
import { appConfig } from '../../app.config';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pending-visits',
  templateUrl: './pending-visits.component.html',
  styleUrls: ['./pending-visits.component.css']
})
export class PendingVisitsComponent implements OnInit {

  
  public allVisits : any;
  public visits = [];

  public currentVisit : any;
  public currentPatient : any;
  public currentVisitAddress : any;
  public currentVisitDate : any;
  public currentVisittime : any;
  public labtests : any;
  public lastVisitDetails : any;
  
  public visitId : any;
  public patientId : any;

  public problemSymptoms : any;
  public LabTests : any;

  public cureentVisitId : any;

  initiateVisit: FormGroup;
  public schedDate:any;
  public schedTime: any;

  public user:any;

  bsValue : Date;
  mytime : Date;
  // mytime = new Date(2018, 4, 2, 10, 0);

  p: number = 1;
  count = 5;

  constructor(private apiService: ApisService,
    private fb: FormBuilder, 
    private vcr: ViewContainerRef,
    public router: Router,
    public http: Http,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private titleService: Title,
    public toastr: ToastrService) {

      if (window.matchMedia('(max-width: 1024px)').matches) {
        this.count = 3;
      } else {
        this.count = 5;
      }
     
    this.initiateVisit = fb.group({
           schedDate : ['', Validators.required],
           schedTime: ['', Validators.required],
    });

    this.titleService.setTitle( 'Pending Visits Confirmation' );

    this.route.url.subscribe(url =>{
  			this.loadPendingVisits(this.route.snapshot.params['id']);
	  });

  }

  ngOnInit() {
     //this.loadPatients();
     //this.cureentVisitId = this.route.snapshot.params['id'];
     //this.loadPendingVisits(this.route.snapshot.params['id']);
  }

  cancelCallback(id){
    this.apiService.cancelVisit(id).subscribe(patients => { 
          
      this.router.navigate(['/pending-vist-confirmation']);
          }, error => {
              let edata;
              if(error.apierror){ edata = error.apierror
              } else { edata = error }
              this.toastr.error(edata.message, edata.status);
            }),
            err   => {
              this.toastr.error('500 Internal Server Error!');           
          };
    }

  updateData(){
    this.loadPendingVisits(this.visitId);
  }

  loadPendingVisits(id) {
    this.apiService.getPendingVisits().subscribe(visits => { 
      this.allVisits = visits;
     // this.currentVisit = this.allVisits[0];
      this.visits = [];
      let j = this.allVisits;
      for(let i = 0; i < j.length; i++){
        if(this.allVisits[i].visitId != id){
          this.visits.push(this.allVisits[i]);
        }else{
          this.currentVisit = this.allVisits[i];
      	  this.currentPatient = this.currentVisit.patientDetails;
          this.currentVisitAddress = this.currentVisit.patientDetails.address;
      	  this.currentVisitDate = this.currentVisit.visitSchedule.date;
      	  this.currentVisittime = this.currentVisit.visitSchedule.time;
      	  this.visitId = this.currentVisit.visitId;
          this.patientId = this.currentPatient.patient_id;
          this.getCurrentPatients(this.patientId);

          this.problemSymptoms = this.currentVisit.problemSymptoms;
          this.LabTests = this.currentVisit.labTests;
	    }
  }

     

      let startdate =  this.currentVisitDate.split("-");
      let enddate =  this.currentVisittime.split(":");
      this.bsValue = new Date(Number(startdate[2]) ,Number(startdate[1])-1, Number(startdate[0]));
      this.mytime = new Date(Number(startdate[2]) ,Number(startdate[1])-1, Number(startdate[0]), Number(enddate[0]), Number(enddate[1]) );
      console.log(this.mytime);


      this.initiateVisit.controls['schedDate'].setValue(this.bsValue);
      this.initiateVisit.controls['schedTime'].setValue(this.mytime);
    
     // this.datepickerModel1 = new Date(Number(startdate[2]) ,Number(startdate[1])-1, Number(startdate[0]));



    }, error => {
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      };
  }

  

   getCurrentPatients(id) {
        this.apiService.getCurrentPatients(id).subscribe(user => { 
          this.user = user;
          console.log('user data');
          console.log(this.user);

        }, error => {
            let edata;
            if(error.apierror){ edata = error.apierror
            } else { edata = error }
            this.toastr.error(edata.message, edata.status);
          }),
          err   => {
            this.toastr.error('500 Internal Server Error!');           
        };
  }

  submitForm(post) {

    post.schedDate = this.datePipe.transform(post.schedDate, 'dd-MM-yyyy');
    post.schedTime = this.datePipe.transform(post.schedTime, 'HH:mm');
    post = {
        clientId :  this.patientId,
        schedDate : post.schedDate,
        schedTime : post.schedTime,
        status : "CONFIRMED"
    }
    console.log('post Data::'+JSON.stringify(post));
    this.apiService.rescheduleVisit(post,Number(this.visitId))
      .subscribe(result => {
          this.toastr.success("Visit Confirmed Successfully", "Success");
    }, error => {
          let edata;
          if(error.apierror){ edata = error.apierror
          } else { edata = error }
          this.toastr.error(edata.message, edata.status);
        }),
        err   => {
          this.toastr.error('500 Internal Server Error!');           
      }; 
  }



}
