﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map'
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../_services/index';
import { appConfig } from '../app.config';
import { User,AddDiagnosticsModal } from '../_models/index';

@Injectable()
export class UserService {
    constructor(
        private http: Http,
        private authenticationService: AuthenticationService,
        private titleService: Title) {
    }

    // get users list service
    getUsers(): Observable<User[]> {
        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/login', options)
            .map((response: Response) => response.json());
    }


    getSearch(): Observable<AddDiagnosticsModal[]>{

        // add authorization header with jwt token
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        let options = new RequestOptions({ headers: headers });

        // get users from api
        return this.http.get(appConfig.apiUrl + '/consultationSummary/diagnosis/search?pattern=Hernia', options)
            .map((response: Response) => response.json());

    }

    setTitle( newTitle: string) {
        this.titleService.setTitle( newTitle );
    }

   

   
}