import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router,ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  constructor(private router: Router, private titleService: Title,private route: ActivatedRoute,) {
  	
  }
	
  ngOnInit() {
	    this.titleService.setTitle('Peritus');
  }
  
}
