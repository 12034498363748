import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from 'angular-calendar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import {NgPipesModule} from 'ngx-pipes';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { AuthGuard } from './../../_guards/index';
import { JwtInterceptorProvider, ErrorInterceptorProvider } from './../../_helpers/index';
import { AlertService, AuthenticationService, UserService } from './../../_services/index';

import { PatientComponent } from './patient.component';
import { SharedModule } from './../../shared/shared.module';
import { mainRouting } from './../main.routing';

import { PatientListComponent } from './../patient/patient-list/patient-list.component';
import { PatientScrollHeaderComponent } from './../patient/patient-scroll-header/patient-scroll-header.component';
import { PatientSidebarComponent } from './../patient/patient-sidebar/patient-sidebar.component';
import { PatientHeaderComponent } from './../patient/patient-header/patient-header.component';
import { PersonalDetailsComponent } from './../patient/patient-details/personal-details/personal-details.component';
import { PatientConsultantsComponent } from './../patient/patient-details/patient-consultants/patient-consultants.component';
import { DoctorCallbacksComponent } from './../patient/patient-details/doctor-callbacks/doctor-callbacks.component';
import { DoctorVisitsComponent } from './../patient/patient-details/doctor-visits/doctor-visits.component';
import { HospitalizationsComponent } from './../patient/patient-details/hospitalizations/hospitalizations.component';
import { PackageHistoryComponent } from './../patient/patient-details/package-history/package-history.component';
import { PatientDashboardComponent } from './../patient/patient-details/patient-dashboard/patient-dashboard.component';
import { PatientSpecialistsComponent } from './../patient/patient-details/patient-specialists/patient-specialists.component';
import { PatientSpecialistVisitsComponent } from './../patient/patient-details/patient-specialist-visits/patient-specialist-visits.component';
import { PatientSponsorCallerComponent } from './../patient/patient-details/patient-sponsor-caller/patient-sponsor-caller.component';
import { ChronicDiseasesComponent } from './../patient/patient-details/chronic-diseases/chronic-diseases.component';
import { OthersComponent } from './../patient/patient-details/others/others.component';
import { RenewPackageComponent } from './../patient/patient-details/renew-package/renew-package.component';
import { SpecialistFromComponent } from './../patient/patient-details/specialist-from/specialist-from.component';
import { ConsultantFormComponent } from './../patient/patient-details/consultant-form/consultant-form.component';
import { NewHospitalizationComponent } from './patient-details/new-hospitalization/new-hospitalization.component';
import { PatientSponsorComponent } from './patient-details/patient-sponsor/patient-sponsor.component';
import { SponsorComponent } from './patient-details/sponsor/sponsor.component';
import { NewPatientComponent } from './new-patient/new-patient.component';
import { SpecialistFormEditComponent } from './patient-details/specialist-form-edit/specialist-form-edit.component';
import { ConsultantFormEditComponent } from './patient-details/consultant-form-edit/consultant-form-edit.component';
import { SponsorEditComponent } from './patient-details/sponsor-edit/sponsor-edit.component';




@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule.forRoot(),
    SharedModule,
    mainRouting,
    BrowserModule,
    RouterModule,
    HttpModule,
    HttpClientModule,
    mainRouting,
    BsDatepickerModule,
    NguiAutoCompleteModule,
    NgPipesModule,
    Ng2SearchPipeModule
  ],
  declarations: [
    ChronicDiseasesComponent,
    PatientConsultantsComponent,
    DoctorCallbacksComponent,
    DoctorVisitsComponent,
    HospitalizationsComponent,
    PackageHistoryComponent,
    PatientDashboardComponent,
    PersonalDetailsComponent,
    PatientSpecialistsComponent,
    PatientSpecialistVisitsComponent,
    PatientSponsorCallerComponent,
    PatientSidebarComponent,
    PatientScrollHeaderComponent,
    PatientListComponent,
    OthersComponent,
    RenewPackageComponent,
    SpecialistFromComponent,
    ConsultantFormComponent,
    PatientComponent,
    PatientHeaderComponent,
    NewHospitalizationComponent,
    PatientSponsorComponent,
    SponsorComponent,
    NewPatientComponent,
    SpecialistFormEditComponent,
    ConsultantFormEditComponent,
    SponsorEditComponent,
  ],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    JwtInterceptorProvider,
    ErrorInterceptorProvider
  ],
  exports: [PatientComponent]
})
export class PatientModule { }
