import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-treatment',
  templateUrl: './doctor-treatment.component.html',
  styleUrls: ['./doctor-treatment.component.css']
})
export class DoctorTreatmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
